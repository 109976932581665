/**
 * Sinewav3 Schema Entity: TimelineEvent
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var TimelineEvent = (function() {

        /**
         * Constructor
         * @param type
         * @param frame
         * @param group_name
         * @param setting_name
         * @param value
         * @param ease_type
         * @param start_val
         * @param end_val
         * @param steps
         * @param loop_type
         * @constructor
         */
        function TimelineEvent(type, frame, group_name, setting_name, value, ease_type, start_val, end_val, steps, loop_type) {
            this.type = type;
            this.frame = frame || 0;
            this.group_name = group_name;
            this.setting_name = setting_name;
            this.value = value;
            this.ease_type = ease_type;
            this.start_val = start_val;
            this.end_val = end_val;
            this.steps = steps;
            this.loop_type = loop_type;
        }

        /**
         * TimelineEvent Type Constants
         *
         * @type {{SELECT: string, VALUE: string, EASE: string}}
         */
        TimelineEvent.TYPE = {
            SELECT:  'select',   // Select a World
            MUTE:    'mute',     // Mute the output of a Plugin
            UNMUTE:  'unmute',   // Unmute the output of a Plugin
            VALUE:   'value',    // New value for a Plugin Setting
            EASE:    'ease'      // Ease a Plugin Setting between two values
        };

        /**
         * Get a new TimelineEvent instance from a database representation
         * @param o
         * @returns {TimelineEvent}
         */
        TimelineEvent.fromObject = function(o) {
            return new TimelineEvent(
                o.type,
                o.frame,
                o.group_name,
                o.setting_name,
                o.value,
                o.ease_type,
                o.start_val,
                o.end_val,
                o.steps,
                o.loop_type
            );
        };

        /**
         * Get a database representation of this TimelineEvent instance
         * @returns {object}
         */
        TimelineEvent.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this TimelineEvent instance
         * @returns {string}
         */
        TimelineEvent.prototype.toString = function() {
            return this.type +
                   this.frame +
                   this.value +
                   this.group_name +
                   this.setting_name +
                   this.ease_type +
                   this.start_val +
                   this.end_val +
                   this.steps +
                   this.loop_type;
        };


        /**
         * Is this TimelineEvent instance's type field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.typeIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    Object.values(TimelineEvent.TYPE).indexOf(this.type) != -1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's frame field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.frameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.frame === 'number' &&
                    this.frame > -1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's group_name field valid?
         * @returns {boolean|*}
         */
        TimelineEvent.prototype.groupNameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.group_name === null || typeof this.group_name === 'undefined'
                ) || (
                    typeof this.group_name === 'string' &&
                    this.group_name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's setting_name field valid?
         * @returns {boolean|*}
         */
        TimelineEvent.prototype.settingNameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.setting_name === null || typeof this.setting_name === 'undefined'
                ) || (
                    typeof this.setting_name === 'string' &&
                    this.setting_name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's value field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.valueIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.type !== TimelineEvent.TYPE.VALUE &&
                        this.value === null || typeof this.value === 'undefined'
                    ) || (
                        this.type === TimelineEvent.TYPE.VALUE &&
                        this.value !== null && typeof this.value !== 'undefined'
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's ease_type field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.easeTypeIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.ease_type === null || typeof this.ease_type === 'undefined'
                ) || (
                    typeof this.ease_type === 'string' &&
                    Object.values( Setting.EASES ).indexOf( this.ease_type ) !=-1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's start_val field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.startValIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.start_val === null || typeof this.start_val === 'undefined'
                ) || (
                    typeof this.start_val === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's end_val field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.endValIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.end_val === null || typeof this.end_val === 'undefined'
                ) || (
                    typeof this.end_val === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's steps field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.stepsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.steps === null || typeof this.steps === 'undefined'
                ) || (
                    typeof this.steps === 'number' &&
                    this.steps > 1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance's loop_type field valid?
         * @returns {boolean}
         */
        TimelineEvent.prototype.loopTypeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.loop_type === null || typeof this.loop_type === 'undefined'
                ) || (
                    Object.values( Setting.LOOPS ).indexOf( this.loop_type ) !=-1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this TimelineEvent instance valid?
         * @returns {boolean|*}
         */
        TimelineEvent.prototype.isValid = function() {
            return (
                this.typeIsValid() &&
                this.frameIsValid() &&
                this.groupNameIsValid() &&
                this.settingNameIsValid() &&
                this.valueIsValid() &&
                this.easeTypeIsValid() &&
                this.startValIsValid() &&
                this.endValIsValid() &&
                this.stepsIsValid() &&
                this.loopTypeIsValid()
            );
        };

        return TimelineEvent;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = TimelineEvent;
    } else {
        window.TimelineEvent = TimelineEvent;
    }

})();