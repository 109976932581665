/**
 * Project Controller
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Controller to the module
    angular.module("Sinewav3.Client.Shell")
        .controller(
            'ProjectController',
            [
                '$rootScope',
                '$scope',
                '$timeout',
                '$window',
                '$mdDialog',
                '$mdSidenav',
                'FocusService',
                'ToastService',
                'AssetService',
                'ShareService',
                'ProjectService',
                'BroadcastService',
                'EVENTS',
                'CHROME',
                'SECTIONS',
                'UPLOAD_TYPES',
                'ASSET_SERVICE_RESPONSES',
                'PROJECT_SERVICE_RESPONSES',
                'VISUALIZER',
                ProjectController
            ]
        );

    // Constructor
    function ProjectController($rootScope,
                               $scope,
                               $timeout,
                               $window,
                               $mdDialog,
                               $mdSidenav,
                               FocusService,
                               ToastService,
                               AssetService,
                               ShareService,
                               ProjectService,
                               BroadcastService,
                               EVENTS,
                               CHROME,
                               SECTIONS,
                               UPLOAD_TYPES,
                               ASSET_SERVICE_RESPONSES,
                               PROJECT_SERVICE_RESPONSES,
                               VISUALIZER
    ) {
        // Construct and initialize the instance
        let instance = this;
        instance.onDestroy = onDestroy;
        instance.onNewProjectAction = onNewProjectAction;
        instance.createProject = createProject;
        instance.deleteProject = deleteProject;
        instance.selectProject = selectProject;
        instance.visualizeProject = visualizeProject;
        instance.showSubmittedDialog = showSubmittedDialog;
        instance.onRenderProject = onRenderProject;
        instance.renderProject = renderProject;
        instance.onShareProject = onShareProject;
        instance.shareProject = shareProject;
        instance.pickFile = pickFile;
        instance.createAsset = createAsset;
        instance.onProjectCRUDResponse = onProjectCRUDResponse;
        instance.onAssetCRUDResponse = onAssetCRUDResponse;
        instance.getSecondaryActions = getSecondaryActions;
        instance.invokeSecondaryAction = invokeSecondaryAction;
        instance.invokeFabAction = invokeFabAction;
        instance.getProjectStatusIcon = getProjectStatusIcon;
        instance.getProjectStatusMessage = getProjectStatusMessage;
        instance.resetOrUpdatePlugin = resetOrUpdatePlugin;

        initialize();

        // Initialize the controller
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.SAVE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.CLOSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.PAUSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.RESUME.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.SETTINGS.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.SNAPSHOT.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.NEW_PROJ.ACTION, instance.onNewProjectAction ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS, instance.onAssetCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, instance.onAssetCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.SUCCESS, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.FAILURE, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.COPY_PROJECT.SUCCESS, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.COPY_PROJECT.FAILURE, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.SUCCESS, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.FAILURE, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.SUCCESS, instance.onProjectCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.FAILURE, instance.onProjectCRUDResponse ) );

            // Show the FAB Menu if editing, or FAB Button with the New Project if not
           let projectModelScope = $rootScope.project;
            if ( projectModelScope.editing && projectModelScope.hasChanged(projectModelScope.selected) ){
                BroadcastService.send(EVENTS.SHOW_FAB_MENU, $rootScope.chrome.fab.menu.sets.editing);
            } else if ( projectModelScope.editing ) {
                projectModelScope.deselectProject();
            } else {
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.NEW_PROJ);
            }

            // For Upload buttons
            $scope.UPLOAD_TYPES = UPLOAD_TYPES;

            // For populating default value menu when editing settings of type MODULATOR
            $scope.MOD_TYPES = Object.values(Setting.MODULATORS);
            $scope.EASE_TYPES = Object.values(Setting.EASES);
            $scope.LOOP_TYPES = Object.values(Setting.LOOPS);

            // Color picker options for settings of type COLOR
            $scope.options  = JSON.parse(JSON.stringify(CHROME.COLOR_PICKER));
            $scope.options.inputClass = 'materialized-color-picker setting-control-color-picker';
            $scope.options.pos ='bottom left';

            // For project setting sidenav
            $scope.toggleSideNav = buildToggler('settings');
            function buildToggler(componentId) {
                return function() {
                    $mdSidenav(componentId).toggle();
                };
            }
        }

        /**
         * Remove event listeners when the controller is destroyed
         * Also hide the FAB button as we are navigating away from the Project page
         */
        function onDestroy(){
            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
            BroadcastService.send(EVENTS.HIDE_FAB);
        }

        /**
         * Show the Create Project dialog
         */
        function onNewProjectAction(event, data) {
            // SHOW THE NEW PROJECT DIALOG
            $mdDialog.show({
                templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.CREATE_PROJ + SECTIONS.POSTFIX,
                parent: angular.element(document.body),
                targetEvent: event,
                fullscreen:true,
                clickOutsideToClose:false,
                controller:
                    function ($scope, $mdDialog, $rootScope) {
                            $scope.project = $rootScope.project;
                            $scope.cancel = () => $mdDialog.cancel();
                            $scope.submit = () => $mdDialog.hide();
                    }
                })
                .then(
                    () => instance.createProject(),        // SUBMIT
                    () => $scope.project.resetFormInputs() // CANCEL
                );
        }

        /**
         * Create a new project
         */
        function createProject() {
            let name = $rootScope.project.input.name;
            let userToken = $rootScope.account.profile.getToken();
            ProjectService.createProject(name, userToken);
        }

        /**
         * Delete a project
         * @param project
         */
        function deleteProject(project) {
            let message = 'Are you sure you want to delete '+project.name+"?";
            let confirm = $mdDialog.confirm()
                .title('Delete Project')
                .textContent(message)
                .ariaLabel(message)
                .ok('Cancel')
                .cancel('Delete');

            $mdDialog.show(confirm)
                .then(
                    () => { }, // CANCEL
                    () => {    // DELETE
                        ProjectService.deleteProject(project)
                    }
                )
        }

        /**
         * The user has clicked on a project in the list
         * If it is submitted, don't allow editing
         * @param project
         */
        function selectProject(project) {
            if (project.status != Project.STATUS.SUBMITTED) {
                // Select the project in the ProjectScopeModel
                $rootScope.project.selectProject(project);

                // Show the FAB MENU with the editing controls
                BroadcastService.send(EVENTS.SHOW_FAB_MENU, $rootScope.chrome.fab.menu.sets.editing);

            } else {
                instance.showSubmittedDialog(project);
            }
        }

        /**
         * The user has clicked the visualize button in the editor
         *
         * @param project
         */
        function visualizeProject(project) {
            if (project.status != Project.STATUS.SUBMITTED) {
                let projectScope = $rootScope.project;
                let chromeScope = $rootScope.chrome;

                // Set the visualizer shell state
                let getSelectedWorldIndex = projectScope.visualizeProject(project);

                // Show the FAB Menu with the visualizer running controls
                let fabSet = projectScope.allowSettingEditing()
                    ? chromeScope.fab.menu.sets.visualizing
                    : chromeScope.fab.menu.sets.visualizing_no_setting;
                BroadcastService.send(EVENTS.SHOW_FAB_MENU, fabSet);

                // Start the visualizer core, giving time for visualizer pane to be shown/sized
                $timeout( () => VISUALIZER.visualize(project, CHROME.VISUALIZER_PANE, getSelectedWorldIndex), 1000);

            } else {
                instance.showSubmittedDialog(project);
            }
        }

        /**
         * Show render project dialog
         */
        function onRenderProject(project) {

            $mdDialog.show({
                templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.RENDER_DLG + SECTIONS.POSTFIX,
                parent: angular.element(document.body),
                targetEvent: event,
                fullscreen: true,
                clickOutsideToClose: false,
                controller:
                    function ($scope, $mdDialog, $rootScope) {
                        $scope.project = $rootScope.project;
                        $scope.cancel = () => $mdDialog.cancel();
                        $scope.submit = () => $mdDialog.hide();
                    }
            })
            .then(
                () => instance.renderProject(project), // SUBMIT
                () => $scope.project.resetFormInputs() // CANCEL
            );
        }

        /**
         * The user has clicked the render button in the list
         *
         * @param project
         */
        function renderProject(project) {
            if (project.status != Project.STATUS.SUBMITTED) {

                // Set the visualizer shell state
                $rootScope.project.renderProject(project);

                // Start the visualizer core
                VISUALIZER.render(project);

                // Show the FAB BUTTON with the CLOSE option
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.CLOSE);

            } else {
                instance.showSubmittedDialog(project);
            }
        }

        /**
         * The user wants to share a project
         * @param project
         */
        function onShareProject(project) {
            let scopeModel = $rootScope.project;
            let alias = project.name.toLowerCase().replace(/\s/g, '-');
            // Find the existing sharing alias if present
            ShareService.findShareByProjectId(project.id)
                .then(snapshot => {
                    let data, share;
                    if (snapshot.numChildren() !== 0) {
                        snapshot.forEach(childSnap => data = childSnap.val());
                        share = ProjectShare.fromObject(data);
                        alias = share.alias;
                    }
                    scopeModel.setAlias(alias);
                    scopeModel.prepareToShare(project, share);
                    showShareDialog();
                })
                .catch(() => {
                    scopeModel.setAlias(alias);
                    scopeModel.prepareToShare(project);
                    showShareDialog();
                });

            // Show the share project dialog
            function showShareDialog(){
                $mdDialog.show({
                        templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.SHARE_PROJ + SECTIONS.POSTFIX,
                        parent: angular.element(document.body),
                        fullscreen:true,
                        clickOutsideToClose:false,
                        controller:
                            function ($scope, $mdDialog, $rootScope) {
                                let scopeModel = $scope.project = $rootScope.project;
                                $scope.checkAlias = checkAlias;
                                $scope.cancel = () => $mdDialog.cancel();
                                $scope.submit = () => {instance.shareProject(); $mdDialog.hide();};
                                $scope.checkAlias();

                                // Find the existing sharing alias if present
                                function checkAlias() {
                                    if (scopeModel.input.alias) {
                                        ShareService.findShareByAlias(scopeModel.input.alias)
                                            .then(snapshot => {
                                                let share = ProjectShare.fromObject(snapshot.val());
                                                validateField(project, share);
                                            })
                                            .catch(() => {
                                                validateField(project);
                                            });

                                        function validateField(project, share) {
                                            scopeModel.prepareToShare(project, share);
                                            $scope.$apply(() => $scope.shareProjectForm.alias.$setValidity('taken', !scopeModel.aliasIsTaken()));

                                        }
                                    }
                                }
                            }
                    })
                    .then(
                        () => {},    // SUBMIT
                        () => $scope.project.resetState() // CANCEL
                    );
            }
        }

        /**
         * Share project
         */
        function shareProject(event) {
            let scopeModel = $rootScope.project;
            let env = $rootScope.shell.getHostPrefix();
            let content, url = `${env}/visualize/${scopeModel.input.alias}`;

            // Save the alias if it's new
            if (scopeModel.alias_ok && !scopeModel.alias_exists) {
                ShareService.shareProject(scopeModel.input.share);
                $timeout(function() {
                    let message = 'Project alias saved!';
                    ToastService.showToast(message);
                });
            }

            // Copy the url or embed code to the clipboard
            if (scopeModel.input.share_type === "URL") {
                content = `${env}/visualize/${scopeModel.input.alias}`;
            } else {
                content = `<iframe type="text/html" width="640" height="360" src="${url}" frameborder="0"></iframe>`;
            }

            // Create textarea, insert text, copy to clipboard, remove textarea
            copyToClipboard(content);

            $timeout(function() {
                let message = 'Copied to clipboard!';
                ToastService.showToast(message);
            });

            // Reset the scope model state
            scopeModel.resetState();

            function copyToClipboard (str) {
                console.log('in copyToClipboard');
                const el = document.createElement('textarea');  // Create a <textarea> element
                el.value = str;                                 // Set its value to the string that you want copied
                el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
                el.style.position = 'absolute';
                el.style.left = '-9999px';                      // Move outside the screen to make it invisible
                document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
                const selected =
                    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
                        ? document.getSelection().getRangeAt(0)     // Store selection if found
                        : false;                                    // Mark as false to know no selection existed before
                el.select();                                    // Select the <textarea> content
                document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
                document.body.removeChild(el);                  // Remove the <textarea> element
                if (selected) {                                 // If a selection existed before copying
                    document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
                    document.getSelection().addRange(selected);   // Restore the original selection
                }
            }
        }

        /**
         * Pick a file of a given type for use in the Project
         */
        function pickFile(type) {
            $rootScope.asset.input.type = type;
            $rootScope.asset.lock_type = true;

            // SHOW THE NEW ASSET DIALOG
            $mdDialog.show({
                    templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.ASSET_DLG + SECTIONS.POSTFIX,
                    parent: angular.element(document.body),
                    fullscreen:true,
                    clickOutsideToClose:false,
                    controller:
                        function ($scope, $mdDialog, $rootScope) {
                            $scope.asset = $rootScope.asset;
                            $scope.UPLOAD_TYPES = UPLOAD_TYPES;
                            $scope.cancel = () => $mdDialog.cancel();
                            $scope.submit = () => $mdDialog.hide();
                            $scope.onChange = function(files) {
                                $timeout( () => {
                                    if ($scope.asset.input.type === UPLOAD_TYPES.CUBE) {
                                        if (files.length === 6) {
                                            let arry = [], list = '';
                                            for (let i = 0; i < files.length; i++) {
                                                arry.push(files[i]);
                                                if (i>0) list += ', ';
                                                list += files[i].name;
                                            }
                                            $scope.asset.input.files = arry;
                                            $scope.asset.input.filelist = list;
                                            $timeout( () => FocusService.focusOn('assetName') );
                                        }
                                    } else {
                                        $scope.asset.input.file = files[0];
                                        $scope.asset.input.name = $scope.asset.extractAssetName($scope.asset.input.file.name);
                                        if ($scope.asset.input.type === UPLOAD_TYPES.AUDIO) {
                                            try {
                                                let audio_object_url = URL.createObjectURL($scope.asset.input.file);
                                                let audio_element = document.getElementById('audio');
                                                audio_element.addEventListener('canplaythrough', event => {
                                                    $scope.$apply(() => {
                                                        $scope.asset.input.duration = event.currentTarget.duration;
                                                        $scope.asset.input.duration_extracted = event.currentTarget.duration > 0;
                                                    });
                                                    URL.revokeObjectURL(audio_object_url);
                                                });
                                                audio_element.src = audio_object_url;
                                            } catch (e){
                                                console.log('Unable to extract audio track duration.');
                                            }
                                        }
                                        $timeout( () => FocusService.focusOn('assetName') );
                                    }
                                });
                            }
                        }
                })
                .then(
                    () => instance.createAsset(),        // SUBMIT
                    () => $scope.asset.deselectAsset() // CANCEL
                )
        }

        /**
         * Create a new asset
         */
        function createAsset( ) {
            let assetScopeModel = $rootScope.asset;
            $rootScope.asset.lock_type = false;
            assetScopeModel.uploadingAsset();

            let name = assetScopeModel.input.name;
            let file = assetScopeModel.input.file;
            let files = assetScopeModel.input.files;
            let duration = $scope.asset.input.duration;
            let type = assetScopeModel.input.type.TYPE;
            let userToken = $rootScope.account.profile.getToken();
            let namingFn = $scope.asset.extractAssetName;
            if (type != AssetGroup.TYPE.CUBE) {
                AssetService.createAsset(userToken, type, name, file, duration);
            } else {
                AssetService.createAssetGroup(userToken, type, name, files, Asset.TYPE.IMAGE, namingFn);
            }
        }

        /**
         * Don't allow tweaking a project while it is submitted
         * @param project
         */
        function showSubmittedDialog(project) {
            let message = 'Wait for it to be approved, or cancel the submission.';
            let confirm = $mdDialog.confirm()
                .title('Project Template Submitted')
                .textContent(message)
                .ariaLabel(message)
                .ok('Ok')
                .cancel('Cancel Submission');

            $mdDialog.show(confirm)
                .then(function(){}) // Ok
                .catch(
                    () => ProjectService.cancelSubmission(project)
                );
        }

        /**
         * Handle the CREATE_PROJECT, UPDATE_PROJECT, and DELETE_PROJECT responses
         * @param event
         * @param data
         */
        function onProjectCRUDResponse(event, data) {
            if (!$rootScope.project.visualizing) $rootScope.project.resetFormInputs();
            let message;
            switch (event.name)
            {
                case PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.SUCCESS:
                    $timeout( () => instance.selectProject(data) );
                    break;

                case PROJECT_SERVICE_RESPONSES.COPY_PROJECT.SUCCESS:
                    $timeout(function() {
                        message = 'Project copied.';
                        ToastService.showToast(message);
                    });
                    break;

                case PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.SUCCESS:
                    $timeout(function() {
                        message = 'Project updated.';
                        ToastService.showToast(message);
                    });
                    break;

                case PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.SUCCESS:
                    $timeout(function() {
                        message = 'Project deleted!';
                        ToastService.showToast(message);
                    });
                    break;

                case PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.FAILURE:
                case PROJECT_SERVICE_RESPONSES.COPY_PROJECT.FAILURE:
                case PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.FAILURE:
                case PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the CREATE_ASSET responses
         * @param event
         * @param data
         */
        function onAssetCRUDResponse(event, data) {
            $rootScope.asset.deselectAsset();
            switch (event.name)
            {
                case ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS:
                    ToastService.showToast('Asset Uploaded.');
                    break;

                case ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Return the list of secondary actions for the given project
         * @param project
         */
        function getSecondaryActions(project) {
            let actions = [];

            if (project.isValid()) {

                // SHARE
                actions.push(CHROME.FAB.ACTIONS.SHARE);

                // RENDER
                if ($rootScope.shell.webgl) actions.push(CHROME.FAB.ACTIONS.RENDER);

                // PUBLISH TODO: replace when publishing of templates works
                // if (project.status === Project.STATUS.DEVELOPMENT) actions.push(CHROME.FAB.ACTIONS.PUBLISH);
            }

            // CANCEL PUBLISH REQUEST
            if (project.status === Project.STATUS.SUBMITTED) actions.push(CHROME.FAB.ACTIONS.CANCEL);

            // COPY
            actions.push(CHROME.FAB.ACTIONS.COPY);

            // DELETE
            if (project.status !== Project.STATUS.SUBMITTED) actions.push(CHROME.FAB.ACTIONS.DELETE);

            return actions;
        }

        /**
         * Invoke a secondary action from an icon in the project list
         * @param project
         * @param action
         */
        function invokeSecondaryAction(project,action) {
            switch (action){

                case CHROME.FAB.ACTIONS.SHARE.ACTION:
                    instance.onShareProject(project);
                    break;

                case CHROME.FAB.ACTIONS.PUBLISH.ACTION:
                    ProjectService.submitTemplate(project);
                    break;

                case CHROME.FAB.ACTIONS.CANCEL.ACTION:
                    ProjectService.cancelSubmission(project);
                    break;

                case CHROME.FAB.ACTIONS.DELETE.ACTION:
                    instance.deleteProject(project);
                    break;

                case CHROME.FAB.ACTIONS.COPY.ACTION:
                    ProjectService.copyProject(project);
                    break;

                case CHROME.FAB.ACTIONS.RENDER.ACTION:
                    instance.onRenderProject(project);
                    break;
            }
            // So that the action can be tracked...
            BroadcastService.send(action,project);
        }

        /**
         * While a project is selected, the user has invoked
         * an action from the FAB menu
         * @param event
         */
        function invokeFabAction(event) {
            let action = event.name;
            let projectScope = $rootScope.project;
            let project = projectScope.selected;
            let message;
            let fabSet;
            switch (action)
            {
                case CHROME.FAB.ACTIONS.SAVE.ACTION:
                    // Update the project
                    message = 'Project saved.';
                    ProjectService.updateProject(project);
                    break;

                case CHROME.FAB.ACTIONS.CLOSE.ACTION:
                    if (projectScope.editing && projectScope.hasChanged(project))
                    {
                        message = 'Do you want to abandon your changes?';
                        let confirm = $mdDialog.confirm()
                            .title('Unsaved Changes')
                            .textContent(message)
                            .ariaLabel(message)
                            .ok('Cancel')
                            .cancel('Abandon');

                        $mdDialog.show(confirm)
                            .then(() =>  {}) // Cancel
                            .catch(() => cancelAction()); // Abandon
                    } else {
                        cancelAction()
                    }
                    break;

                case CHROME.FAB.ACTIONS.PAUSE.ACTION:
                    fabSet = projectScope.allowSettingEditing()
                        ? $rootScope.chrome.fab.menu.sets.paused
                        : $rootScope.chrome.fab.menu.sets.paused_no_setting;
                    // Change the FAB menu to have the resume button instead of pause
                    BroadcastService.send(EVENTS.SHOW_FAB_MENU, fabSet);

                    // Tell the Visualizer to pause
                    VISUALIZER.pause();
                    break;

                case CHROME.FAB.ACTIONS.RESUME.ACTION:
                    fabSet = projectScope.allowSettingEditing()
                        ? $rootScope.chrome.fab.menu.sets.visualizing
                        : $rootScope.chrome.fab.menu.sets.visualizing_no_setting;
                    // Change the FAB menu to have the pause button instead of resume
                    BroadcastService.send(EVENTS.SHOW_FAB_MENU, fabSet);

                    // Tell the Visualizer to resume
                    VISUALIZER.resume();
                    break;

                case CHROME.FAB.ACTIONS.SETTINGS.ACTION:
                    projectScope.toggleProjectDrillDown();
                    break;

                case CHROME.FAB.ACTIONS.SNAPSHOT.ACTION:
                    break;
            }

            // Inner function handle the cancel action which is varies by state
            function cancelAction(){
                if (projectScope.visualizing || projectScope.rendering || projectScope.analyzing) VISUALIZER.close();
                if (projectScope.visualizing) {
                    projectScope.editProject();
                    BroadcastService.send(EVENTS.SHOW_FAB_MENU, $rootScope.chrome.fab.menu.sets.editing);
                } else {
                    projectScope.deselectProject();
                    BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.NEW_PROJ);
                }
            }
        }

        /**
         * Get the status icon for a project
         * @param project
         * @returns {string}
         */
        function getProjectStatusIcon(project) {
            let icon = CHROME.ICON.STATUS.NEEDS_WORK;
            if (project.isValid()){
                switch (project.status)
                {
                    case Project.STATUS.DEVELOPMENT:
                        icon = CHROME.ICON.STATUS.READY;
                        break;

                    case Project.STATUS.SUBMITTED:
                        icon = CHROME.ICON.STATUS.SUBMITTED;
                        break;

                    case Project.STATUS.REJECTED:
                        icon = CHROME.ICON.STATUS.REJECTED;
                        break;

                    case Project.STATUS.APPROVED:
                        icon = CHROME.ICON.STATUS.APPROVED;
                        break;
                }
            }
            return icon;
        }

        /**
         * Get the status message for a project
         * @param project
         * @returns {string}
         */
        function getProjectStatusMessage( project ) {
            let message = project.isValid()
                ? "Ready to visualize or render."
                : "Not yet valid. Needs work.";
            return message;
        }

        /**
         * Replace the given plugin in the given world with a clone from the list.
         * This as two effects:
         *    1) to reset all settings to default
         *    2) update the plugin code and settings if either has changed
         */
        function resetOrUpdatePlugin( world, plugin ){
            if( world && world.plugins ) {
                let plugin_scope = $rootScope.plugin;
                let project_scope = $rootScope.project;
                let master_list = plugin_scope.getUsablePlugins();
                const world_pos = world.plugins.map( x => x.id ).indexOf( plugin.id );
                const list_pos  = master_list.map( x => x.id ).indexOf( plugin.id );

                // If original plugin still exists, replace the one in the world with the original
                if ( list_pos > -1 ) {
                    let clone_original = Plugin.fromObject( master_list[ list_pos ].toObject() );
                    world.plugins[ world_pos ] = clone_original;
                    if ( project_scope.input.plugin && project_scope.input.plugin.id === clone_original.id ) {
                        project_scope.pluginChanged( clone_original );
                    }
                } else { // otherwise, just reset the plugin's settings to their defaults
                    plugin.resetPluginSettings();
                }
            }
        }

    }
})(); // IIFE keeps global scope clean
