/**
 * Sinewav3 Schema Entity: Render
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Render = (function() {

        /**
         * Constructor
         * @param id
         * @param project_id
         * @param owner
         * @param status
         * @param request_time
         * @param finish_time
         * @param output_url
         * @constructor
         */
        function Render(id, project_id, owner, status, request_time, finish_time, output_url) {
            this.id = id;
            this.project_id = project_id;
            this.owner = owner;
            this.status = status;
            this.request_time = request_time;
            this.finish_time = finish_time;
            this.output_url = output_url;
        }

        /**
         * Render Status Constants
         * @type {{SUBMITTED: string, RENDERING: string, ABORTED: string, COMPLETED: string}}
         */
        Render.STATUS = {
            SUBMITTED: 'submitted',
            RENDERING: 'rendering',
            ABORTED: 'aborted',
            COMPLETED: 'completed'
        };

        /**
         * Get a new Render instance from a database representation
         * @param o
         * @returns {Render}
         */
        Render.fromObject = function(o) {
            var owner_name = o.owner ? new NameToken(o.owner.name.display, o.owner.name.first, o.owner.name.last) : null;
            var owner = o.owner ? new UserToken(o.owner.uid, owner_name, o.owner.photo_url) : null;
            return new Render(
                o.id,
                o.project_id,
                owner,
                o.status,
                o.request_time,
                o.finish_time,
                o.output_url
            );
        };

        /**
         * Get a database representation of this Render instance
         * @returns {object}
         */
        Render.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Render instance
         * @returns {string}
         */
        Render.prototype.toString = function() {
            return [
                this.id,
                this.project_id,
                this.owner ? this.owner.toString() : "",
                this.status,
                this.request_time,
                this.finish_time,
                this.output_url
            ].join(' ');
        };

        /**
         * Is this Render instance's id field valid?
         * @returns {boolean|*}
         */
        Render.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance's project_id field valid?
         * @returns {boolean|*}
         */
        Render.prototype.projectIdIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.project_id !== null &&
                    typeof this.project_id !== 'undefined' &&
                    typeof this.project_id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance's owner field valid?
         * @returns {boolean|*}
         */
        Render.prototype.ownerIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.owner !== 'undefined' && this.owner !== null &&
                    Object.getPrototypeOf(this.owner) === UserToken.prototype &&
                    this.owner.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance's status field valid?
         * @returns {boolean|*}
         */
        Render.prototype.statusIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.status !== null &&
                    typeof this.status !== 'undefined' &&
                    typeof this.status === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance's status field valid?
         * @returns {boolean|*}
         */
        Render.prototype.statusIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.status !== null &&
                    typeof this.status !== 'undefined' &&
                    typeof this.status === 'string' &&
                    (
                        this.status === Render.STATUS.SUBMITTED ||
                        this.status === Render.STATUS.RENDERING ||
                        this.status === Render.STATUS.ABORTED ||
                        this.status === Render.STATUS.COMPLETED
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance's request_time field valid?
         * @returns {boolean}
         */
        Render.prototype.requestTimeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.request_time !== null &&
                    typeof this.request_time !== 'undefined' &&
                    typeof this.request_time === 'number' &&
                    Number.isInteger(this.request_time)  &&
                    this.request_time > 1483228800 // 01/01/2017 @ 12:00am (UTC)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Render instance valid?
         * @returns {boolean|*}
         */
        Render.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.projectIdIsValid() &&
                this.ownerIsValid() &&
                this.statusIsValid() &&
                this.requestTimeIsValid()
            );
        };

        return Render;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Render;
    } else {
        window.Render = Render;
    }

})();