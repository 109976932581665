/**
 * Sinewav3 Schema Entity: Usage
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Usage = (function() {

        /**
         * Constructor
         * @param resource
         * @param user
         * @param time
         * @param amount
         * @param unit
         * @constructor
         */
        function Usage(resource, user, time, amount, unit) {
            this.resource = resource;
            this.user = user;
            this.time = time;
            this.amount = amount;
            this.unit = unit
        }

        /**
         * Get a new Usage instance from a database representation
         * @param o
         * @returns {Usage}
         */
        Usage.fromObject = function(o) {
            var user_name = o.user ? new NameToken(o.user.name.display, o.user.name.first, o.user.name.last) : null;
            var user = o.user ? new UserToken(o.user.uid, user_name, o.user.photo_url) : null;
            return new Usage(o.resource, user, o.time, o.amount, o.unit);
        };

        /**
         * Get a database representation of this Usage instance
         * @returns {object}
         */
        Usage.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Usage instance
         * @returns {string}
         */
        Usage.prototype.toString = function() {
            return [
                this.resource,
                this.user ? this.user.toString() : "",
                this.time,
                this.amount,
                this.unit
            ].join(' ');
        };

        /**
         * Is this Usage instance's resource field valid?
         * @returns {boolean|*}
         */
        Usage.prototype.resourceIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.resource !== 'undefined' &&
                    typeof this.resource === 'string' &&
                    (
                        this.resource === Entitlement.RESOURCE.RENDERS ||
                        this.resource === Entitlement.RESOURCE.STORAGE ||
                        this.resource === Entitlement.RESOURCE.SVC_UPLOADS ||
                        this.resource === Entitlement.RESOURCE.QUEUE_BUMPS
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Usage instance's user field valid?
         * @returns {boolean}
         */
        Usage.prototype.userIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.user !== null &&
                    typeof this.user !== 'undefined' &&
                    Object.getPrototypeOf( this.user ) === UserToken.prototype &&
                    this.user.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Usage instance's time field valid?
         * @returns {boolean}
         */
        Usage.prototype.timeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.time !== null &&
                    typeof this.time !== 'undefined' &&
                    typeof this.time === 'number' &&
                    Number.isInteger(this.time)  &&
                    this.time > 1483228800 // 01/01/2017 @ 12:00am (UTC)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Usage instance's amount field valid?
         * @returns {boolean}
         */
        Usage.prototype.amountIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.amount !== null &&
                    typeof this.amount !== 'undefined' &&
                    typeof this.amount === 'number' &&
                    this.amount > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Usage instance valid?
         * @returns {boolean|*}
         */
        Usage.prototype.isValid = function() {
            return (
                this.resourceIsValid() &&
                this.userIsValid() &&
                this.amountIsValid()
            );
        };

        return Usage;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Usage;
    } else {
        window.Usage = Usage;
    }

})();