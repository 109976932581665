/**
 * Plugin Controller
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Controller to the module
    angular.module("Sinewav3.Client.Shell")
        .controller(
            'PluginController',
            [
                '$rootScope',
                '$scope',
                '$timeout',
                '$mdDialog',
                '$window',
                'ToastService',
                'FocusService',
                'PluginService',
                'PublishService',
                'LikeService',
                'BroadcastService',
                'EVENTS',
                'CHROME',
                'SECTIONS',
                'PLUGIN_SERVICE_RESPONSES',
                PluginController
            ]
        );

    // Constructor
    function PluginController($rootScope,
                              $scope,
                              $timeout,
                              $mdDialog,
                              $window,
                              ToastService,
                              FocusService,
                              PluginService,
                              PublishService,
                              LikeService,
                              BroadcastService,
                              EVENTS,
                              CHROME,
                              SECTIONS,
                              PLUGIN_SERVICE_RESPONSES
    ) {
        // Construct and initialize the instance
        let instance = this;
        instance.onDestroy = onDestroy;
        instance.onCodeResize = onCodeResize;
        instance.onNewPluginAction = onNewPluginAction;
        instance.createPlugin = createPlugin;
        instance.deletePlugin = deletePlugin;
        instance.selectPlugin = selectPlugin;
        instance.describePlugin = describePlugin;
        instance.deselectPlugin = deselectPlugin;
        instance.copyPlugin = copyPlugin;
        instance.approvePlugin = approvePlugin;
        instance.rejectPlugin = rejectPlugin;
        instance.onPublishPlugin = onPublishPlugin;
        instance.publishPlugin = publishPlugin;
        instance.onPluginCRUDResponse = onPluginCRUDResponse;
        instance.getSecondaryActions = getSecondaryActions;
        instance.getPublicSecondaryActions = getPublicSecondaryActions;
        instance.getSubmittedSecondaryActions = getSubmittedSecondaryActions;
        instance.invokeSecondaryAction = invokeSecondaryAction;
        instance.invokeFabAction = invokeFabAction;
        instance.getPluginStatusIcon = getPluginStatusIcon;
        instance.getPluginStatusMessage = getPluginStatusMessage;
        instance.getSettingStatusIcon = getSettingStatusIcon;
        instance.selectFnSetup = selectFnSetup;
        instance.selectFnRender = selectFnRender;
        instance.selectFnDestroy = selectFnDestroy;
        instance.getFnSetupIcon = getFnSetupIcon;
        instance.getFnRenderIcon = getFnRenderIcon;
        instance.getFnDestroyIcon = getFnDestroyIcon;
        instance.getFnSetupColor = getFnSetupColor;
        instance.getFnRenderColor = getFnRenderColor;
        instance.getFnDestroyColor = getFnDestroyColor;
        instance.getSettingStatusColor = getSettingStatusColor;
        instance.sortMyPlugins = sortMyPlugins;
        instance.sortProductPlugins = sortProductPlugins;
        instance.sortExamplePlugins = sortExamplePlugins;
        instance.sortPublicPlugins = sortPublicPlugins;
        instance.sortSubmittedPlugins = sortSubmittedPlugins;

        initialize();

        // Initialize the controller
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.CODE_RESIZE, instance.onCodeResize ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.SAVE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.CLOSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.NEW_PLUG.ACTION, instance.onNewPluginAction ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.SUBMIT_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.SUBMIT_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.CANCEL_PLUGIN.SUCCESS, instance.onPluginCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.CANCEL_PLUGIN.FAILURE, instance.onPluginCRUDResponse ) );

            // Show the FAB Menu if editing, or FAB Button with the New Project if not
            let pluginModelScope = $rootScope.plugin;
            if ( pluginModelScope.editing && pluginModelScope.hasChanged(pluginModelScope.selected) ){
                BroadcastService.send(EVENTS.SHOW_FAB_MENU, $rootScope.chrome.fab.menu.sets.editing);
            } else if ( pluginModelScope.editing ) {
                instance.deselectPlugin();
            } else {
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.NEW_PLUG);
            }

            // For populating asset type menu when editing settings
            $scope.ASSET_TYPES = [
                {desc: 'Image', type: Asset.TYPE.IMAGE},
                {desc: 'Cube Map', type: AssetGroup.TYPE.CUBE},
                {desc: '3D Model', type: Asset.TYPE.MODEL},
                {desc: 'Font',     type: Asset.TYPE.FONT},
            ];

            // For populating default value menu when editing settings of type MODULATOR
            $scope.MOD_TYPES = Object.values(Setting.MODULATORS);
            $scope.EASE_TYPES = Object.values(Setting.EASES);
            $scope.LOOP_TYPES = Object.values(Setting.LOOPS);

            // Color picker options for settings of type COLOR
            $scope.options = CHROME.COLOR_PICKER;

        }

        /**
         * Remove event listeners when the controller is destroyed
         * Also hide the FAB button as we are navigating away from the Plugin page
         */
        function onDestroy() {
            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
            BroadcastService.send(EVENTS.HIDE_FAB);
        }

        /**
         * Handle user resize of a code editor
         * @param event
         * @param data
         */
        function onCodeResize(event, data) {
            let id;
            switch (data.id) {
                case 'fn_setup_wrapper':
                    id = 'fn_setup';
                    break;

                case 'fn_render_wrapper':
                    id = 'fn_render';
                    break;

                case 'fn_destroy_wrapper':
                    id = 'fn_destroy';
                    break;
            }
            let editor = ace.edit(id);
            editor.resize(true);
        }

        /**
         * Show the Create Plugin dialog
         */
        function onNewPluginAction(event) {
            // SHOW THE NEW PLUGIN DIALOG
            $mdDialog.show({
                templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.PLUG_DLG + SECTIONS.POSTFIX,
                parent: angular.element(document.body),
                targetEvent: event,
                fullscreen:true,
                clickOutsideToClose:false,
                controller:
                    function ($scope, $mdDialog, $rootScope) {
                        $scope.plugin = $rootScope.plugin;
                        $scope.cancel = () => $mdDialog.cancel();
                        $scope.submit = () => $mdDialog.hide();
                    }
                })
                .then(
                    () => instance.createPlugin(),        // SUBMIT
                    () => $scope.plugin.resetFormInputs() // CANCEL
                );
        }

        /**
         * Create a new plugin
         */
        function createPlugin( ) {
            let name = $rootScope.plugin.input.name;
            let userToken = $rootScope.account.profile.getToken();
            PluginService.createPlugin(name, userToken);
        }

        /**
         * Delete a plugin
         * @param plugin
         */
        function deletePlugin(plugin) {
            let confirm = $mdDialog.confirm()
                .title('Delete Plugin')
                .textContent('Are you sure you want to delete '+plugin.name+"?")
                .ariaLabel('Are you sure?')
                .ok('Cancel')
                .cancel('Delete');

            $mdDialog.show(confirm)
                .then(
                    () => { }, // CANCEL
                    () => {    // DELETE
                        PluginService.deletePlugin(plugin)
                    }
                )
        }

        /**
         * The user has clicked on a plugin in the list
         * If it is submitted, don't allow editing
         ** @param plugin
         */
        function selectPlugin(plugin) {
            let message, confirm;

            switch ( plugin.status ) {

                case Plugin.STATUS.DEVELOPMENT:
                    // Select the plugin
                    $rootScope.plugin.selectPlugin(plugin);

                    // Show the FAB MENU with the editing controls
                    BroadcastService.send(EVENTS.SHOW_FAB_MENU, $rootScope.chrome.fab.menu.sets.editing);
                    break;

                case Plugin.STATUS.SUBMITTED:
                    message = 'Wait for it to be approved, or cancel the submission.';
                    confirm = $mdDialog.confirm()
                        .title('Plugin Submitted')
                        .textContent(message)
                        .ariaLabel(message)
                        .ok('Ok')
                        .cancel('Cancel Submission');

                    $mdDialog.show(confirm)
                        .then(function(){}) // Ok
                        .catch(
                            () => PluginService.cancelSubmission(plugin)
                        );
                    break;

                case Plugin.STATUS.APPROVED:
                    message = 'Cancel and resubmit if you need to make changes.';
                    confirm = $mdDialog.confirm()
                        .title('Plugin Approved')
                        .textContent(message)
                        .ariaLabel(message)
                        .ok('Ok')
                        .cancel('Cancel Submission');

                    $mdDialog.show(confirm)
                        .then(function(){}) // Ok
                        .catch(
                            () => PluginService.cancelSubmission(plugin)
                        );
                    break;

                case Plugin.STATUS.REJECTED:
                    message = plugin.reject_reason +' This plugin has been rejected. Copy it if you want to edit and re-submit.';
                    confirm = $mdDialog.confirm()
                        .title('Plugin Rejected')
                        .textContent(message)
                        .ariaLabel(message)
                        .ok('Ok')
                        .cancel('Copy Plugin');

                    $mdDialog.show(confirm)
                        .then(function(){}) // Ok
                        .catch(
                            () => instance.copyPlugin(plugin)
                        );
                    break;
            }
        }

        /**
         * Deselect the currently selected plugin
         */
        function deselectPlugin(){
            // Deselect the plugin and reset the form inputs in the PluginScopeModel
            $rootScope.plugin.deselectPlugin();

            // Show the FAB BUTTON with the NEW_PLUG action
            BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.NEW_PLUG);
        }

        /**
         * Copy a plugin
         * @param plugin
         */
        function copyPlugin(plugin){
            let userToken = $rootScope.account.profile.getToken();
            PluginService.copyPlugin(plugin, userToken)
        }

        /**
         * Approve a plugin
         * @param plugin
         */
        function approvePlugin(plugin){
            let message = 'Are you sure? Everyone will have access to the plugin.';
            let confirm = $mdDialog.confirm()
                .title('Approve Submission')
                .textContent(message)
                .ariaLabel(message)
                .ok('Cancel')
                .cancel('Approve');

            $mdDialog.show(confirm)
                .then(function(){}) // Ok
                .catch(
                    () => PublishService.approveItem(plugin.id)
                );
        }

        /**
         * Reject a plugin
         * @param plugin
         */
        function rejectPlugin(plugin){
            // SHOW THE REJECT SUBMISSION DIALOG
            $mdDialog.show({
                    templateUrl: SECTIONS.PREFIX + SECTIONS.ADMIN.REJECT_DLG + SECTIONS.POSTFIX,
                    parent: angular.element(document.body),
                    targetEvent: event,
                    fullscreen:true,
                    clickOutsideToClose:false,
                    controller:
                        function ($scope, $mdDialog) {
                            $scope.submission = plugin;
                            $scope.cancel = () => $mdDialog.cancel();
                            $scope.submit = () => $mdDialog.hide();
                        }
                })
                .then(
                    () => PublishService.rejectItem(plugin.id, plugin.reject_reason),  // SUBMIT
                    () => {} // CANCEL
                );
        }

        /**
         * Describe a plugin
         * @param plugin
         */
        function describePlugin( plugin ){
            let title = 'Plugin Description';
            let message = plugin.description;
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(title)
                    .ariaLabel(title)
                    .textContent(message)
                    .ok('Ok')
            );
        }

        /**
         * Show publish plugin dialog
         * @param plugin
         */
        function onPublishPlugin( plugin ) {

            // SHOW THE PUBLISH PLUGIN DIALOG
            $mdDialog.show({
                    templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.PUB_PLUG + SECTIONS.POSTFIX,
                    parent: angular.element(document.body),
                    targetEvent: event,
                    fullscreen:true,
                    clickOutsideToClose:false,
                    controller:
                        function ($scope, $mdDialog, $rootScope) {
                            $scope.plugin = $rootScope.plugin;
                            $scope.plugin.input.name = plugin.name;
                            $scope.cancel = () => $mdDialog.cancel();
                            $scope.submit = () => $mdDialog.hide();
                        }
                })
                .then(
                    () => instance.publishPlugin( plugin ),  // SUBMIT
                    () => $scope.plugin.resetFormInputs()    // CANCEL
                );

        }

        /**
         * Publish a plugin
         * @param plugin
         */
        function publishPlugin( plugin ){
            plugin.is_example = $rootScope.plugin.input.publish_example;
            PluginService.submitPlugin(plugin);
        }

        /**
         * Handle the CREATE_PLUGIN, UPDATE_PLUGIN, and DELETE_PLUGIN responses
         * @param event
         * @param data
         */
        function onPluginCRUDResponse(event, data) {
            $rootScope.plugin.resetFormInputs();
            switch (event.name)
            {
                case PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.SUCCESS:
                    $timeout( () => instance.selectPlugin(data) );
                    break;

                case PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.SUCCESS:
                    $timeout(function() {
                        let message = 'Plugin copied.';
                        ToastService.showToast(message);
                    });
                    break;

                case PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.SUCCESS:
                    $timeout(function() {
                        let message = 'Plugin updated.';
                        ToastService.showToast(message);
                    });
                    break;

                case PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.SUCCESS:
                    $timeout(function() {
                        let message = 'Plugin deleted!';
                        ToastService.showToast(message);
                    });
                    break;

                case PLUGIN_SERVICE_RESPONSES.SUBMIT_PLUGIN.SUCCESS:
                    $timeout(function() {
                        let message = 'Publish Request Submitted!';
                        ToastService.showToast(message);
                    });
                    break;

                case PLUGIN_SERVICE_RESPONSES.CANCEL_PLUGIN.SUCCESS:
                    $timeout(function() {
                        let message = 'Publish Request Canceled';
                        ToastService.showToast(message);
                    }, 500);
                    break;

                case PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.FAILURE:
                case PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.FAILURE:
                case PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.FAILURE:
                case PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.FAILURE:
                case PLUGIN_SERVICE_RESPONSES.SUBMIT_PLUGIN.FAILURE:
                case PLUGIN_SERVICE_RESPONSES.CANCEL_PLUGIN.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Return the list of secondary actions for the given plugin
         * @param plugin
         */
        function getSecondaryActions(plugin) {
            let actions = [];

            switch(plugin.status)
            {
                case Plugin.STATUS.DEVELOPMENT:
                    if (plugin.isValid() && plugin.description) actions.push(CHROME.FAB.ACTIONS.PUBLISH);
                    break;

                case Plugin.STATUS.SUBMITTED:
                case Plugin.STATUS.APPROVED:
                    actions.push(CHROME.FAB.ACTIONS.CANCEL);
                    break;
            }

            actions.push(CHROME.FAB.ACTIONS.COPY);
            if (plugin.status === Plugin.STATUS.DEVELOPMENT) actions.push(CHROME.FAB.ACTIONS.DELETE);

            return actions;
        }

        /**
         * Return the list of secondary actions for the given public plugin
         * @param plugin
         */
        function getPublicSecondaryActions(plugin) {
            let actions = [], likes = $rootScope.plugin.likes;

            if ( plugin.is_example ) actions.push(CHROME.FAB.ACTIONS.COPY);
            actions.push( ( likes[ plugin.id ] ) ? CHROME.FAB.ACTIONS.UNLIKE_PLUGIN : CHROME.FAB.ACTIONS.LIKE_PLUGIN );

            return actions;
        }

        /**
         * Return the list of secondary actions for the given submitted plugin
         * @param plugin
         */
        function getSubmittedSecondaryActions(plugin) {
            let actions = [];

            actions.push(CHROME.FAB.ACTIONS.COPY);
            actions.push(CHROME.FAB.ACTIONS.APPROVE);
            actions.push(CHROME.FAB.ACTIONS.REJECT);

            return actions;
        }

        /**
         * Invoke a secondary action from an icon in the plugin list
         * @param plugin
         */
        function invokeSecondaryAction(plugin,action) {
            switch (action){
                case CHROME.FAB.ACTIONS.PUBLISH.ACTION:
                    instance.onPublishPlugin(plugin);
                    break;

                case CHROME.FAB.ACTIONS.CANCEL.ACTION:
                    PluginService.cancelSubmission(plugin);
                    break;

                case CHROME.FAB.ACTIONS.DELETE.ACTION:
                    instance.deletePlugin(plugin);
                    break;

                case CHROME.FAB.ACTIONS.COPY.ACTION:
                    instance.copyPlugin(plugin);
                    break;

                case CHROME.FAB.ACTIONS.LIKE_PLUGIN.ACTION:
                    LikeService.likePlugin($rootScope.account.uid, plugin.id);
                    break;

                case CHROME.FAB.ACTIONS.UNLIKE_PLUGIN.ACTION:
                    LikeService.unlikePlugin($rootScope.account.uid, plugin.id);
                    break;

                case CHROME.FAB.ACTIONS.APPROVE.ACTION:
                    instance.approvePlugin(plugin);
                    break;

                case CHROME.FAB.ACTIONS.REJECT.ACTION:
                    instance.rejectPlugin(plugin);
                    break;

            }
            BroadcastService.send(action,plugin);
        }

        /**
         * While editing a plugin the user has invoked
         * an action from the FAB menu
         * @param event
         */
        function invokeFabAction(event) {
            let action = event.name;
            let plugin = $rootScope.plugin.selected;
            switch (action) {
                case CHROME.FAB.ACTIONS.SAVE.ACTION:
                    // Update the plugin
                    PluginService.updatePlugin(plugin);
                    break;

                case CHROME.FAB.ACTIONS.CLOSE.ACTION:
                    if ($rootScope.plugin.hasChanged(plugin)) {
                        let message = 'Do you want to abandon your changes?';
                        let confirm = $mdDialog.confirm()
                            .title('Unsaved Changes')
                            .textContent(message)
                            .ariaLabel(message)
                            .ok('Cancel')
                            .cancel('Abandon');

                        $mdDialog.show(confirm)
                            .then(() => {}) // Cancel
                            .catch(() => deselectPlugin()); // Abandon
                    } else {
                        deselectPlugin()
                    }
                    break;
            }

        }

        /**
         * Get the status icon for a plugin
         * @param plugin
         * @returns {string}
         */
        function getPluginStatusIcon(plugin) {
            let icon = CHROME.ICON.STATUS.NEEDS_WORK;
            if (plugin && plugin.isValid()){
                switch (plugin.status)
                {
                    case Plugin.STATUS.DEVELOPMENT:
                        icon = CHROME.ICON.STATUS.READY;
                        break;

                    case Plugin.STATUS.SUBMITTED:
                        icon = CHROME.ICON.STATUS.SUBMITTED;
                        break;

                    case Plugin.STATUS.REJECTED:
                        icon = CHROME.ICON.STATUS.REJECTED;
                        break;

                    case Plugin.STATUS.APPROVED:
                        icon = CHROME.ICON.STATUS.APPROVED;
                        break;
                }
            }
            return icon;
        }


        /**
         * Get the status message for a plugin
         * @param plugin
         * @returns {string}
         */
        function getPluginStatusMessage( plugin ) {
            let message = plugin.isValid()
                ? "Ready to be used or published."
                : "Not yet valid. Needs work.";
            return message;
        }

        /**
         * Get the status icon for a plugin
         * @param plugin
         * @returns {string}
         */
        function getSettingStatusIcon(setting) {
            let icon = (setting && setting.isValid()) ? CHROME.ICON.STATUS.READY : CHROME.ICON.STATUS.NEEDS_WORK;
            return icon;
        }

        /**
         * User selected the fn_setup field
         */
        function selectFnSetup(){
            $rootScope.plugin.showFnSetup = true;
            FocusService.focusOn(CHROME.FN_SETUP);
        }

        /**
         * User selected the fn_render field
         */
        function selectFnRender(){
            $rootScope.plugin.showFnRender = true;
            FocusService.focusOn(CHROME.FN_RENDER);
        }

        /**
         * User selected the fn_destroy field
         */
        function selectFnDestroy(){
            $rootScope.plugin.showFnDestroy = true;
            FocusService.focusOn(CHROME.FN_DESTROY);
        }

        /**
         * Get the status icon for a the given plugin's setup function
         * @param plugin
         * @returns {string}
         */
        function getFnSetupIcon(plugin) {
            let icon = CHROME.ICON.STATUS.NEEDS_WORK;
            if ( plugin && plugin.fnSetupIsValid() ) icon = CHROME.ICON.STATUS.READY;
            return icon;
        }

        /**
         * Get the status icon for a the given plugin's render function
         * @param plugin
         * @returns {string}
         */
        function getFnRenderIcon(plugin) {
            let icon = CHROME.ICON.STATUS.NEEDS_WORK;
            if ( plugin && plugin.fnRenderIsValid() ) icon = CHROME.ICON.STATUS.READY;
            return icon;
        }

        /**
         * Get the status icon for a the given plugin's destroy function
         * @param plugin
         * @returns {string}
         */
        function getFnDestroyIcon(plugin) {
            let icon = CHROME.ICON.STATUS.NEEDS_WORK;
            if ( plugin && plugin.fnDestroyIsValid() ) icon = CHROME.ICON.STATUS.READY;
            return icon;
        }

        /**
         * Get the status icon color for a the given plugin's setup function
         * @param plugin
         * @returns {string}
         */
        function getFnSetupColor(plugin) {
            let color = ( plugin && plugin.fnSetupIsValid() ) ? 'icon-ready' : 'icon-needs-work';
            return color;
        }

        /**
         * Get the status icon color for a the given plugin's render function
         * @param plugin
         * @returns {string}
         */
        function getFnRenderColor(plugin) {
            let color = ( plugin && plugin.fnRenderIsValid() ) ? 'icon-ready' : 'icon-needs-work';
            return color;
        }

        /**
         * Get the status icon color for a the given plugin's destroy function
         * @param plugin
         * @returns {string}
         */
        function getFnDestroyColor(plugin) {
            let color = ( plugin && plugin.fnDestroyIsValid() ) ? 'icon-ready' : 'icon-needs-work';
            return color;
        }

        /**
         * Get the status icon color for a the given setting
         * @param setting
         * @returns {string}
         */
        function getSettingStatusColor(setting) {
            let color = ( setting && setting.isValid() ) ? 'icon-ready' : 'icon-needs-work';
            return color;
        }

        // Comparator for sorting user's plugins by status and plugin name
        function sortMyPlugins(v1, v2) {

            const statuses = [
                Plugin.STATUS.DEVELOPMENT,
                Plugin.STATUS.SUBMITTED,
                Plugin.STATUS.APPROVED,
                Plugin.STATUS.REJECTED
            ];

            let v1Weight = statuses.indexOf( v1.value ),
                v2Weight = statuses.indexOf( v2.value ),
                v1Name = $rootScope.plugin.list[ v1.index].name,
                v2Name = $rootScope.plugin.list[ v2.index].name,
                retval = 0;

            if ( v1Weight  <  v2Weight ) retval = -1;
            if ( v1Weight  >  v2Weight ) retval = 1;
            if ( v1Weight === v2Weight ) {
                if ( v1Name  <  v2Name ) retval = -1;
                if ( v1Name  >  v2Name ) retval = 1;
                if ( v1Name === v2Name ) retval = 0;
            }

            return retval;

        }

        // Comparator for sorting product plugins by likes, author name, and plugin name
        function sortProductPlugins(v1, v2) {

            return instance.sortPublicPlugins(v1, v2, $rootScope.plugin.product_list);

        }

        // Comparator for sorting public plugins by likes, author name, and plugin name
        function sortExamplePlugins(v1, v2) {

            return instance.sortPublicPlugins(v1, v2, $rootScope.plugin.example_list);

        }

        // Comparator for sorting submitted plugins by author name and plugin name
        function sortSubmittedPlugins(v1, v2) {

            return instance.sortPublicPlugins(v1, v2, $rootScope.plugin.submissions_list);

        }

        /**
         * Sort the given items from the given public list
         * @param v1
         * @param v2
         * @param list
         * @returns {number}
         */
        function sortPublicPlugins(v1, v2, list) {

            let v1Likes = v1.value,
                v2Likes = v2.value,
                v1Author = list[ v1.index ].author.name.display,
                v2Author = list[ v2.index ].author.name.display,
                v1Name = list[ v1.index ].name,
                v2Name = list[ v2.index ].name,
                retval = 0;

            if ( v1Likes  <  v2Likes ) retval = 1;
            if ( v1Likes  >  v2Likes ) retval = -1;
            if ( v1Likes === v2Likes ) {
                if (v1Author  <  v2Author) retval = -1;
                if (v1Author  >  v2Author) retval = 1;
                if (v1Author === v2Author) {
                    if (v1Name  <  v2Name) retval = -1;
                    if (v1Name  >  v2Name) retval = 1;
                    if (v1Name === v2Name) retval = 0;
                }
            }
            return retval;

        }

    }
})(); // IIFE keeps global scope clean
