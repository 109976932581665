/**
 * Authentication Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the AuthService to the module
    angular.module('Sinewav3.Client.Shell')

        // AUTH SERVICE RESPONSES
        .constant('AUTH_SERVICE_RESPONSES', {
            CREATE_USER: {
                SUCCESS: 'auth-create-user-success',
                FAILURE: 'auth-create-user-error'
            },
            VERIFY_EMAIL:{
                SUCCESS: 'auth-verify-email-success',
                FAILURE: 'auth.verify-email-failure'
            },
            SIGN_IN: {
                SUCCESS: 'auth-sign-in-success',
                FAILURE: 'auth-sign-in-error'
            },
            SIGN_OUT: {
                SUCCESS: 'auth-sign-out-success',
                FAILURE: 'auth-sign-out-error'
            },
            DELETE_USER: {
                SUCCESS: 'auth-delete-user-success',
                FAILURE: 'auth-delete-user-error'
            },
            CHANGE_PW: {
                SUCCESS: 'auth-change-password-success',
                FAILURE: 'auth-change-password-error'
            },
            RESET_PW: {
                SUCCESS: 'auth-send-password-reset-success',
                FAILURE: 'auth-send-password-reset-error'
            }
        })

        .factory(
            'AuthService',
            [
                'BroadcastService',
                'AUTH_PROVIDERS',
                'AUTH_SERVICE_RESPONSES',
                AuthServiceFactory
            ]
        );

    // Factory Method
    function AuthServiceFactory(BroadcastService,
                                AUTH_PROVIDERS,
                                AUTH_SERVICE_RESPONSES)
    {
        let instance = {};

        instance.createUser = createUser;
        instance.sendVerificationEmail = sendVerificationEmail;
        instance.signInWithPassword = signInWithPassword;
        instance.signInWithSocial = signInWithSocial;
        instance.sendPasswordResetEmail = sendPasswordResetEmail;
        instance.changePassword = changePassword;
        instance.signOut = signOut;

        return instance;

        /**
         * Create a user account
         * @param db
         * @param email
         * @param password
         */
        function createUser(email, password)
        {
            firebase.auth()
                .createUserWithEmailAndPassword(email, password)
                .then(success)
                .catch(failure);

            function success(user){
                instance.sendVerificationEmail(user);
                BroadcastService.send(AUTH_SERVICE_RESPONSES.CREATE_USER.SUCCESS, user);
            }

            function failure(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.CREATE_USER.FAILURE, error);
            }
        }

        /**
         * Send a verification email to new users
         * @param user
         */
        function sendVerificationEmail(user)
        {
            user.sendEmailVerification()
                .then(success)
                .catch(failure);

            function success(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.VERIFY_EMAIL.SUCCESS, user);
            }

            function failure(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.VERIFY_EMAIL.FAILURE, error);
            }
        }

        /**
         * Sign the user in with email and password
         * @param db
         * @param email
         * @param password
         */
        function signInWithPassword(email, password)
        {
            firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .catch(failure);

            function failure(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_IN.FAILURE, error);
            }
        }

        /**
         * Try to use a popup for sign in first
         * @param provider
         */
        function signInWithSocial(who)
        {
            let provider = null;
            switch (who){
                case AUTH_PROVIDERS.GOOGLE:
                    provider = new firebase.auth.GoogleAuthProvider();
                    break;

                case AUTH_PROVIDERS.TWITTER:
                    provider = new firebase.auth.TwitterAuthProvider();
                    break;

                case AUTH_PROVIDERS.GITHUB:
                    provider = new firebase.auth.GithubAuthProvider();
                    break;

                case AUTH_PROVIDERS.FACEBOOK:
                    provider = new firebase.auth.FacebookAuthProvider();
                    break;

            }

            // Try first with popup
            if (provider) firebase.auth().signInWithPopup( provider ).catch( popupFailed );

            // Popup failed, try redirect (if popups aren't available) or report error
            function popupFailed(error) {
                // TODO: handle errors properly...
                // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signInWithPopup
                if (error && error.code === "auth/popup-blocked") {
                    // Try with redirect
                    firebase.auth().signInWithRedirect( provider ).catch( redirectFailed );
                } else {
                    BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_IN.FAILURE, error);
                }

            }

            // Redirect failed
            function redirectFailed(error){
                BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_IN.FAILURE, error);
            }
        }

        /**
         * Change the user's password
         * @param newPassword
         */
        function sendPasswordResetEmail(email)
        {
            // Get the user and set the password
            firebase.auth()
                .sendPasswordResetEmail(email)
                .then(success)
                .catch(failure);

            function success() {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.RESET_PW.SUCCESS);
            }

            function failure(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.RESET_PW.FAILURE, error);
            }
        }

        /**
         * Change the user's password
         * @param newPassword
         */
        function changePassword(newPassword)
        {
            // Get the user and set the password
            firebase.auth()
                .currentUser
                .updatePassword(newPassword)
                .then(success)
                .catch(failure);

            function success() {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.CHANGE_PW.SUCCESS);
            }

            function failure(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.CHANGE_PW.FAILURE, error);
            }
        }

        /**
         * Sign the user out
         */
        function signOut()
        {
            firebase.auth()
                .signOut()
                .catch(signOutFailed);

            // Sign out failed (super unlikely)
            function signOutFailed(error) {
                BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_OUT.FAILURE, error);
            }
        }

    }
})(); // IIFE keeps global scope clean