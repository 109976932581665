(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'settingsSideNavMenu',
            [
                'CHROME',
                SettingsSideNavMenuFactory
            ]
        );

    // Factory Method
    function SettingsSideNavMenuFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            templateUrl: CHROME.PREFIX + CHROME.PROJECT.SIDE_NAV.MENU + CHROME.POSTFIX
        };

    }
})();



