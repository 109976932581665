// Shell - Focus Service
(function() {

    // Add the service to the module
    angular.module('Sinewav3.Client.Shell')
        .factory(
            'FocusService',
            [
                '$timeout',
                '$window',
                FocusServiceFactory
            ]
        );

    // Factory Method
    function FocusServiceFactory($timeout, $window)
    {
        let service = {};
        service.focusOn = focusOn;
        return service;

        function focusOn(id) {

            $timeout(function() {
                let element = $window.document.getElementById(id);
                if (element) element.focus();
            });

        }
    }

})();
