/**
 * Account Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the AccountService to the module
    angular.module('Sinewav3.Client.Shell')

        // ACCOUNT SERVICE RESPONSES
        .constant('ACCOUNT_SERVICE_RESPONSES', {
            CREATE_PROFILE: {
                SUCCESS: 'account-create-profile-success',
                FAILURE: 'account-create-profile-error'
            },
            UPDATE_PROFILE: {
                SUCCESS: 'account-update-profile-success',
                FAILURE: 'account-update-profile-error'
            },
            LOAD_PROFILE: {
                SUCCESS: 'account-load-profile-success',
                FAILURE: 'account-load-profile-error',
                NO_PROFILE: 'account-load-profile-none'
            }
        })

        .factory(
            'AccountService',
            [
                'BroadcastService',
                'DatabaseService',
                'ACCOUNT_SERVICE_RESPONSES',
                AccountServiceFactory
            ]
        );

    // Factory Method
    function AccountServiceFactory(
        BroadcastService,
        DatabaseService,
        ACCOUNT_SERVICE_RESPONSES
    ){
        let instance = {};

        instance.createProfile = createProfile;
        instance.updateProfile = updateProfile;
        instance.loadProfile = loadProfile;

        return instance;

        /**
         * Create a Sinewav3 account profile from the given Firebase UserInfo instance
         * @param user
         */
        function createProfile(user) {

            // PREPARE
            let display = user.displayName ? user.displayName : user.email.split('@')[0];
            let photoURL = user.photoURL;
            let profile = new Profile( user.uid, user.email, display, '', '', photoURL );

            // CREATE
            DatabaseService.getProfilesRef().child(user.uid).set(profile.toObject(), onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.FAILURE, error );
                } else {
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.SUCCESS, profile );
                }
            }
        }

        /**
         * Save changes to user profile
         * @param profile
         */
        function updateProfile( profile ) {

            // UPDATE USER PROFILE
            let entity = DatabaseService.stripAngularProps( profile ).toObject();
            DatabaseService.getProfilesRef().child( profile.uid ).set( entity, onComplete );

            // ANNOUNCE
            function onComplete( error ){
                if ( error ){
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.FAILURE, error );
                } else {
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.SUCCESS, profile );
                }
            }
        }

        /**
         * Load an account profile
         * @param user
         */
        function loadProfile( uid ) {

            // LOAD
            DatabaseService.getProfilesRef().child(uid).once('value').then(success).catch(failure);

            // ANNOUNCE
            function failure(error) {
                BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.FAILURE, error );
            }

            function success(snapshot) {
                let entity = snapshot.val();
                if (entity) {
                    let profile = Profile.fromObject(entity);
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.SUCCESS, profile );
                } else {
                    BroadcastService.send( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.NO_PROFILE );
                }
            }
        }
    }
})(); // IIFE keeps global scope clean