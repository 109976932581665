// Shell - Plugin Scope
(function (){

    angular.module('Sinewav3.Client.Shell')

        .run([
            '$rootScope','$timeout',
            ($rootScope, $timeout) => $rootScope.plugin = new PluginScopeModel( $timeout )
        ]);

    /**
     * Plugin Scope Model
     * @constructor
     */
    function PluginScopeModel( $timeout ) {

        // Injected
        this.$timeout = $timeout;

        // Initialize model, state, and form inputs
        this.reset();

    }

    PluginScopeModel.prototype.reset = function () {

        // Data model
        this.resetModel();

        // State
        this.resetState();

        // Build form input models
        this.resetFormInputs();

    };

    PluginScopeModel.prototype.resetModel = function () {
        this.likes = {};
        this.list = [];
        this.product_list = [];
        this.example_list = [];
        this.submissions_list = [];
        this.selected = null;
    };

    PluginScopeModel.prototype.resetState = function () {
        this.editing = false;
        this.showSettingsList = false;
        this.showFnSetup = true;
        this.showFnRender = true;
        this.showFnDestroy = true;
    };

    PluginScopeModel.prototype.resetFormInputs = function () {
        this.input = {};
        this.input.name = null;
        this.input.publish_example = false;
    };

    /**
     * Select the plugin for editing
     * @param plugin
     */
    PluginScopeModel.prototype.selectPlugin = function( plugin ) {
        let clone = Plugin.fromObject(plugin.toObject());
        this.showSettingsList = plugin.settings && plugin.settings.length;
        this.$timeout( () => {
            this.selected = clone;
            this.editing = true;
            }, 350 );
    };

    /**
     * Deselect the plugin and cease editing
     */
    PluginScopeModel.prototype.deselectPlugin = function(){
        this.editing = false;
        this.selected = null;
        this.resetFormInputs();
    };

    /**
     * Has the selected Plugin clone changed from the original in the list?
     * @param plugin
     * @returns {boolean}
     */
    PluginScopeModel.prototype.hasChanged = function( plugin ) {
        let retval = false;
        let list = this.list.concat(this.product_list);
        let pos = list.map( x => x.id ).indexOf(plugin.id);
        if ( pos > -1 ) { // Found in master list
            let original = list[pos];
            let a = angular.toJson(original.toObject());
            let b = angular.toJson(plugin.toObject());
            retval = !(a === b);
        }
        return retval;
    };

    /**
     * Show the plugin settings edit cards
     */
    PluginScopeModel.prototype.editSettings = function( ) {
        this.showSettingsList = false;
    };

    /**
     * Show the plugin settings list
     */
    PluginScopeModel.prototype.listSettings = function( ) {
        this.showSettingsList = true;
    };

    /**
     * Add a new setting group to the given plugin
     * @param plugin
     */
    PluginScopeModel.prototype.createSettingGroup = function( plugin ) {
        let group = new SettingGroup('New Group');
        plugin.addSettingGroup( group );
    };

    /**
     * Delete a setting group from the given plugin
     * @param plugin
     */
    PluginScopeModel.prototype.deleteSettingGroup = function ( plugin, group ) {
        let index = plugin.settings.indexOf(group);
        if (index != -1) plugin.settings.splice(index,1);
    };

    /**
     * Add a new setting to the given SettingGroup
     * @param plugin
     */
    PluginScopeModel.prototype.createSetting = function ( group ) {
        let setting = new Setting('New Setting');
        group.addSetting( setting );
    };

    /**
     * Delete a setting to the given SettingGroup
     * @param plugin
     */
    PluginScopeModel.prototype.deleteSetting = function ( group, setting ) {
        let index = group.settings.indexOf(setting);
        if (index != -1) group.settings.splice(index,1);
    };

    /**
     * Get the setting types in an array for a menu
     * @returns {Array}
     */
    PluginScopeModel.prototype.getSettingTypes = function ( ) {
        return Object.values( Setting.TYPE );
    };

    /**
     * Is the given setting of type Setting.TYPE.ASSET?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isAsset = function ( setting ) {
        return setting.type == Setting.TYPE.ASSET;
    };

    /**
     * Is the given setting of type Setting.TYPE.BOOLEAN?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isBoolean = function ( setting ) {
        return setting.type == Setting.TYPE.BOOLEAN;
    };

    /**
     * Is the given setting of type Setting.TYPE.COLOR?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isColor = function ( setting ) {
        return setting.type == Setting.TYPE.COLOR;
    };

    /**
     * Is the given setting of type Setting.TYPE.EASE?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isEase = function ( setting ) {
        return setting.type == Setting.TYPE.EASE;
    };

    /**
     * Is the given setting of type Setting.TYPE.ENUM?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isEnum = function ( setting ) {
        return setting.type == Setting.TYPE.ENUM;
    };

    /**
     * Is the given setting of type Setting.TYPE.FLOAT?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isFloat = function ( setting ) {
        return setting.type == Setting.TYPE.FLOAT;
    };

    /**
     * Is the given setting of type Setting.TYPE.INTEGER?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isInteger = function ( setting ) {
        return setting.type == Setting.TYPE.INTEGER;
    };

    /**
     * Is the given setting of type Setting.TYPE.LOOP?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isLoop = function ( setting ) {
        return setting.type === Setting.TYPE.LOOP;
    };

    /**
     * Is the given setting of type Setting.TYPE.MODULATOR?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isModulator = function ( setting ) {
        return setting.type == Setting.TYPE.MODULATOR;
    };

    /**
     * Is the given setting of type Setting.TYPE.STRING?
     * @returns {Boolean}
     */
    PluginScopeModel.prototype.isString = function ( setting ) {
        return setting.type == Setting.TYPE.STRING;
    };

    /**
     * User changed the setting type, so wipe out the conditional fields
     * @param setting
     */
    PluginScopeModel.prototype.settingTypeChanged = function ( setting ) {
        setting.sane = null;
        setting.value = null;
        setting.min = null;
        setting.max = null;
        setting.step = null;
        setting.asset_type = null;

        switch (setting.type)
        {
            case Setting.TYPE.BOOLEAN:
                setting.sane = true;
                break;

            case Setting.TYPE.FLOAT:
                setting.sane = .5;
                setting.min = 0;
                setting.max = 1;
                setting.step = .1;
                break;

            case Setting.TYPE.INTEGER:
                setting.sane = 50;
                setting.min = 0;
                setting.max = 100;
                setting.step = 10;
                break;

            case Setting.TYPE.COLOR:
                setting.sane = "00FF00";
                break;

            case Setting.TYPE.ENUM:
                // TODO: Need Setting.enum_list property and sane must equal a string in the enum_list
                break;

            case Setting.TYPE.STRING:
                setting.sane = "";
                break;

            case Setting.TYPE.MODULATOR:
                setting.sane = Setting.MODULATORS.NONE;
                break;
        }
    };

    /**
     * Is this SettingGroup the first in the Plugin's settings array?
     * @param plugin
     * @param group
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.groupIsFirst = function( plugin, group ) {
        return plugin.settings && plugin.settings.indexOf( group ) == 0;
    };

    /**
     * Is this SettingGroup the last in the Plugin's settings array?
     * @param plugin
     * @param group
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.groupIsLast = function( plugin, group ) {
        return plugin.settings && plugin.settings.indexOf( group ) == plugin.settings.length - 1;

    };

    /**
     * Is this the first SettingGroup in the Plugin's settings array?
     * @param plugin
     * @param group
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.groupIsOnly = function( plugin, group ) {
        return this.groupIsFirst( plugin, group ) && plugin.settings.length == 1;
    };


    /**
     * Is this Setting the first in the SettingGroup's settings array?
     * @param group
     * @param setting
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.settingIsFirst = function( group, setting ) {
        return group.settings && group.settings.indexOf( setting ) == 0;
    };

    /**
     * Is this Setting the last in the SettingGroup's settings array?
     * @param group
     * @param setting
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.settingIsLast = function( group, setting ) {
        return group.settings && group.settings.indexOf( setting ) == group.settings.length - 1;
    };

    /**
     * Is this the only Setting in the SettingGroup's settings array?
     * @param group
     * @param setting
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.settingIsOnly = function( group, setting ) {
        return this.settingIsFirst( group, setting ) && group.settings.length == 1;
    };

    /**
     * Move this SettingGroup up in the Plugin's settings array
     * @param plugin
     * @param group
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.moveGroupUp = function( plugin, group ) {

        if (plugin.settings) {
            let index = plugin.settings.indexOf(group);
            if (index > 0) {
                plugin.settings.splice(index, 1);
                plugin.settings.splice(index - 1, 0, group);
            }
        }
    };

    /**
     * Move this SettingGroup down in the Plugin's settings array
     * @param plugin
     * @param group
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.moveGroupDown = function( plugin, group ) {

        if (plugin.settings) {
            let index = plugin.settings.indexOf(group);
            if (index < plugin.settings.length - 1) {
                plugin.settings.splice(index, 1);
                plugin.settings.splice(index + 1, 0, group);
            }
        }
    };

    /**
     * Move this Setting up in the SettingGroup's settings array
     * @param group
     * @param setting
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.moveSettingUp = function( group, setting ) {

        if (group.settings) {
            let index = group.settings.indexOf(setting);
            if (index > 0) {
                group.settings.splice(index, 1);
                group.settings.splice(index - 1, 0, setting);
            }
        }
    };

    /**
     * Move this Setting down in the SettingGroup's settings array
     * @param group
     * @param setting
     * @returns {*|Array|boolean}
     */
    PluginScopeModel.prototype.moveSettingDown = function( group, setting ) {

        if (group.settings) {
            let index = group.settings.indexOf(setting);
            if (index < group.settings.length - 1) {
                group.settings.splice(index, 1);
                group.settings.splice(index + 1, 0, setting);
            }
        }
    };

    /**
     * Get this Setting's enum_list as a comma-separated string
     * @param setting
     * @returns {string}
     */
    PluginScopeModel.prototype.getEnumListAsString = function( setting ) {

        return (setting.enum_list)
            ? setting.enum_list.join(', ')
            : "";
    };

    /**
     * Get the list of plugins that are valid and not rejected
     * @returns {*}
     */
    PluginScopeModel.prototype.getUsablePlugins = function() {
        // Get all the plugins into a single list
        let uniq = {},
            list = [],
            all = this.list.filter( plugin => plugin.isValid() && plugin.status !== Plugin.STATUS.REJECTED).concat(this.product_list);

        // Uniquify, since the user may have a published plugin appearing in both lists
        all.forEach( plugin => { if (!uniq[plugin.id]) { uniq[plugin.id] = plugin.id; list.push(plugin); } } );

        return list;
    }

    /**
     * Get this user's published plugins
     * @returns {Array.<T>}
     */
    PluginScopeModel.prototype.getMyPublishedPlugins = function( ) {

        return this.list.filter( plugin => plugin.status === Plugin.STATUS.APPROVED );

    };

})();
