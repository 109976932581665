(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'settingEditor',
            [
                'CHROME',
                SettingEditFactory
            ]
        );

    // Factory Method
    function SettingEditFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            templateUrl: CHROME.PREFIX + CHROME.PROJECT.TOOLBAR.SETTING_EDITOR + CHROME.POSTFIX
        };

    }
})();



