// Shell - Project Configuration
(function (){

    angular.module('Sinewav3.Client.Shell')

        .run([
            '$rootScope', '$timeout',
            ($rootScope, $timeout) => $rootScope.project = new ProjectScopeModel($timeout)
        ]);

    /**
     * Project Scope Model
     * @constructor
     */
    function ProjectScopeModel($timeout) {

        this.$timeout = $timeout;

        // Initialize model, state, and form inputs
        this.reset();
    }


    ProjectScopeModel.prototype.reset = function () {

        // Data model
        this.resetModel();

        // State
        this.resetState();

    };

    ProjectScopeModel.prototype.resetModel = function () {
        this.list = null;
        this.selected = null;
    };

    ProjectScopeModel.prototype.resetState = function () {
        this.rendering = false;
        this.visualizing = false;
        this.analyzing = false;
        this.editing = false;
        this.show_world_list = false;
        this.show_drill_down = false;
        this.alias_exists = false;
        this.alias_ok = false;
        this.resetFormInputs();

    };

    ProjectScopeModel.prototype.resetFormInputs = function () {
        this.input = {};
        this.input.name = null;
        this.input.audio = null;
        this.input.world = null;
        this.input.plugin = null;
        this.input.setting_group = null;
        this.input.setting = null;
        this.input.plugins = {};
        this.input.cancelRender = false;
        this.input.alias = null;
        this.input.share = null;
        this.input.share_type = "URL";
        this.show_drill_down = false;
    };

    /**
     * Set the project list
     * @param list
     */
    ProjectScopeModel.prototype.setProjectList = function( list ) {
        this.list = list;

        if (this.selected && !this.visualizing) {
            let replacement;
            this.list.forEach( project => { if ( project.id == this.selected.id ) replacement = project; } );
            if (replacement) {
                this.selectProject(replacement);
            } else {
                this.deselectProject();
            }

            // Update the selected world if one is selected, or choose the first one if present
            if (this.show_world_list && this.selected) {
                if (this.input.world) {
                    this.selected.worlds.forEach(world => {
                        if (world.id === this.input.world.id) this.worldChanged(world);
                    });
                } else {
                    this.worldChanged(this.selected.worlds[0]);
                }
            }
        }
    };

    /**
     * Prepare to share a project
     * @param alias
     */
    ProjectScopeModel.prototype.setAlias = function(alias) {
        this.input.alias = alias;
    };

    /**
     * Prepare to share a project
     * @param project
     * @param share
     */
    ProjectScopeModel.prototype.prepareToShare = function( project, share ) {
        this.alias_exists = Boolean(share);
        this.alias_ok = !share || (share && share.project_id === project.id);
        this.input.share = this.alias_ok ? share ? share : new ProjectShare(project.id, this.input.alias) : null;
    };

    /**
     * Is the sharing alias under consideration already taken by someone else?
     * @returns {boolean|*}
     */
    ProjectScopeModel.prototype.aliasIsTaken = function(){
        return this.alias_exists && !this.alias_ok;
    };

    /**
     * Clone the given project and select it for editing
     * @param project
     */
    ProjectScopeModel.prototype.selectProject = function( project ) {
        let clone = Project.fromObject(project.toObject());
        this.selected = clone;
        this.show_world_list = project.worlds && project.worlds.length;
        this.editProject();
    };

    /**
     * Set the shell state for editing the selected project
     */
    ProjectScopeModel.prototype.editProject = function( ) {
        this.visualizing = false;
        this.analyzing = false;
        this.rendering = false;
        this.editing = true;
        this.show_drill_down = false;
    };

    /**
     * Set the shell state for analyzing the selected project's audio
     */
    ProjectScopeModel.prototype.analyzeAudio = function() {
        this.visualizing = false;
        this.analyzing = true;
        this.rendering = false;
        this.editing = false;
        this.show_drill_down = false;
    };


    /**
     * Set the shell state for visualizing the selected project
     * Returned function is a closure that gets the index of the currently selected world
     * @returns {Function}
     */
    ProjectScopeModel.prototype.visualizeProject = function() {
        this.visualizing = true;
        this.analyzing = false;
        this.rendering = false;
        this.editing = false;
        this.show_drill_down = false;
        if ( this.selected.worlds.indexOf( this.input.world ) < 0 ) this.worldChanged( this.selected.worlds[0] );
        return () => ( this.input.world )
            ? this.selected.worlds.indexOf( this.input.world )
            : 0;
    };

    /**
     * Set the shell state for rendering the selected project
     */
    ProjectScopeModel.prototype.renderProject = function() {
        this.visualizing = false;
        this.analyzing = false;
        this.rendering = true;
        this.editing = false;
        this.show_drill_down = false;
    };

    /**
     * Deselect the currently selected project
     */
    ProjectScopeModel.prototype.deselectProject = function(){
        this.resetState();
    };

    /**
     * Has the given project changed from its original in the list?
     * @param project
     * @returns {boolean}
     */
    ProjectScopeModel.prototype.hasChanged = function( project ) {
        // Find the original
        let pos = this.list.map( x => x.id ).indexOf(project.id);
        let original = this.list[pos].toObject();

        // Remove transient world.selected_plugin properties from edited project
        let proj = project.toObject();
        proj.worlds.forEach( world => delete world.selected_plugins );

        // Compare JSON
        let a = angular.toJson(original);
        let b = angular.toJson(proj);
        return !(a === b);
    };

    /**
     * Show the world list and hide the cards
     */
    ProjectScopeModel.prototype.listWorlds = function() {
        this.show_world_list = true;
    };

    /**
     * Show the world editing cards and hide the list
     */
    ProjectScopeModel.prototype.editWorlds = function() {
        this.show_world_list = false;
    };

    /**
     * Add a new world to the given project
     * @param project
     */
    ProjectScopeModel.prototype.createWorld = function( project ) {
        let world = new World('New World');
        project.addWorld( world );
    };

    /**
     * Delete a plugin from the given world
     * @param project
     * @param world
     */
    ProjectScopeModel.prototype.deleteWorld = function ( project, world ) {
        let index = project.worlds.indexOf( world );
        if (index != -1) project.worlds.splice(index,1);
    };

    /**
     * Delete a plugin from the given world
     * @param world
     * @param plugin
     */
    ProjectScopeModel.prototype.deletePlugin = function ( world, plugin ) {
        let index = world.plugins.indexOf(plugin);
        if (index != -1) world.plugins.splice(index,1);
    };

    /**
     * Is this World the first in the Project's worlds array?
     * @param project
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.worldIsFirst = function( project, world ) {
        return project.worlds && project.worlds.indexOf( world ) == 0;
    };

    /**
     * Is this World the last in the Project's worlds array?
     * @param project
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.worldIsLast = function( project, world ) {
        return project.worlds && project.worlds.indexOf( world ) == project.worlds.length - 1;

    };

    /**
     * Is this the only World in the Project's worlds array?
     * @param project
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.worldIsOnly = function( project, world ) {
        return this.worldIsFirst( project, world ) && project.worlds.length == 1;
    };

    /**
     * Move this World up in the Project's worlds array
     * @param project
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.moveWorldUp = function( project, world ) {

        if (project.worlds) {
            let index = project.worlds.indexOf(world);
            if (index > 0) {
                project.worlds.splice(index, 1);
                project.worlds.splice(index - 1, 0, world);
            }
        }
    };

    /**
     * Move this World down in the Project's worlds array
     * @param project
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.moveWorldDown = function( project, world ) {

        if (project.worlds) {
            let index = project.worlds.indexOf(world);
            if (index < project.worlds.length - 1) {
                project.worlds.splice(index, 1);
                project.worlds.splice(index + 1, 0, world);
            }
        }
    };

    /**
     * Is this Plugin the first in the World's plugins array?
     * @param world
     * @param plugin
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.pluginIsFirst = function( world, plugin ) {
        return world.plugins && world.plugins.indexOf( plugin ) == 0;
    };

    /**
     * Is this Plugin the last in the World's plugins array?
     * @param world
     * @param plugin
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.pluginIsLast = function( world, plugin ) {
        return world.plugins && world.plugins.indexOf( plugin ) == world.plugins.length - 1;
    };

    /**
     * Is this the only Plugin the World's plugins array?
     * @param world
     * @param plugin
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.pluginIsOnly = function( world, plugin ) {
        return this.pluginIsFirst( world, plugin ) && world.plugins.length == 1;
    };

    /**
     * Move this Plugin up in the World's plugins array
     * @param world
     * @param plugin
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.movePluginUp = function( world, plugin ) {

        if (world.plugins) {
            let index = world.plugins.indexOf(plugin);
            if (index > 0) {
                world.plugins.splice(index, 1);
                world.plugins.splice(index - 1, 0, plugin);
            }
        }
    };

    /**
     * Move this Plugin down in the World's plugins array
     * @param world
     * @param plugin
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.movePluginDown = function( world, plugin ) {

        if (world.plugins) {
            let index = world.plugins.indexOf(plugin);
            if (index < world.plugins.length - 1) {
                world.plugins.splice(index, 1);
                world.plugins.splice(index + 1, 0, plugin);
            }
        }
    };

    /**
     * Replace a World's plugins with clones of the selected plugins if any
     * @param world
     * @returns {*|Array|boolean}
     */
    ProjectScopeModel.prototype.updateWorldPlugins = function( world ) {

        if ( world && world.selected_plugins ) {
            let selected = world.selected_plugins.map( plugin => Plugin.fromObject(plugin.toObject()) );
            selected.forEach( ( plugin, index ) => {
                if (world.plugins) world.plugins.forEach( existing => {
                    if ( existing.id === plugin.id ) selected[ index ] = existing;
                });
            });
            world.plugins = selected;
        }
        this.worldChanged( null );
    };

    /**
     * The World selection changed
     * @param world
     */
    ProjectScopeModel.prototype.worldChanged = function( world ){
        this.input.world = world;
        if ( world && world.plugins && world.plugins.length === 1 ) {
            this.pluginChanged( world.plugins[0] );
        } else {
            this.pluginChanged( null );
        }
    };

    /**
     * The Plugin selection changed
     * @param plugin
     */
    ProjectScopeModel.prototype.pluginChanged = function( plugin ){
        this.input.plugin = plugin;
        if ( plugin && plugin.settings && plugin.settings.length === 1 ) {
            this.groupChanged( plugin.settings[0] );
        } else {
            this.groupChanged( null );
        }
    };

    /**
     * The SettingGroup selection changed
     * @param group
     */
    ProjectScopeModel.prototype.groupChanged = function( group ){
        this.input.setting_group = group;
        if ( group && group.settings && group.settings.length === 1 ) {
            this.settingChanged( group.settings[0] );
        } else {
            this.settingChanged( null );
        }
    };

    /**
     * The Setting selection changed
     * @param setting
     */
    ProjectScopeModel.prototype.settingChanged = function( setting ){
            this.input.setting = setting;
    };

    /**
     * Are there any settings in this project for the user to edit?
     */
    ProjectScopeModel.prototype.allowSettingEditing = function( ){
        let found_setting = false;

        if (this.selected && this.selected.worlds && this.selected.worlds.length) {
            this.selected.worlds.forEach(
                world => {
                    if (world.plugins && world.plugins.length) {
                        world.plugins.forEach(
                            plugin => {
                                if (plugin.settings && plugin.settings.length) {
                                    plugin.settings.forEach(
                                        group => {
                                            if (group.settings && group.settings.length)  found_setting = true;
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            )
        }

        return found_setting;

    };

    /**
     * Are there any settings in this world's plugins?
     */
    ProjectScopeModel.prototype.worldIsConfigurable = function( world ) {
        let isConfigurable = false;
        if (world && world.plugins && world.plugins.length) {
            world.plugins.forEach(
                plugin => {
                    if (plugin.settings && plugin.settings.length) {
                        plugin.settings.forEach(
                            group => {
                                if (group.settings && group.settings.length) isConfigurable = true;
                            }
                        )
                    }
                }
            )
        }
        return isConfigurable;
    };

    /**
     * Show the project drilldown menu, selecting the first world if
     * 1) there isn't already a selected world
     * 2) it is the only world in the selected project
     */
    ProjectScopeModel.prototype.showProjectDrillDown = function( ){
        if ( this.allowSettingEditing() ) {
            if (!this.input.world){
                this.worldChanged( this.selected.worlds[0] );
            }
            this.show_drill_down = true;
        }
    };

    /**
     * Hide the setting editor
     */
    ProjectScopeModel.prototype.hideProjectDrillDown = function( ){
        this.show_drill_down = false;
    };

    /**
     * Toggle the setting editor
     */
    ProjectScopeModel.prototype.toggleProjectDrillDown = function( ){
        this.show_drill_down
            ? this.hideProjectDrillDown()
            : this.showProjectDrillDown();
    };

})();