(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'brandLogoType',
            [
                'CHROME',
                BrandLogoTypeFactory
            ]
        );

    // Factory Method
    function BrandLogoTypeFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            template: '<span class="brand-logo" md-truncate flex><a href="#!/landing/home">Sinewav3</a></span>'
        };

    }
})();