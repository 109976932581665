// Shell Chrome - Toolbar Controller
(function (){

    angular.module('Sinewav3.Client.Shell')

        .controller(
            'ToolbarController',
            [
                '$rootScope',
                '$scope',
                '$document',
                'MODES',
                'CHROME',
                'EVENTS',
                ToolbarController
            ]
        );

    // Constructor
    function ToolbarController($rootScope, $scope, $document, MODES, CHROME, EVENTS){

        var instance = this;

        instance.onDestroy = onDestroy;
        instance.onChangeMode = onChangeMode;
        instance.toggleFullScreen = toggleFullScreen;

        initialize();

        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.CHANGE_MODE, instance.onChangeMode ) );

            // Initial mode
            instance.onChangeMode(null, $rootScope.shell.mode);

        }

        /**
         * Remove event listeners when the controller is destroyed
         */
        function onDestroy(){
            var i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
        }

        /**
         * Respond to the EVENTS.CHANGE_MODE event
         * @param event
         * @param mode
         */
        function onChangeMode(event, mode){
            switch (mode)
            {
                case MODES.ADMIN:
                    $rootScope.chrome.tabs = CHROME.TABS.ADMIN;
                    break;

                case MODES.USER:
                    $rootScope.chrome.tabs = CHROME.TABS.USER;
                    break;

                case MODES.ANON:
                    $rootScope.chrome.tabs = CHROME.TABS.ANON;
                    if ($rootScope.shell.fullscreen) instance.toggleFullScreen();
                    break;
            }
        }

        /**
         * Toggle full screen
         */
        function toggleFullScreen() {
            var doc = $document[0];
            var shell = $rootScope.shell;
            shell.fullscreen = !shell.fullscreen;

            if (!doc.fullscreenElement &&
                !doc.mozFullScreenElement &&
                !doc.webkitFullscreenElement &&
                !doc.msFullscreenElement) {
                if (doc.documentElement.requestFullscreen) {
                    doc.documentElement.requestFullscreen();
                } else if (doc.documentElement.msRequestFullscreen) {
                    doc.documentElement.msRequestFullscreen();
                } else if (doc.documentElement.mozRequestFullScreen) {
                    doc.documentElement.mozRequestFullScreen();
                } else if (doc.documentElement.webkitRequestFullscreen) {
                    doc.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (doc.exitFullscreen) {
                    doc.exitFullscreen();
                } else if (doc.msExitFullscreen) {
                    doc.msExitFullscreen();
                } else if (doc.mozCancelFullScreen) {
                    doc.mozCancelFullScreen();
                } else if (doc.webkitExitFullscreen) {
                    doc.webkitExitFullscreen();
                }
            }
        }

    }

})();
