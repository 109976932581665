/**
 * Sinewav3 Schema Entity: AudioFrame
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var AudioFrame = (function() {

        /**
         * Constructor
         * @param second
         * @param frame
         * @param avg_left
         * @param avg_right
         * @param volume
         * @param bass
         * @param mid
         * @param high
         * @param time_current  // TRANSIENT
         * @param time_target   // TRANSIENT
         * @constructor
         */
        function AudioFrame(second, frame, avg_left, avg_right, volume, bass, mid, high, time_current, time_target) {
            this.second = second;
            this.frame = frame;
            this.avg_left = avg_left;
            this.avg_right = avg_right;
            this.volume = volume;
            this.bass = bass;
            this.mid = mid;
            this.high = high;
            this.time_current = time_current; // Transient properties
            this.time_target = time_target;   // Used only during processing, not persisted
        }

        /**
         * Get a new AudioFrame instance from a database representation
         * @param o
         * @returns {AudioFrame}
         */
        AudioFrame.fromObject = function(o) {
            return new AudioFrame(
                o.second,
                o.frame,
                o.avg_left,
                o.avg_right,
                o.volume,
                o.bass,
                o.mid,
                o.high,
                o.time_current,
                o.time_target
            );
        };

        /**
         * Get a database representation of this AudioFrame instance
         * NOTE: Transient properties are stripped out
         * @returns {object}
         */
        AudioFrame.prototype.toObject = function(){
            var o = JSON.parse(JSON.stringify(this));
            delete o['time_current']; // omit transient properties
            delete o['time_target'];  // when serializing for storage
            return o;
        };

        /**
         * Get a string representation of this AudioFrame instance
         * @returns {string}
         */
        AudioFrame.prototype.toString = function() {
            return [
                this.second,
                this.frame,
                this.avg_left,
                this.avg_right,
                this.volume,
                this.bass,
                this.mid,
                this.high
            ].join(' ');
        };

        /**
         * Is this AudioFrame instance's second field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.secondIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.second !== 'undefined' &&
                    typeof this.second === 'number' &&
                    Number.isInteger(this.second)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's frame field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.frameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.frame !== 'undefined' &&
                    typeof this.frame === 'number' &&
                    Number.isInteger(this.frame) &&
                    this.frame <= this.frame &&
                    this.frame >= 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's avg_left field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.avgLeftIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.avg_left !== 'undefined' &&
                    typeof this.avg_left === 'number' &&
                    this.avg_left >= 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's avg_right field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.avgRightIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.avg_right !== 'undefined' &&
                    typeof this.avg_right === 'number' &&
                    this.avg_right >= 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's volume field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.volumeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.volume !== 'undefined' &&
                    typeof this.volume === 'number' &&
                    this.volume >= 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's bass field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.bassIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.bass !== 'undefined' &&
                    typeof this.bass === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's mid field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.midIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.mid !== 'undefined' &&
                    typeof this.mid === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance's high field valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.highIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.high !== 'undefined' &&
                    typeof this.high === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioFrame instance valid?
         * @returns {boolean|*}
         */
        AudioFrame.prototype.isValid = function() {
            return (
                this.secondIsValid() &&
                this.frameIsValid() &&
                this.avgLeftIsValid() &&
                this.avgRightIsValid() &&
                this.volumeIsValid() &&
                this.bassIsValid() &&
                this.midIsValid() &&
                this.highIsValid()
            );
        };

        return AudioFrame;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = AudioFrame;
    } else {
        window.AudioFrame = AudioFrame;
    }

})();