/**
 * Sinewav3 Schema Entity: PluginLike
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var PluginLike = (function() {

        /**
         * Constructor
         * @param uid
         * @param plugin_id
         * @constructor
         */
        function PluginLike( uid, plugin_id ) {
            this.uid = uid;
            this.plugin_id = plugin_id;
            this.key = this.getKey();
        }

        /**
         * Get a new PluginLike instance from a database representation
         * @param o
         * @returns {PluginLike}
         */
        PluginLike.fromObject = function(o) {
            return new PluginLike( o.uid, o.plugin_id );
        };


        /**
         * Get a string representation of this PluginLike instance
         * @returns {string}
         */
        PluginLike.prototype.toString = function() {
            return [
                this.key,
                this.uid,
                this.plugin_id
            ].join(' ');
        };

        /**
         * Get a database representation of this PluginLike instance
         * @returns {object}
         */
        PluginLike.prototype.toObject = function(){
            this.key = this.getKey(); // just in case it was meddled with
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Is this PluginLike instance's uid field valid?
         * @returns {boolean|*}
         */
        PluginLike.prototype.uidIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.uid !== null &&
                    typeof this.uid !== 'undefined' &&
                    typeof this.uid === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PluginLike instance's plugin_id field valid?
         * @returns {boolean|*}
         */
        PluginLike.prototype.pluginIdIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.plugin_id !== null &&
                    typeof this.plugin_id !== 'undefined' &&
                    typeof this.plugin_id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PluginLike instance's key field valid?
         * @returns {boolean|*}
         */
        PluginLike.prototype.keyIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.key !== null &&
                    typeof this.key !== 'undefined' &&
                    typeof this.key === 'string' &&
                    this.key === this.getKey()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PluginLike instance valid?
         * @returns {boolean|*}
         */
        PluginLike.prototype.isValid = function() {
            return (
                this.uidIsValid() &&
                this.pluginIdIsValid() &&
                this.keyIsValid()

            );
        };

        /**
         * Get the appropriate key for this PluginLike instance
         * @returns {boolean|*}
         */
        PluginLike.prototype.getKey = function() {
            return this.uid + '<-+->' + this.plugin_id;
        };

        return PluginLike;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = PluginLike;
    } else {
        window.PluginLike = PluginLike;
    }

})();