/**
 * Sinewav3 Schema Entity: WorldContextContext
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var WorldContext = (function() {

        /**
         * Constructor
         * @param name
         * @params settings
         * @constructor
         */
        function WorldContext( name, settings ) {
            this.name = name;
            this.settings = settings
        }

        /**
         * Get a settings group by name from this WorldContext instance's settings collection
         * @param group
         */
        WorldContext.prototype.getSettingGroup = function( name ) {
            var group = ( this.settings )
                ? this.settings.find( item => item.name === name )
                : null;
            return group;
        };

        return WorldContext;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = WorldContext;
    } else {
        window.WorldContext = WorldContext;
    }

})();