/**
 * Sinewav3 Schema Entity: Project
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Project = (function() {

        /**
         * Constructor
         * @param id
         * @param name
         * @param owner
         * @param status
         * @param worlds
         * @param audio_asset
         * @param description
         * @param is_public
         * @constructor
         */
        function Project(id, name, owner, status, worlds, audio_asset, description, is_public) {
            this.id = id;
            this.name = name;
            this.owner = owner;
            this.status = status || Project.STATUS.DEVELOPMENT;
            this.worlds = worlds || [];
            this.audio_asset = audio_asset || null;
            this.description = description;
            this.is_public = (typeof is_public === 'undefined') ? false : is_public;
        }

        /**
         * Project Status Constants
         * @type {{DEVELOPMENT: string, SUBMITTED: string, APPROVED: string, REJECTED: string}}
         */
        Project.STATUS = {
            DEVELOPMENT: 'development',
            SUBMITTED: 'submitted',
            APPROVED: 'approved',
            REJECTED: 'rejected'
        };

        /**
         * Get a new Project instance from a database representation
         * @param o
         * @returns {Project}
         */
        Project.fromObject = function(o) {
            var owner = o.owner ? Profile.fromObject(o.owner).getToken() : null;
            var audio_asset = o.audio_asset ? Asset.fromObject(o.audio_asset) : null;
            var worlds = o.worlds ? o.worlds.map(world => World.fromObject(world)) : null;
            return new Project(o.id, o.name, owner, o.status, worlds, audio_asset, o.description, o.is_public);
        };

        /**
         * Get a database representation of this Project instance
         * @returns {object}
         */
        Project.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Project instance
         * @returns {string}
         */
        Project.prototype.toString = function() {
            return [
                this.idIsValid() ? this.id : "",
                this.nameIsValid() ? this.name : "",
                this.ownerIsValid() ? this.owner.toString() : "",
                this.status,
                this.worldsIsValid() ? this.worlds.reduce( (prev, world) => prev + (prev ? ", ": "") + world.toString() ) : "",
                this.audio_asset ? this.audio_asset.toString() : "",
                this.description ? this.description : ""
            ].join(' ');
        };

        /**
         * Get a ProjectToken instance referring to this Project
         * @returns {ProjectToken}
         */
        Project.prototype.getToken = function() {
            return new ProjectToken( this.id, this.name, this.owner, this.description, this.is_public );
        };


        /**
         * Is this Project instance's id field valid?
         * @returns {boolean|*}
         */
        Project.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Project instance's name field valid?
         * @returns {boolean|*}
         */
        Project.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string' &&
                    this.name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Project instance's owner field valid?
         * @returns {boolean|*}
         */
        Project.prototype.ownerIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.owner  !== 'undefined' && this.owner !== null &&
                    Object.getPrototypeOf(this.owner) === UserToken.prototype &&
                    this.owner.isValid()
                );
            } catch (e) {}
            return valid;
        };


        /**
         * Is this Project instance's status field valid?
         * @returns {boolean|*}
         */
        Project.prototype.statusIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.status !== null &&
                    typeof this.status !== 'undefined' &&
                    typeof this.status === 'string' &&
                    Object.values( Project.STATUS ).indexOf( this.status ) !=-1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Project instance's worlds field valid?
         * If set, must be an array fo valid SettingsGroup instances
         * @returns {boolean|*}
         */
        Project.prototype.worldsIsValid = function() {
            var valid = false;
            try {
                valid = this.worlds != null &&
                        typeof this.worlds != 'undefined' &&
                        Array.isArray(this.worlds) &&
                        this.worlds.length > 0 &&
                        this.worlds.reduce( (prev, world) => prev && world.isValid(), true );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Project instance's audio_asset field valid?
         * @returns {boolean|*}
         */
        Project.prototype.audioAssetIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.audio_asset === null ||
                        typeof this.audio_asset == 'undefined'
                    ) || (
                        Object.getPrototypeOf(this.audio_asset) === Asset.prototype &&
                        this.audio_asset.isValid()
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Project instance's audio_asset field populated and valid?
         * @returns {boolean|*}
         */
        Project.prototype.hasAudio = function() {

            return( this.audio_asset && this.audioAssetIsValid() );
        };

        /**
         * Get this Project instance's audio_asset
         * @returns {T}
         */
        Project.prototype.getAudio = function() {
            return this.audio_asset;
        };

        /**
         * Add a World to this Project instance's collection
         * @param world
         */
        Project.prototype.addWorld = function( world ) {
            if ( Object.getPrototypeOf( world ) === World.prototype ) {
                this.worlds.unshift( world );
            };
        };

        /**
         * Is this Project instance valid?
         * @returns {boolean|*}
         */
        Project.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.nameIsValid() &&
                this.ownerIsValid() &&
                this.statusIsValid() &&
                this.worldsIsValid() &&
                this.audioAssetIsValid()
            );
        };

        return Project;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Project;
    } else {
        window.Project = Project;
    }

})();