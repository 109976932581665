(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'fabMenu',
            [
                'CHROME',
                FabMenuFactory
            ]
        );

    // Factory Method
    function FabMenuFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            controller: 'FabController',
            controllerAs: 'fabCtrl',
            templateUrl: CHROME.PREFIX + CHROME.FAB.MENU + CHROME.POSTFIX
    };

    }
})();



