/**
 * Asset Controller
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Controller to the module
    angular.module("Sinewav3.Client.Shell")
        .controller(
            'AssetController',
            [
                '$rootScope',
                '$scope',
                '$timeout',
                '$window',
                '$mdDialog',
                'ToastService',
                'AssetService',
                'FocusService',
                'BroadcastService',
                'EVENTS',
                'CHROME',
                'SECTIONS',
                'VISUALIZER',
                'UPLOAD_TYPES',
                'ASSET_SERVICE_RESPONSES',
                AssetController
            ]
        );

    // Constructor
    function AssetController($rootScope,
                             $scope,
                             $timeout,
                             $window,
                             $mdDialog,
                             ToastService,
                             AssetService,
                             FocusService,
                             BroadcastService,
                             EVENTS,
                             CHROME,
                             SECTIONS,
                             VISUALIZER,
                             UPLOAD_TYPES,
                             ASSET_SERVICE_RESPONSES)
    {
        // Construct and initialize the instance
        let instance = this;

        instance.onDestroy = onDestroy;
        instance.onNewAssetAction = onNewAssetAction;
        instance.createAsset = createAsset;
        instance.deleteAsset = deleteAsset;
        instance.selectAsset = selectAsset;
        instance.onAssetCRUDResponse = onAssetCRUDResponse;
        instance.getSecondaryActions = getSecondaryActions;
        instance.invokeSecondaryAction = invokeSecondaryAction;
        instance.invokeFabAction = invokeFabAction;
        instance.returnToList = returnToList;

        initialize();

        // Initialize the controller
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.SAVE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.CLOSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.NEW_ASSET.ACTION, instance.onNewAssetAction ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS, instance.onAssetCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, instance.onAssetCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.DELETE_ASSET.SUCCESS, instance.onAssetCRUDResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.DELETE_ASSET.FAILURE, instance.onAssetCRUDResponse ) );

            // Show the FAB Menu if editing, or FAB Button with the New Asset if not
            let assetScopeModel = $rootScope.asset;
            let shellScopeModel = $rootScope.shell;
            if (assetScopeModel.viewing){
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.CLOSE);
            } else {
                if (shellScopeModel.page === SECTIONS.ACCOUNT.VIEW_ASSET && !assetScopeModel.selected) {
                    returnToList();
                } else {
                    BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.NEW_ASSET);
                }
            }
        }

        /**
         * Remove event listeners when the controller is destroyed
         * Also hide the FAB and close the viewer as we are navigating away from the Asset page
         */
        function onDestroy(){

            // Remove listeners
            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }

            // Hide the FAB
            BroadcastService.send(EVENTS.HIDE_FAB);

            // Close the viewer and the visualizer if need be
            let shellScopeModel = $rootScope.shell;
            if (shellScopeModel.page !== SECTIONS.ACCOUNT.VIEW_ASSET) {
                let assetScopeModel = $rootScope.asset;
                if (assetScopeModel.isModel(assetScopeModel.selected)) {
                    if (shellScopeModel.webgl) VISUALIZER.close();
                }
                assetScopeModel.deselectAsset();
            }
        }

        /**
         * Show the Create Asset dialog
         */
        function onNewAssetAction(event)
        {
            // SHOW THE NEW ASSET DIALOG
            $mdDialog.show({
                templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.ASSET_DLG + SECTIONS.POSTFIX,
                parent: angular.element(document.body),
                targetEvent: event,
                fullscreen:true,
                clickOutsideToClose:false,
                controller:
                    function ($scope, $mdDialog, $rootScope) {
                        $scope.asset = $rootScope.asset;
                        $scope.UPLOAD_TYPES = UPLOAD_TYPES;
                        $scope.cancel = () => $mdDialog.cancel();
                        $scope.submit = () => $mdDialog.hide();
                        $scope.onTypeChange = () => $scope.asset.typeChanged();
                        $scope.onChange = function(files) {
                            $timeout( () => {
                                if ($scope.asset.input.type === UPLOAD_TYPES.CUBE) {
                                    if (files.length === 6) {
                                        let arry = [], list = '';
                                        for (let i = 0; i < files.length; i++) {
                                            arry.push(files[i]);
                                            if (i>0) list += ', ';
                                            list += files[i].name;
                                        }
                                        $scope.asset.input.files = arry;
                                        $scope.asset.input.filelist = list;
                                        $timeout( () => FocusService.focusOn('assetName') );
                                    }
                                } else {
                                    $scope.asset.input.file = files[0];
                                    $scope.asset.input.name = $scope.asset.extractAssetName($scope.asset.input.file.name);
                                    if ($scope.asset.input.type === UPLOAD_TYPES.AUDIO) {
                                        try {
                                            let audio_object_url = URL.createObjectURL($scope.asset.input.file);
                                            let audio_element = document.getElementById('audio');
                                            audio_element.addEventListener('canplaythrough', event => {
                                                $scope.$apply(() => {
                                                    $scope.asset.input.duration = event.currentTarget.duration;
                                                    $scope.asset.input.duration_extracted = event.currentTarget.duration > 0;
                                                });
                                                URL.revokeObjectURL(audio_object_url);
                                            });
                                            audio_element.src = audio_object_url;
                                        } catch (e){
                                            console.log('Unable to extract audio track duration.');
                                        }
                                    }
                                    $timeout( () => FocusService.focusOn('assetName') );
                                }
                            });
                        }
                    }
                })
                .then(
                    () => instance.createAsset(),        // SUBMIT
                    () => $scope.asset.resetFormInputs() // CANCEL
                )
        }

        /**
         * Create a new asset
         */
        function createAsset( )
        {
            let assetScopeModel = $rootScope.asset;
            assetScopeModel.uploadingAsset();

            // Hide the FAB MENU and BUTTON
            BroadcastService.send(EVENTS.HIDE_FAB);

            let name = assetScopeModel.input.name;
            let file = assetScopeModel.input.file;
            let files = assetScopeModel.input.files;
            let type = assetScopeModel.input.type.TYPE;
            let userToken = $rootScope.account.profile.getToken();
            let namingFn = $scope.asset.extractAssetName;
            if (type != AssetGroup.TYPE.CUBE) {
                AssetService.createAsset(userToken, type, name, file);
            } else {
                AssetService.createAssetGroup(userToken, type, name, files, Asset.TYPE.IMAGE, namingFn);
            }
        }

        /**
         * Delete an asset
         * @param asset
         */
        function deleteAsset(asset)
        {
            let message = 'Are you sure you want to delete '+asset.name+'?';
            let confirm = $mdDialog.confirm()
                .title('Delete Asset')
                .textContent(message)
                .ariaLabel(message)
                .ok('Cancel')
                .cancel('Delete');

            $mdDialog.show(confirm)
                .then(
                    () => { }, // CANCEL
                    () => {    // DELETE
                        asset.is_group ? AssetService.deleteAssetGroup(asset) : AssetService.deleteAsset(asset);
                    } // CANCEL
                )
        }

        /**
         * The user has clicked on an asset in the list
         ** @param asset
         */
        function selectAsset(asset)
        {
            // Select the asset in the AssetScopeModel
            let assetScopeModel = $rootScope.asset;
            let shellScopeModel = $rootScope.shell;
            assetScopeModel.selectAsset(asset);

            // Load the asset
            switch(asset.type) {
                case Asset.TYPE.IMAGE:
                    assetScopeModel.image = loadImage(asset.url, false);
                    break;

                case AssetGroup.TYPE.CUBE:
                    asset.assets.forEach( item => loadImage(item.url, true) );
                    break;

                case Asset.TYPE.MODEL:
                    if (shellScopeModel.webgl) loadModel(asset.url);
                    break;

                case Asset.TYPE.FONT:
                    if (shellScopeModel.webgl) loadFont(asset.url);
                    break;
            }

            // Change to the asset viewer
            BroadcastService.send(EVENTS.NAV_TO, SECTIONS.ACCOUNT.VIEW_ASSET);

            // Inner function to load an image
            function loadImage(url, isCube) {
                let image = new Image();
                image.onload = () => {
                    assetScopeModel.imageLoaded(image,isCube);
                    $timeout( () => {
                        if (!isCube) image.className = 'stretch';
                        let viewer_id = isCube ? CHROME.CUBE_VIEWER : CHROME.IMG_VIEWER;
                        let viewer = $window.document.getElementById(viewer_id);
                        if (viewer) viewer.appendChild(image);
                    }, 350);
                };
                image.src = url;
                return image;
            }

            function loadModel(url) {
                let xhr = new XMLHttpRequest();
                xhr.responseType = 'text';
                xhr.onload = () => {
                    assetScopeModel.modelLoaded();
                    $timeout( () => VISUALIZER.viewModel(xhr.response, CHROME.MODEL_VIEWER), 350);
                };
                xhr.open('GET', url);
                xhr.send();
            }

            function loadFont(url) {

                $timeout( () => VISUALIZER.viewFont(
                    url,
                    CHROME.FONT_VIEWER,
                    () => $timeout( assetScopeModel.fontLoaded(), 350 )
                ), 350);

            }

        }

        /**
         * Handle the CREATE_ASSET and DELETE_ASSET responses
         * @param event
         * @param data
         */
        function onAssetCRUDResponse(event, data)
        {
            $rootScope.asset.resetFormInputs();
            switch (event.name)
            {
                case ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS:
                    $timeout(
                        () => instance.selectAsset(data)
                    );
                    break;

                case ASSET_SERVICE_RESPONSES.DELETE_ASSET.SUCCESS:
                    $timeout(function() {
                        let message = 'Asset deleted!';
                        ToastService.showToast(message);
                        instance.returnToList();
                    });
                    break;

                case ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE:
                case ASSET_SERVICE_RESPONSES.DELETE_ASSET.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Return the list of secondary actions for the given asset
         * @param asset
         */
        function getSecondaryActions(asset)
        {
            let actions = [];

            actions.push(CHROME.FAB.ACTIONS.DOWNLOAD);
            actions.push(CHROME.FAB.ACTIONS.DELETE);

            return actions;
        }

        /**
         * Invoke a secondary action from an icon in the asset list
         * @param asset
         */
        function invokeSecondaryAction(asset,action)
        {
            switch (action){

                case CHROME.FAB.ACTIONS.DELETE.ACTION:
                    instance.deleteAsset(asset);
                    break;

                case CHROME.FAB.ACTIONS.DOWNLOAD.ACTION:
                    switch(asset.type)
                    {
                        case Asset.TYPE.VIDEO:
                        case Asset.TYPE.AUDIO:
                        case Asset.TYPE.MODEL:
                        case Asset.TYPE.IMAGE:
                        case Asset.TYPE.FONT:
                            $window.open(asset.url, '_blank');
                            break;

                        case AssetGroup.TYPE.CUBE:
                            asset.assets.forEach( item => $window.open(item.url, '_blank') );
                            break;
                    }
                    break;
            }
            BroadcastService.send(action,asset);
        }

        /**
         * While editing an asset the user has invoked
         * an action from the FAB menu
         * @param event
         */
        function invokeFabAction(event)
        {
            let action = event.name;
            let assetScopeModel = $rootScope.asset;
            switch (action) {

                case CHROME.FAB.ACTIONS.CLOSE.ACTION:
                    if (assetScopeModel.isModel(assetScopeModel.selected) ||
                        assetScopeModel.isFont(assetScopeModel.selected)) {
                        VISUALIZER.close();
                    };
                    instance.returnToList();
                    break;

            }
        }

        /**
         * Return to the list view
         */
        function returnToList() {
            // Deselect the asset and reset the form inputs in the AssetScopeModel
            $rootScope.asset.deselectAsset();

            // Change to the assets list
            BroadcastService.send(EVENTS.NAV_TO, SECTIONS.ACCOUNT.ASSETS);

        }

    }
})(); // IIFE keeps global scope clean
