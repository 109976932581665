/**
 * Splash Controller
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Controller to the module
    angular.module("Sinewav3.Client.Shell")
        .controller(
            'SplashController',
            [
                '$rootScope',
                '$scope',
                '$timeout',
                'ProjectService',
                'BroadcastService',
                'EVENTS',
                'CHROME',
                'VISUALIZER',
                'PROJECT_SERVICE_RESPONSES',
                SplashController
            ]
        );

    // Constructor
    function SplashController($rootScope,
                              $scope,
                              $timeout,
                              ProjectService,
                              BroadcastService,
                              EVENTS,
                              CHROME,
                              VISUALIZER,
                              PROJECT_SERVICE_RESPONSES
    ) {
        // Construct and initialize the instance
        let instance = this;
        instance.onDestroy = onDestroy;
        instance.onKeyDown = onKeyDown;
        instance.showIntroduction = showIntroduction;
        instance.showPresentation = showPresentation;
        instance.closePresentation = closePresentation;
        instance.onLoadProjectResponse = onLoadProjectResponse;
        instance.visualizeProject = visualizeProject;
        instance.invokeFabAction = invokeFabAction;

        initialize();

        // Initialize the controller
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            document.onkeydown = instance.onKeyDown;

            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.CLOSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.LOAD_PROJECT.SUCCESS, instance.onLoadProjectResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.LOAD_PROJECT.FAILURE, instance.onLoadProjectResponse ) );

            // Show the intro card
            instance.showIntroduction();
        }

        /**
         * Remove event listeners, close visualizer,
         * and deselect project when the controller is destroyed
         */
        function onDestroy(){
            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
            instance.closePresentation();
            document.onkeydown = undefined;
        }


        /**
         * Handle the KEY_DOWN event for navigation during presentations
         * @param event
         * @param object
         * @param key
         */
        function onKeyDown(event) {

            let splashScopeModel = $rootScope.splash;
            if (splashScopeModel.show_preso && !splashScopeModel.debouncing ) {

                const ESC = 27;
                const LEFT = 37;
                const UP = 38;
                const RIGHT = 39;
                const DOWN = 40;

                switch (event.keyCode) {
                    case UP:
                    case LEFT:
                        splashScopeModel.navigatePrevious();
                        break;

                    case RIGHT:
                    case DOWN:
                        splashScopeModel.navigateNext();
                        break;

                    case ESC:
                        $timeout( () => instance.closePresentation(), 100);
                        break;
                }
            }

        }

        /**
         * Show the intro card
         */
        function showIntroduction() {
            let splashScopeModel = $rootScope.splash;
            splashScopeModel.showIntro();
        }

        /**
         * Show the selected presentation
         * @param preso
         */
        function showPresentation( preso ) {

            // Show the close FAB button
            $timeout(() => {
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.CLOSE);
            }, 600);

            // Show the presentation
            let splashScopeModel = $rootScope.splash;
            splashScopeModel.showPreso(preso);

            // Load the visualizer project if WebGL is available
            if ($rootScope.shell.webgl) {
                let project_id = $rootScope.shell.config.preso[preso.CONFIG];
                ProjectService.loadProject(project_id);
            } else {
                splashScopeModel.visualizeProject(null);
            }
        }

        /**
         * Close the running presentation
         */
        function closePresentation() {
            $rootScope.splash.closePreso();
            VISUALIZER.close();
            BroadcastService.send(EVENTS.HIDE_FAB);
        }

        /**
         * Orchestrate visualization of the splash project
         *
         * @param project
         * @param delay
         */
        function visualizeProject(project) {
            let splashScopeModel = $rootScope.splash;
            let getSelectedWorldIndex = splashScopeModel.visualizeProject(project);
            $timeout(() => {
                VISUALIZER.visualize(project, CHROME.VISUALIZER_PANE, getSelectedWorldIndex);
            }, 100);
        }

        /**
         * Handle the LOAD_PROJECT response
         * @param event
         * @param data
         */
        function onLoadProjectResponse(event, data) {
            $timeout( () => instance.visualizeProject(data) );
        }

        /**
         * While a project is selected, the user has invoked
         * an action from the FAB menu
         * @param event
         */
        function invokeFabAction(event) {
            let action = event.name;
            switch (action)
            {
                case CHROME.FAB.ACTIONS.CLOSE.ACTION:
                    instance.closePresentation();
                    break;
            }
        }

    }
})(); // IIFE keeps global scope clean
