/**
 * Sinewav3 Schema Entity: Plugin
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Plugin = (function() {

        /**
         * Constructor
         * @param id
         * @param name
         * @param author
         * @param status
         * @param fn_setup
         * @param fn_render
         * @param fn_destroy
         * @param settings
         * @param description
         * @param is_public
         * @param is_example
         * @param likes
         * @param reject_reason
         * @constructor
         */
        function Plugin(id, name, author, status, fn_setup, fn_render, fn_destroy, settings, description, is_public, is_example, likes, reject_reason) {
            this.id = id;
            this.name = name;
            this.author = author;
            this.status = status || Plugin.STATUS.DEVELOPMENT;
            this.description = description;
            this.fn_setup = fn_setup;
            this.fn_render = fn_render;
            this.fn_destroy = fn_destroy;
            this.settings = settings;
            this.is_public  = (typeof is_public  === 'undefined')? false : is_public;
            this.is_example = (typeof is_example === 'undefined')? false : is_example;
            this.likes = likes || 0;
            this.reject_reason = reject_reason;
        }

        /**
         * Plugin Status Constants
         * @type {{DEVELOPMENT: string, SUBMITTED: string, APPROVED: string, REJECTED: string}}
         */
        Plugin.STATUS = {
            DEVELOPMENT: 'development',
            SUBMITTED: 'submitted',
            APPROVED: 'approved',
            REJECTED: 'rejected'
        };

        /**
         * Get a new Plugin instance from a database representation
         * @param o
         * @returns {Plugin}
         */
        Plugin.fromObject = function(o) {
            var settings = o.settings ? o.settings.map(settingGroup => SettingGroup.fromObject(settingGroup)) : null;
            var author_name = o.author ? new NameToken(o.author.name.display, o.author.name.first, o.author.name.last) : null;
            var author = o.author ? new UserToken(o.author.uid, author_name, o.author.photo_url) : null;
            return new Plugin(
                o.id,
                o.name,
                author,
                o.status,
                o.fn_setup,
                o.fn_render,
                o.fn_destroy,
                settings,
                o.description,
                o.is_public,
                o.is_example,
                o.likes,
                o.reject_reason
            );
        };

        /**
         * Get a database representation of this Plugin instance
         * @returns {object}
         */
        Plugin.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Plugin instance
         * @returns {string}
         */
        Plugin.prototype.toString = function() {
            return [
                this.id,
                this.name,
                this.authorIsValid() ? this.author.toString() : "",
                this.status,
                this.is_public,
                this.is_example,
                this.reject_reason,
                this.fnSetupIsValid() ? this.fn_setup : "",
                this.fnRenderIsValid() ? this.fn_render : "",
                this.fnDestroyIsValid() ? this.fn_destroy : "",
                this.is_public,
                this.is_example,
                this.likes,
                this.settings && this.settingsIsValid() ? this.settings.reduce( (prev, settingsGroup) => prev + (prev ? ", ": "") + settingsGroup.toString(), null ) : "",
            ].join(' ');
        };

        /**
         * Is this Plugin instance's id field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's name field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string' &&
                    this.name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's author field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.authorIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.author !== 'undefined' && this.author !== null &&
                    Object.getPrototypeOf(this.author) === UserToken.prototype &&
                    this.author.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's status field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.statusIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.status !== null &&
                    typeof this.status !== 'undefined' &&
                    typeof this.status === 'string' &&
                    (
                        this.status === Plugin.STATUS.DEVELOPMENT ||
                        this.status === Plugin.STATUS.SUBMITTED ||
                        this.status === Plugin.STATUS.APPROVED ||
                        this.status === Plugin.STATUS.REJECTED
                    ) && (
                        this.status === Plugin.STATUS.REJECTED
                            ? this.rejectReasonIsValid()
                            : true
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's fn_setup field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.fnSetupIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.fn_setup === null ||
                        this.fn_setup === "" ||
                        typeof this.fn_setup === 'undefined'
                    ) || (
                        typeof this.fn_setup === 'string' &&
                        this.fn_setup.length > 0 &&
                        typeof this.getSetupFunction() === 'function'
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's fn_render field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.fnRenderIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.fn_render === null ||
                    this.fn_render === "" ||
                    typeof this.fn_render === 'undefined'
                ) || (
                    typeof this.fn_render === 'string' &&
                    this.fn_render.length > 0 &&
                    typeof this.getRenderFunction() === 'function'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is one or both of this Plugin instance's fn_setup and fn_render fields populated?
         * @returns {boolean|*}
         */
        Plugin.prototype.setupOrRenderIsPopulated = function() {
            var valid = false;
            try {
                valid = (
                    (this.fn_render !== null && this.fn_render !== "") ||
                    (this.fn_setup !== null && this.fn_setup !== "")
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's fn_destroy field valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.fnDestroyIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.fn_destroy === null ||
                    this.fn_destroy === "" ||
                    typeof this.fn_destroy === 'undefined'
                ) || (
                    typeof this.fn_destroy === 'string' &&
                    this.fn_destroy.length > 0 &&
                    typeof this.getDestroyFunction() === 'function'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's settings field valid?
         * If set, must be an array fo valid SettingsGroup instances
         * @returns {boolean|*}
         */
        Plugin.prototype.settingsIsValid = function() {
            var valid = false;
            try {
                valid = (
                        Array.isArray(this.settings) &&
                        (this.settings.length > 0)
                            ? this.settings.reduce( (prev, group) => prev && group.isValid(), true )
                            : true
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plugin instance's name reject_reason valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.rejectReasonIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.status === Plugin.STATUS.REJECTED &&
                        typeof this.reject_reason === 'string'
                    ) || (
                        this.status !== Plugin.STATUS.REJECTED &&
                        this.reject_reason === null ||
                        typeof this.reject_reason === 'undefined'
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Add a settings group
         * @param group
         */
        Plugin.prototype.addSettingGroup = function( group ) {
            if ( !this.settings ) this.settings = [];
            if ( Object.getPrototypeOf( group ) === SettingGroup.prototype ) {
                this.settings.unshift( group );
            }
        };

        /**
         * Get a settings group by name from this Plugin instance's settings collection
         * @param group
         */
        Plugin.prototype.getSettingGroup = function( name ) {
            var group = ( this.settings )
                ? this.settings.find( item => item.name === name )
                : null;
            return group;
        };

        /**
         * Is this Plugin instance valid?
         * @returns {boolean|*}
         */
        Plugin.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.nameIsValid() &&
                this.authorIsValid() &&
                this.statusIsValid() &&
                this.fnSetupIsValid() &&
                this.fnRenderIsValid() &&
                this.fnDestroyIsValid() &&
                this.setupOrRenderIsPopulated() &&
                this.settingsIsValid() &&
                this.rejectReasonIsValid()
            );
        };

        /**
         * Get this Plugin instance's setup function
         * @returns {*}
         */
        Plugin.prototype.getSetupFunction = function() {
            var fn;
            try {
                fn = new Function( 'context', this.fn_setup );
            } catch(e){}
            return fn;
        };

        /**
         * Get this Plugin instance's render function
         * @returns {*}
         */
        Plugin.prototype.getRenderFunction = function() {
            var fn;
            try {
                fn = new Function( 'context', this.fn_render );
            } catch(e){}
            return fn;
        };

        /**
         * Get this Plugin instance's destroy function
         * @returns {*}
         */
        Plugin.prototype.getDestroyFunction = function() {
            var fn;
            try {
                fn = new Function( 'context', this.fn_destroy );
            } catch(e){}
            return fn;
        };

        /**
         * Get a PluginContext for this Plugin instance
         * @returns {*}
         */
        Plugin.prototype.getContext = function() {
            return new PluginContext( this.id, this.name, this.author, this.settings );
        };

        /**
         * Have the plugin's settings changed from their sane defaults?
         * @param plugin
         * @returns {boolean}
         */
        Plugin.prototype.settingsHaveChanged = function(){
            let retval = false;
            if (this.settings) this.settings.forEach(
                group => {
                    if( group.settings ) group.settings.forEach(
                        setting => retval = retval || !setting.valueIsDefault()
                    )
                }
            );
            return retval;
        };


        /**
         * Reset plugin settings to their sane defaults
         * @returns {*}
         */
        Plugin.prototype.resetPluginSettings = function() {
            if (this.settings) this.settings.forEach(
                group => {
                    if( group.settings ) group.settings.forEach(
                        setting => setting.resetToDefault()
                    )
                }
            )
        };

        return Plugin;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Plugin;
    } else {
        window.Plugin = Plugin;
    }

})();