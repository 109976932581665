// Shell Chrome - FAB Controller
(function (){

    angular.module('Sinewav3.Client.Shell')

        .controller(
            'FabController',
            [
                'BroadcastService',
                FabController
            ]
        );

    // Constructor
    function FabController(BroadcastService){

        let instance = this;
        instance.invokeAction = invokeAction;

        function invokeAction(action){
            BroadcastService.send(action.ACTION);
        }
    }

})();
