/**
 * Sinewav3 Schema Entity: Transaction
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Transaction = (function() {

        /**
         * Constructor
         * @param id
         * @param time
         * @param amount
         * @param type
         * @param user
         * @param reference
         * @constructor
         */
        function Transaction(id, time, amount, type, user, reference) {
            this.id = id;
            this.time = time;
            this.amount = amount;
            this.type = type;
            this.user = user; /* UserToken */
            this.reference = reference;
        }

        /**
         * Transaction Types
         * @type {{PAYMENT: string, ROYALTY: string, REFUND: string}}
         */
        Transaction.TYPE = {
            PAYMENT: 'payment',
            ROYALTY: 'royalty',
            REFUND: 'refund'
        };

        /**
         * Get a new Transaction instance from a database representation
         * @param o
         * @returns {Transaction}
         */
        Transaction.fromObject = function(o) {
            var user = o.user ? UserToken.fromObject(o.user) : null;
            return new Transaction(o.id, o.time, o.amount, o.type, user, o.reference);
        };

        /**
         * Get a database representation of this Transaction instance
         * @returns {object}
         */
        Transaction.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Transaction instance
         * @returns {boolean}
         */
        Transaction.prototype.toString = function() {
            return [
                this.id,
                this.time ? new Date(this.time).toDateString() : "",
                this.type,
                this.user ? this.user.toString() : "",
                this.reference
            ].join(', ');
        };

        /**
         * Is this Transaction instance's id field valid?
         * @returns {boolean}
         */
        Transaction.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Transaction instance's type field valid?
         * @returns {boolean}
         */
        Transaction.prototype.typeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    (
                        this.type === Transaction.TYPE.PAYMENT ||
                        this.type === Transaction.TYPE.ROYALTY ||
                        this.type === Transaction.TYPE.REFUND
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Transaction instance's amount field valid?
         * @returns {boolean}
         */
        Transaction.prototype.amountIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.amount !== null &&
                    typeof this.amount !== 'undefined' &&
                    typeof this.amount === 'number' &&
                    this.amount > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Transaction instance's time field valid?
         * @returns {boolean}
         */
        Transaction.prototype.timeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.time !== null &&
                    typeof this.time !== 'undefined' &&
                    typeof this.time === 'number' &&
                    Number.isInteger(this.time)  &&
                    this.time > 1483228800 // 01/01/2017 @ 12:00am (UTC)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Transaction instance's user field valid?
         * @returns {boolean}
         */
        Transaction.prototype.userIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.user !== null &&
                    typeof this.user !== 'undefined' &&
                    Object.getPrototypeOf( this.user ) === UserToken.prototype &&
                    this.user.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Transaction instance valid?
         * @returns {boolean}
         */
        Transaction.prototype.isValid = function() {
            return (
                this.idIsValid()  &&
                this.typeIsValid() &&
                this.amountIsValid() &&
                this.timeIsValid() &&
                this.userIsValid()
            );
        };

        return Transaction;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Transaction;
    } else {
        window.Transaction = Transaction;
    }

})();