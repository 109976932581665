(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'fileread',
            [
                FileReadFactory
            ]
        );

    // Factory Method
    function FileReadFactory()
    {
        // Construct and return the instance
        return {

            restrict : 'A',
            require: '?ngModel',
            link: function (scope, element, attributes, ngModel) {
                element.bind("change", function (changeEvent) {
                    // Report that a file was selected
                    let filename = changeEvent.target.files[0].name;
                    let value = {
                        filename: filename,
                        loading: true
                    };
                    ngModel.$setViewValue(value);

                    // Read the file
                    let reader = new FileReader();
                    reader.onload = function (loadEvent) {
                        value.data = loadEvent.target.result;
                        value.loading = false;
                        scope.$apply(function () {
                            ngModel.$setViewValue(value);
                        });
                    };
                    reader.readAsDataURL(changeEvent.target.files[0]);
                });
            }
        };
    }
})();