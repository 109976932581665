(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'brand',
            [
                'CHROME',
                BrandFactory
            ]
        );

    // Factory Method
    function BrandFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            template: '<brand-icon hide-gt-xs></brand-icon><brand-logo-type hide-xs></brand-logo-type>'
        };

    }
})();