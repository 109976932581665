/**
 * Sinewav3 Schema Entity: AssetGroup
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var AssetGroup = (function() {

        function AssetGroup(id, type, name, owner, assets, is_public) {
            this.id = id;
            this.type = type;
            this.name = name;
            this.owner = owner;
            this.assets = assets;
            this.is_public = (typeof is_public === 'undefined')? false : is_public;
            this.is_group = true;
        }

        /**
         * AssetGroup Type Constants
         * @type {{CUBE: string}}
         */
        AssetGroup.TYPE = {
            CUBE: 'cubemap'
        };

        /**
         * Get a new AssetGroup instance from a database representation
         * @param o
         * @returns {AssetGroup}
         */
        AssetGroup.fromObject = function(o) {
            var owner = o.owner ? UserToken.fromObject(o.owner) : null;
            var assets = o.assets ? o.assets.map(asset => Asset.fromObject(asset)) : null;
            return new AssetGroup(o.id, o.type, o.name, owner, assets, o.is_public);
        };

        /**
         * Get a database representation of this AssetGroup instance
         * @returns {object}
         */
        AssetGroup.prototype.toObject = function(){
            this.is_group = true; // to be safe: internally maintained but mutable
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this AssetGroup instance
         * @returns {string}
         */
        AssetGroup.prototype.toString = function() {
            return [
                this.idIsValid() ? this.id : "",
                this.typeIsValid() ? this.type : "",
                this.nameIsValid() ? this.name : "",
                this.ownerIsValid() ? this.owner.toString() : "",
                this.assetsIsValid() ? this.assets.reduce( (prev, asset) => prev + (prev ? ", ": "") + asset.toString() ) : ""
            ].join(' ');
        };

        /**
         * Is this AssetGroup instance's id field valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AssetGroup instance's type field valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.typeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    Object.values(AssetGroup.TYPE).indexOf(this.type) != -1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AssetGroup instance's name field valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string' &&
                    this.name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AssetGroup instance's owner field valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.ownerIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.owner  !== 'undefined' && this.owner !== null &&
                    Object.getPrototypeOf(this.owner) === UserToken.prototype &&
                    this.owner.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AssetGroup instance's assets field valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.assetsIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.assets !== null &&
                        typeof this.assets !== 'undefined' &&
                        Array.isArray(this.assets) &&
                        this.assets.length > 0 &&
                        (this.type === AssetGroup.TYPE.CUBE ? this.assets.length === 6 : true) &&
                        this.assets.reduce( (prev, asset) => prev && asset.isValid(), true )
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AssetGroup instance valid?
         * @returns {boolean|*}
         */
        AssetGroup.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.typeIsValid() &&
                this.nameIsValid() &&
                this.ownerIsValid() &&
                this.assetsIsValid()
            );
        };

        /**
         * Get the aggregate size of this AssetGroup instance
         * @returns {*}
         */
        AssetGroup.prototype.getSize = function () {
            return this.assetsIsValid()
                ? this.assets.reduce( (prev, asset) => prev + asset.size, 0 )
                : 0;
        };

        /**
         * Get assets as a cube map
         * They must be ordered as px, nx, py, ny, pz, nz
         * filenames can be like: negx / posX / nX / Py / 1...6
         */
        AssetGroup.prototype.sortAssetsAsCube = function () {

            this.assets.sort( (a, b) => {

                let retval = 0;

                let aStr = a.filename.toLowerCase().split('.')[0];
                let bStr = b.filename.toLowerCase().split('.')[0];
                let aNum = parseInt(aStr, 10);
                let bNum = parseInt(bStr, 10);

                // If it's a number, then this is easy...
                if ( !Number.isNaN(aNum) && !Number.isNaN(bNum) ){
                    if (aNum < bNum) {
                        retval = -1;
                    } else if (aNum > bNum) {
                        retval = 1;
                    }

                // if not, it's a little more complicated...
                } else {

                    // A is less than B in the XYZ dimension
                    if ( ( isX(aStr) && ( isY(bStr) || isZ(bStr) ) ) ||
                         ( isY(aStr) && isZ(bStr) ) ) {

                         retval = -1;

                    // B is greater than A in the XYZ dimension
                    } else if ( ( isX(bStr) && ( isY(aStr) || isZ(aStr) ) ) ||
                                ( isY(bStr) && isZ(aStr) ) ){

                        retval = 1;

                    // A and B may be equal in the XYZ dimension...
                    } else if (

                        // A and B are equal in the XYX dimension
                        ( isX(aStr) && isX(bStr)  )||
                        ( isY(aStr) && isY(bStr)  )||
                        ( isZ(aStr) && isZ(bStr)  )) {

                        // Is A less than B in the neg/pos dimension?
                        if ( isPos(aStr) && isNeg(bStr) ) {
                            retval = -1;
                        } else if ( isNeg(aStr) && isPos(bStr) ) {
                            retval = 1;
                        }

                    } else {

                    }
                }

                return retval;

                function isNeg(str){
                    return str.includes('n');
                }

                function isPos(str){
                    return str.includes('p');
                }

                function isX(str){
                    return str.includes('x');
                }

                function isY(str){
                    return str.includes('y');
                }

                function isZ(str){
                    return str.includes('z');
                }


            });
        };

        return AssetGroup;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = AssetGroup;
    } else {
        window.AssetGroup = AssetGroup;
    }

})();