(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'sticky',
            [
                '$mdSticky',
                StickyFactory
            ]
        );

    // Factory Method
    function StickyFactory($mdSticky)
    {
        // Construct and return the instance
        return {
            restrict : 'A',
            link: function(scope, element) {
                $mdSticky(scope, element);
            }
        };
    }
})();



