/**
 * Sinewav3 Schema Entity: Plan
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Plan = (function() {

        /**
         * Constructor
         * @param id
         * @param name
         * @param price
         * @param period
         * @param hidden
         * @param entitlements
         * @param coupon
         * @param signup_date
         * @param expiry_date
         * @param next_bill
         * @constructor
         */
        function Plan(id, name, price, period, hidden, entitlements, coupon, signup_date, expiry_date, next_bill) {
            this.id = id;
            this.name = name;
            this.price = price;
            this.period = period;
            this.hidden = hidden;
            this.entitlements = entitlements;
            this.coupon = coupon;
            this.signup_date = signup_date; /* UNIX date */
            this.expiry_date = expiry_date; /* UNIX date */
            this.next_bill = next_bill;     /* UNIX date */
        }

        /**
         * Get a new Plan instance from a database representation
         * @param o
         * @returns {Plan}
         */
        Plan.fromObject = function(o) {
            var entitlements = o.entitlements ? o.entitlements.map(entitlement => Entitlement.fromObject(entitlement)) : null;
            return new Plan(
                o.id,
                o.name,
                o.price,
                o.period,
                o.hidden,
                entitlements,
                o.coupon,
                o.signup_date,
                o.expiry_date,
                o.next_bill
            );
        };

        /**
         * Get a database representation of this Plan instance
         * @returns {object}
         */
        Plan.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Plan instance
         * @returns {string}
         */
        Plan.prototype.toString = function() {
            return [
                this.id,
                this.name,
                this.price,
                this.period,
                this.hidden,
                this.entitlements ? this.entitlements.reduce( (prev, entitlement) => prev + (prev ? ", ": "") + entitlement.toString() ) : "",
                this.coupon,
                this.signup_date,
                this.expiry_date,
                this.next_bill
            ].join(' ');
        };

        /**
         * Is this Plan instance's id field valid?
         * @returns {boolean|*}
         */
        Plan.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's name field valid?
         * @returns {boolean|*}
         */
        Plan.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's price field valid?
         * @returns {boolean|*}
         */
        Plan.prototype.priceIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.price !== null &&
                    typeof this.price !== 'undefined' &&
                    typeof this.price === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's period field valid?
         * @returns {boolean|*}
         */
        Plan.prototype.periodIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.period !== null &&
                    typeof this.period !== 'undefined' &&
                    typeof this.period === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's hidden field valid?
         * @returns {boolean|*}
         */
        Plan.prototype.hiddenIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.hidden !== null &&
                    typeof this.hidden !== 'undefined' &&
                    typeof this.hidden === 'boolean'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's entitlements field valid?
         * Must be an array of valid Entitlement instances
         * @returns {boolean|*}
         */
        Plan.prototype.entitlementsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.entitlements !== null &&
                    typeof this.entitlements !== 'undefined' &&
                    Array.isArray(this.entitlements) &&
                    this.entitlements.length > 0 &&
                    this.entitlements.reduce( (prev, entitlement) => prev && entitlement.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Plan instance's signup_date field valid?
         * @returns {boolean}
         */
        Plan.prototype.signupDateIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.signup_date !== null &&
                    typeof this.signup_date !== 'undefined' &&
                    typeof this.signup_date === 'number' &&
                    Number.isInteger(this.signup_date)  &&
                    this.signup_date > 1483228800 // 01/01/2017 @ 12:00am (UTC)
                );
            } catch (e) {}
            return valid;
        };



        /**
         * Is this Plan instance valid?
         * @returns {boolean|*}
         */
        Plan.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.nameIsValid() &&
                this.priceIsValid() &&
                this.periodIsValid() &&
                this.hiddenIsValid() &&
                this.entitlementsIsValid() &&
                this.signupDateIsValid()
            );
        };

        return Plan;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Plan;
    } else {
        window.Plan = Plan;
    }

})();