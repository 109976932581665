// Shell - Asset Scope
(function (){

    angular.module('Sinewav3.Client.Shell')

        .run( [ '$rootScope', $rootScope => $rootScope.asset = new AssetScopeModel() ] );

    /**
     * Asset Scope Model
     * @constructor
     */
    function AssetScopeModel() {

        // Initialize model, state, and form inputs
        this.reset();

    }

    AssetScopeModel.prototype.reset = function () {

        // Data model
        this.resetModel();

        // State
        this.resetState();

        // Build form input models
        this.resetFormInputs();

    };

    AssetScopeModel.prototype.resetModel = function () {
        this.list = [];
        this.list_audio = [];
        this.list_images = [];
        this.list_models = [];
        this.list_cubes = [];
        this.list_fonts = [];
        this.list_videos = [];
        this.list_public = [];
        this.sections = null;
    };

    AssetScopeModel.prototype.resetState = function () {
        this.selected = null;
        this.viewing = false;
        this.uploading = false;
        this.lock_type = false;
        this.width = 0;
        this.height = 0;
        this.loaded = false;
        this.cube_faces = 0;
    };

    AssetScopeModel.prototype.resetFormInputs = function () {
        this.input = {};
        this.input.name  = null;
        this.input.type  = null;
        this.input.file  = null;
        this.input.files = null;
        this.input.filelist = null;
        this.input.duration = 0;
        this.lock_type = false;
    };

    AssetScopeModel.prototype.typeChanged = function () {
        this.input.name  = null;
        this.input.file  = null;
        this.input.files = null;
        this.input.filelist = null;
        this.input.duration = 0;
        this.input.duration_extracted = false;
    };

    AssetScopeModel.prototype.setAssetList = function (list) {
        this.list = list;
        this.list_audio  = list.filter( asset => asset.type === Asset.TYPE.AUDIO );
        this.list_images = list.filter( asset => asset.type === Asset.TYPE.IMAGE );
        this.list_models = list.filter( asset => asset.type === Asset.TYPE.MODEL );
        this.list_fonts  = list.filter( asset => asset.type === Asset.TYPE.FONT );
        this.list_videos = list.filter( asset => asset.type === Asset.TYPE.VIDEO );
        this.list_cubes  = list.filter( asset => asset.type === AssetGroup.TYPE.CUBE );
        this.sections = [
            { sublist: this.list_audio,   title: 'Audio'},
            { sublist: this.list_images,  title: 'Images'},
            { sublist: this.list_models,  title: '3D Models'},
            { sublist: this.list_cubes,   title: 'Cube Maps'},
            { sublist: this.list_fonts,   title: 'Fonts'},
            { sublist: this.list_videos,  title: 'Videos'}
        ];
    };

    AssetScopeModel.prototype.setPublicAssetList = function (list) {
        this.list_public = list;
    };

    AssetScopeModel.prototype.uploadingAsset = function( ) {
        let placeholder = new Asset(null, this.input.type, this.input.name);
        this.viewing = true;
        this.uploading = true;
        this.selected = placeholder;
    };

    AssetScopeModel.prototype.selectAsset = function( asset ) {
        let clone = asset.is_group
            ? AssetGroup.fromObject(asset.toObject())
            : Asset.fromObject(asset.toObject());
        this.viewing = true;
        this.uploading = false;
        this.selected = clone;
    };

    AssetScopeModel.prototype.deselectAsset = function(){
        this.viewing = false;
        this.uploading = false;
        this.selected = null;
        this.uploading = false;
        this.width = 0;
        this.height = 0;
        this.cube_faces = 0;
        this.loaded = false;
        this.image = null;
        this.images = [];
        this.resetFormInputs();
    };

    AssetScopeModel.prototype.extractAssetName = function(filename) {
        let name, proper, parts = filename.split('.');
        parts.splice(-1,1); // remove file extension
        name = parts.join(' ').replace(/_|-/g, ' '); // replace separators with spaces
        parts = name.split(' '); // split into words
        proper = parts.map( (element) => element.charAt(0).toUpperCase() + element.slice(1) ); // capitalize
        return proper.join(' ');
    };

    AssetScopeModel.prototype.imageLoaded = function(image, isCube) {
        if (isCube) {
            this.cube_faces++;
            this.loaded = this.cube_faces === 6;
        } else {
            this.height = image.height;
            this.width = image.width;
            this.loaded = true;
        }
    };

    AssetScopeModel.prototype.modelLoaded = function() {
        this.loaded = true;
    };

    AssetScopeModel.prototype.fontLoaded = function() {
        this.loaded = true;
    };

    AssetScopeModel.prototype.isImage = function(asset) {
        return asset && asset.type === Asset.TYPE.IMAGE;
    };

    AssetScopeModel.prototype.isCube = function(asset) {
        return asset && asset.type === AssetGroup.TYPE.CUBE;
    };

    AssetScopeModel.prototype.isAudio = function(asset) {
        return asset && asset.type === Asset.TYPE.AUDIO;
    };

    AssetScopeModel.prototype.isModel = function(asset) {
        return asset && asset.type === Asset.TYPE.MODEL;
    };

    AssetScopeModel.prototype.isFont = function(asset) {
        return asset && asset.type === Asset.TYPE.FONT;
    };

    AssetScopeModel.prototype.getCubeFilenames = function(asset) {
        return asset.assets.reduce( (prev, asset) => prev ? prev += ', ' + asset.filename : asset.filename, '' );
    };

    AssetScopeModel.prototype.getAudioAssets = function() {
        return this.getAssetsByType(Asset.TYPE.AUDIO);
    };

    AssetScopeModel.prototype.getAssetsByType = function( type ) {
        let uniqIds = {};
        return this.list
            .concat( this.list_public )             // combine private and public lists
            .filter( asset => asset.type === type ) // get all assets of type
            .filter( asset => !uniqIds[asset.id] && (uniqIds[asset.id] = true)); // make unique
    };

    AssetScopeModel.prototype.getTotalAssetStorage = function(list) {
        let sizeInBytes = list.reduce( (sum, asset) => {
            return sum + ((asset.is_group) ? asset.getSize() : asset.size);
        }, 0);
        return this.formatBytes(sizeInBytes);
    };

    AssetScopeModel.prototype.formatBytes = function (bytes,decimals) {
        if(bytes == 0) return '0 Bytes';
        let k = 1000,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

})();
