/**
 * Sinewav3 Schema Entity: Location
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Location = (function() {

        /**
         * Constructor
         * @param street
         * @param city
         * @param state
         * @param postcode
         * @param country
         * @constructor
         */
       function Location(street, city, state, postcode, country){
            this.street = street;
            this.city = city;
            this.state = state;
            this.postcode = postcode;
            this.country = country;
        }

        /**
         * Get a new Location instance from a database representation
         * @param o
         * @returns {Location}
         */
        Location.fromObject = function(o) {
            return new Location(o.street, o.city, o.state, o.postcode, o.country);
        };

        /**
         * Get a database representation of this Location instance
         * @returns {object}
         */
        Location.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Location instance
         * @returns {boolean}
         */
        Location.prototype.toString = function() {
            return [
                this.street,
                this.city,
                this.state,
                this.postcode,
                this.country
            ].join(', ');
        };

        /**
         * Is this Location instance's street field valid?
         * @returns {boolean}
         */
        Location.prototype.streetIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.street !== 'undefined' &&
                    this.street != null
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Location instance's city field valid?
         * @returns {boolean}
         */
        Location.prototype.cityIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.city !== 'undefined' &&
                    this.city != null
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Location instance's state field valid?
         * @returns {boolean}
         */
        Location.prototype.stateIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.state !== 'undefined' &&
                    this.state != null
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Location instance's postcode field valid?
         * @returns {boolean}
         */
        Location.prototype.postcodeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.postcode !== 'undefined' &&
                    this.postcode != null
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Location instance's postcode field valid?
         * @returns {boolean}
         */
        Location.prototype.countryIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.country !== 'undefined' &&
                    this.country != null
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Location instance valid?
         * @returns {boolean}
         */
        Location.prototype.isValid = function() {
            return (
                this.streetIsValid() &&
                this.cityIsValid() &&
                this.stateIsValid() &&
                this.postcodeIsValid() &&
                this.countryIsValid()
            );
        };

        return Location;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Location;
    } else {
        window.Location = Location;
    }

})();