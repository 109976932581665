/**
 * Sinewav3 Schema Entity: ProjectShare
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var ProjectShare = (function() {

        /**
         * Constructor
         * @param project_id
         * @param alias
         * @param views
         * @constructor
         */
        function ProjectShare(project_id, alias, views) {
            this.project_id = project_id;
            this.alias = alias;
            this.views = views || 0;
        }

        /**
         * Get a new ProjectShare instance from a database representation
         * @param o
         * @returns {ProjectShare}
         */
        ProjectShare.fromObject = function(o) {
            return new ProjectShare(o.project_id, o.alias, o.views);
        };

        /**
         * Get a database representation of this ProjectShare instance
         * @returns {object}
         */
        ProjectShare.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this ProjectShare instance
         * @returns {string}
         */
        ProjectShare.prototype.toString = function() {
            return this.project_id +
            this.alias +
            this.viewsIsValid() ? this.views.toString() : "";
        };

        /**
         * Is this ProjectShare instance's project_id field valid?
         * @returns {boolean}
         */
        ProjectShare.prototype.projectIdIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.project_id !== null &&
                    typeof this.project_id !== 'undefined' &&
                    typeof this.project_id === 'string' &&
                    this.project_id.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectShare instance's alias field valid?
         * @returns {boolean}
         */
        ProjectShare.prototype.aliasIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.alias !== null &&
                    typeof this.alias !== 'undefined' &&
                    typeof this.alias === 'string' &&
                    this.alias.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectShare instance's views field valid?
         * @returns {boolean}
         */
        ProjectShare.prototype.viewsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.views !== null &&
                    typeof this.views !== 'undefined' &&
                    typeof this.views === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectShare instance valid?
         * @returns {boolean|*}
         */
        ProjectShare.prototype.isValid = function() {
            return (
                this.projectIdIsValid() &&
                this.aliasIsValid() &&
                this.viewsIsValid()
            );
        };

        return ProjectShare;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = ProjectShare;
    } else {
        window.ProjectShare = ProjectShare;
    }

})();