(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'loopControl',
            [
                'CHROME',
                LoopControlFactory
            ]
        );

    // Factory Method
    function LoopControlFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            templateUrl: CHROME.PREFIX + CHROME.PROJECT.CONTROL.LOOP + CHROME.POSTFIX
        };

    }
})();



