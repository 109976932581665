// Shell Controller
(function (){

    angular.module('Sinewav3.Client.Shell')

        .controller(
            'AccountController',
            [
                '$rootScope',
                '$scope',
                'AuthService',
                'ToastService',
                'AccountService',
                'DatabaseService',
                'BroadcastService',
                'EVENTS',
                'MODES',
                'SECTIONS',
                'AUTH_SERVICE_RESPONSES',
                AccountController
            ]
        );

    // Constructor
    function AccountController($rootScope,
                               $scope,
                               AuthService,
                               ToastService,
                               AccountService,
                               DatabaseService,
                               BroadcastService,
                               EVENTS,
                               MODES,
                               SECTIONS,
                               AUTH_SERVICE_RESPONSES
    ) {

        let instance = this;
        instance.onDestroy = onDestroy;

        instance.createUser = createUser;
        instance.onCreateUserResponse = onCreateUserResponse;
        instance.signIn = signIn;
        instance.signInWithOAuth = signInWithOAuth;
        instance.signOut = signOut;
        instance.sendResetEmail = sendResetEmail;
        instance.onSendResetEmailResponse = onSendResetEmailResponse;
        instance.changePassword = changePassword;
        instance.onChangePasswordResponse = onChangePasswordResponse;
        instance.sendResetEmail = sendResetEmail;
        instance.clearForm = clearForm;

        initialize();

        // Private initialization function
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.CREATE_USER.SUCCESS, instance.onCreateUserResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.CREATE_USER.FAILURE, instance.onCreateUserResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.RESET_PW.SUCCESS, instance.onSendResetEmailResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.RESET_PW.FAILURE, instance.onSendResetEmailResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.CHANGE_PW.SUCCESS, instance.onChangePasswordResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.CHANGE_PW.FAILURE, instance.onChangePasswordResponse ) );

            // Set form validation functions on the $scope
            $scope.signupFormIsValid = $scope.changePassFormIsValid = function (form) {
                return form.$valid && $rootScope.account.input.password === $rootScope.account.input.confirm;
            };

            // Handle the selection of a profile image to be cropped
            $scope.handleImageFileSelect = function( files ) {
                let account = $rootScope.account;
                let file = files[0];

                // Use blueimp loadImage which fixes exif orientation problem
                loadImage(
                    file,
                    function(canvas) {
                        $scope.$apply( function( $scope ) {
                            account.input.image.raw = canvas.toDataURL();
                        });
                    },
                    {  orientation: true }
                );

            };

            // Hide the FAB button
            BroadcastService.send(EVENTS.HIDE_FAB);
        }

        /**
         * Remove event listeners when the controller is destroyed
         */
        function onDestroy() {
            let i, removeListener;
            for (i = 0; i < $scope.listenerCleanup.length; i++) {
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
        }

        /**
         * Create a new user account
         */
        function createUser() {
            let account = $rootScope.account;
            account.signing_up = true;
            AuthService.createUser(account.input.email, account.input.password);
        }

        /**
         * Handle the CREATE_USER response
         * @param event
         * @param data
         */
        function onCreateUserResponse(event, data) {
            switch (event.name) {
                case AUTH_SERVICE_RESPONSES.CREATE_USER.SUCCESS:
                    let user = data;
                    AccountService.createProfile(user);
                    break;

                case AUTH_SERVICE_RESPONSES.CREATE_USER.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Sign the user in with email/password
         */
        function signIn() {
            let account = $rootScope.account;
            account.manual_sign_in = true;
            AuthService.signInWithPassword(account.input.email, account.input.password);
        }

        /**
         * Sign the user out
         */
        function signOut() {
            DatabaseService.unlistenAll();
            AuthService.signOut();
        }

        /**
         * Send the user a password reset email
         */
        function sendResetEmail() {
            let account = $rootScope.account;
            AuthService.sendPasswordResetEmail(account.input.email);
        }

        /**
         * Handle the CREATE_USER response
         * @param event
         * @param data
         */
        function onSendResetEmailResponse(event, data) {
            switch (event.name) {
                case AUTH_SERVICE_RESPONSES.RESET_PW.SUCCESS:
                    ToastService.showToast('Password reset email sent!');
                    $rootScope.account.resetPasswordInputs();
                    BroadcastService.send(EVENTS.NAV_TO, SECTIONS.LANDING.SIGN_IN);
                    break;

                case AUTH_SERVICE_RESPONSES.RESET_PW.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Change password
         */
        function changePassword() {
            let account = $rootScope.account;
            AuthService.changePassword(account.input.password);
        }

        /**
         * Handle the CHANGE_PW response
         * @param event
         * @param data
         */
        function onChangePasswordResponse(event, data) {
            switch (event.name) {
                case AUTH_SERVICE_RESPONSES.CHANGE_PW.SUCCESS:
                    $rootScope.account.resetPasswordInputs();
                    ToastService.showToast('Password changed!');
                    BroadcastService.send(EVENTS.NAV_TO, SECTIONS.ACCOUNT.PROFILE_MANAGE);
                    break;

                case AUTH_SERVICE_RESPONSES.CHANGE_PW.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Sign the user in with an OAuth provider
         * @param provider
         */
        function signInWithOAuth(provider) {
            let account = $rootScope.account;
            account.reset();
            account.manual_sign_in = true;
            account.signing_in_with_social = true;
            AuthService.signInWithSocial(provider);
        }


        /**
         * Clear the sign-in or sign-up form
         */
        function clearForm( form ) {
            let account = $rootScope.account;
            account.resetFormInputs();
            form.$setPristine();
            form.$setUntouched();
        }

    }
})();
