(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'modulatorControl',
            [
                'CHROME',
                ModulatorControlFactory
            ]
        );

    // Factory Method
    function ModulatorControlFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            templateUrl: CHROME.PREFIX + CHROME.PROJECT.CONTROL.MODULATOR + CHROME.POSTFIX
        };

    }
})();



