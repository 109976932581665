/**
 * Sinewav3 Schema Entity: AudioAnalysis
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var AudioAnalysis = (function() {

        /**
         * Constructor
         * @param audio
         * @param frame_rate
         * @param frames
         * @constructor
         */
        function AudioAnalysis(audio, frame_rate, frames) {
            this.audio = audio;
            this.frame_rate = frame_rate;
            this.frames = frames;
        }

        /**
         * AudioAnalysis Frame Rate Constants
         * @type {{FPS_60: number, FPS_48: number, FPS_30: number}}
         */
        AudioAnalysis.FRAME_RATE = {
            FPS_60: 60,
            FPS_48: 48,
            FPS_30: 30
        };

        /**
         * Get a new AudioAnalysis instance from a database representation
         * @param o
         * @returns {AudioAnalysis}
         */
        AudioAnalysis.fromObject = function(o) {
            var audio = o.audio ? Asset.fromObject(o.audio) : null;
            var frames = o.frames ? o.frames.map(frame => AudioFrame.fromObject(frame)) : null;
            return new AudioAnalysis(audio, o.frame_rate, frames);
        };

        /**
         * Get a database representation of this AudioAnalysis instance
         * @returns {object}
         */
        AudioAnalysis.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this AudioAnalysis instance
         * @returns {string}
         */
        AudioAnalysis.prototype.toString = function() {
            return [
                this.audio.toString(),
                this.frame_rate,
                this.frames ? this.frames.reduce( (prev, frame) => prev + (prev ? ", ": "") + frame.toString() ) : ""
            ].join(' ');
        };

        /**
         * Is this AudioAnalysis instance's name field valid?
         * @returns {boolean}
         */
        AudioAnalysis.prototype.audioIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.audio  !== 'undefined' && this.audio !== null &&
                    Object.getPrototypeOf(this.audio) === Asset.prototype &&
                    this.audio.isAudio() &&
                    this.audio.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioAnalysis instance's frame_rate field valid?
         * @returns {boolean|*}
         */
        AudioAnalysis.prototype.frameRateIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.frame_rate !== 'undefined' &&
                    typeof this.frame_rate === 'number' &&
                    (
                        this.frame_rate === AudioAnalysis.FRAME_RATE.FPS_30 ||
                        this.frame_rate === AudioAnalysis.FRAME_RATE.FPS_48 ||
                        this.frame_rate === AudioAnalysis.FRAME_RATE.FPS_60
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioAnalysis instance's frames field valid?
         * @returns {boolean|*}
         */
        AudioAnalysis.prototype.framesIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.frames !== null &&
                    typeof this.frames !== 'undefined' &&
                    Array.isArray(this.frames) &&
                    this.frames.length > 0 &&
                    this.frames.reduce( (prev, frame) => prev && frame.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this AudioAnalysis instance valid?
         * @returns {boolean|*}
         */
        AudioAnalysis.prototype.isValid = function() {
            return (
                this.audioIsValid() &&
                this.frameRateIsValid() &&
                this.framesIsValid()
            );
        };

        return AudioAnalysis;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = AudioAnalysis;
    } else {
        window.AudioAnalysis = AudioAnalysis;
    }

})();