/**
 * Sinewav3 Schema Entity: Timeline
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Timeline = (function() {

        function Timeline(offset, events) {
            this.offset = offset || 0;
            this.events = events || [];
        }

        /**
         * Get a new Timeline instance from a database representation
         * @param o
         * @returns {Timeline}
         */
        Timeline.fromObject = function(o) {
            var events = o.events ? o.events.map(event => TimelineEvent.fromObject(event)) : null;
            return new Timeline(o.offset, events);
        };

        /**
         * Get a database representation of this Timeline instance
         * @returns {object}
         */
        Timeline.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Timeline instance
         * @returns {string}
         */
        Timeline.prototype.toString = function() {
            return [
                this.offset,
                this.eventsIsValid() ? this.events.reduce( (prev, event) => prev + (prev ? ", ": "") + event.toString() ) : ""
            ].join(' ');
        };

        /**
         * Is this Timeline instance's offset field valid?
         * @returns {boolean|*}
         */
        Timeline.prototype.offsetIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.offset !== null &&
                    typeof this.offset !== 'undefined' &&
                    typeof this.offset === 'number' &&
                    this.offset >= 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Timeline instance's events field valid?
         * @returns {boolean|*}
         */
        Timeline.prototype.eventsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.events !== null &&
                    typeof this.events !== 'undefined' &&
                    Array.isArray(this.events) &&
                    this.events.reduce( (prev, event) => prev && event.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Timeline instance valid?
         * @returns {boolean|*}
         */
        Timeline.prototype.isValid = function() {
            return (
                this.offsetIsValid() &&
                this.eventsIsValid()
            );
        };

        return Timeline;



    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Timeline;
    } else {
        window.Timeline = Timeline;
    }

})();