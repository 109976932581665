(function() {

    // Add the RouteHandler configuration to the module
    angular.module('Sinewav3.Client.Shell')

        // Initialize the routes
        .config([
            '$routeProvider',
            'SECTIONS',
            RouteHandler
        ]);

    function RouteHandler($routeProvider, SECTIONS)
    {

        // The sections for which there are routes
        let sections = [SECTIONS.ACCOUNT, SECTIONS.ADMIN, SECTIONS.LANDING];

        // Add each route in the defined sections
        for (let section of sections) {
            for (let route of Object.getOwnPropertyNames(section)) {
                let page = section[route];
                let url = build(page);
                $routeProvider.when(
                    page,
                    {
                        templateUrl: url
                    }
                );
            }
        }

        // The default route
        $routeProvider.otherwise( {redirectTo: SECTIONS.LANDING.HOME} );

        // Create the relative URL for the page from the route
        function build(page){
            return SECTIONS.PREFIX + page + SECTIONS.POSTFIX;
        }

    }

})();