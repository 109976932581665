/**
 * Publish Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        .factory(
            'PublishService',
            [
                'DatabaseService',
                PublishServiceFactory
            ]
        );

    // Factory Method
    function PublishServiceFactory( DatabaseService )
    {

        let instance = {};

        instance.sendPublishRequest = sendPublishRequest;
        instance.approveItem = approveItem;
        instance.rejectItem = rejectItem;

        return instance;

        /**
         * Send a publish request (either a submission or a cancellation)
         * @param request
         * @returns {*} Promise
         */
        function sendPublishRequest(request) {

            // SAVE PUBLISH REQUEST, RETURN PROMISE
            let entity = DatabaseService.stripAngularProps(request).toObject();
            return DatabaseService.getPublishReqsRef().child(request.item_id).set(entity);

        }

        /**
         * Approve a publish request
         * @param item_id
         * @returns {*} Promise
         */
        function approveItem(item_id) {
            DatabaseService.getPublishReqsRef()
                .child(item_id)
                .once('value')
                .then( snapshot => {
                    let request = PublishRequest.fromObject( snapshot.val() );
                    request.status = PublishRequest.STATUS.APPROVED;
                    instance.sendPublishRequest(request);
                });
        }

        /**
         * Reject a publish request
         * @param request
         * @returns {*} Promise
         */
        function rejectItem(item_id, reason) {
            DatabaseService.getPublishReqsRef()
                .child(item_id)
                .once('value')
                .then( snapshot => {
                    let request = PublishRequest.fromObject( snapshot.val() );
                    request.status = PublishRequest.STATUS.REJECTED;
                    request.reject_reason = reason;
                    instance.sendPublishRequest(request);
                });
        }
    }
})(); // IIFE keeps global scope clean