/**
 * Project Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // PROJECT SERVICE RESPONSES
        .constant('PROJECT_SERVICE_RESPONSES', {
            LIST_PROJECTS: {
                SUCCESS: 'project-list-success',
                FAILURE: 'project-list-error'
            },
            CREATE_PROJECT: {
                SUCCESS: 'project-create-success',
                FAILURE: 'project-create-error'
            },
            COPY_PROJECT: {
                SUCCESS: 'project-copy-success',
                FAILURE: 'project-copy-error'
            },
            UPDATE_PROJECT: {
                SUCCESS: 'project-update-success',
                FAILURE: 'project-update--error'
            },
            DELETE_PROJECT: {
                SUCCESS: 'project-delete-success',
                FAILURE: 'project-delete-error'
            },
            LOAD_PROJECT: {
                SUCCESS: 'project-load-success',
                FAILURE: 'project-load--error'
            }
        })

        .factory(
            'ProjectService',
            [
                'BroadcastService',
                'DatabaseService',
                'PROJECT_SERVICE_RESPONSES',
                ProjectServiceFactory
            ]
        );

    // Factory Method
    function ProjectServiceFactory(BroadcastService,
                                   DatabaseService,
                                   PROJECT_SERVICE_RESPONSES)
    {
        let instance = {};

        instance.listProjects = listProjects;
        instance.createProject = createProject;
        instance.copyProject = copyProject;
        instance.updateProject = updateProject;
        instance.deleteProject = deleteProject;
        instance.submitTemplate = submitTemplate;
        instance.cancelSubmission = cancelSubmission;
        instance.loadProject = loadProject;

        return instance;

        /**
         * Retrieve the list of projects
         * Automatically receives updates when projects are added or removed
         * @param uid
         */
        function listProjects(uid)
        {
            // Listen for changes to the user's projects
            DatabaseService.getProjectsRef()
                .orderByChild('owner/uid')
                .equalTo(uid)
                .on('value', onChange, onCancel);

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let project, projects = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        project = Project.fromObject(childSnapshot.val());
                        projects.push(project);
                    }
                );

                BroadcastService.send(PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.SUCCESS, projects);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.FAILURE, error);
            }

        }

        /**
         * Create a project
         * @param title
         * @param userToken
         */
        function createProject(title, userToken)
        {
            // CREATE NEW PROJECT NODE
            let id = DatabaseService.getProjectsRef().push().key;

            // PREPARE PROJECT
            let project = new Project(id, title, userToken);

            // SAVE PROJECT
            DatabaseService.getProjectsRef().child(id).set(project.toObject(), onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.FAILURE, error);
                } else {
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.CREATE_PROJECT.SUCCESS, project);
                }
            }
        }

        /**
         * Copy a project
         * @param project
         */
        function copyProject(project)
        {
            // CREATE NEW PROJECT NODE
            let id = DatabaseService.getProjectsRef().push().key;

            // PREPARE PROJECT
            let entity = DatabaseService.stripAngularProps(project).toObject();
            entity.id = id;
            entity.name += " copy";

            // SAVE PROJECT
            DatabaseService.getProjectsRef().child(id).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.COPY_PROJECT.FAILURE, error);
                } else {
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.COPY_PROJECT.SUCCESS);
                }
            }
        }

        /**
         * Save changes to a project
         * @param project
         */
        function updateProject(project)
        {
            // SAVE PROJECT
            let entity = DatabaseService.stripAngularProps(project).toObject();
            DatabaseService.getProjectsRef().child(project.id).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.FAILURE, error);
                } else {
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.UPDATE_PROJECT.SUCCESS);
                }
            }
        }

        /**
         * Delete a project
         * @param project
         */
        function deleteProject(project)
        {
            // DELETE PROJECT
            DatabaseService.getProjectsRef().child(project.id).remove(onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.FAILURE, error);
                } else {
                    BroadcastService.send(PROJECT_SERVICE_RESPONSES.DELETE_PROJECT.SUCCESS);
                }
            }
        }

        /**
         * Submit a project template for review
         * @param project
         */
        function submitTemplate(project)
        {
            project.status = Project.STATUS.SUBMITTED;
            let entity = DatabaseService.stripAngularProps(project);
            instance.updateProject(entity);
        }

        /**
         * Cancel a project template submission
         * @param project
         */
        function cancelSubmission(project)
        {
            project.status = Project.STATUS.DEVELOPMENT;
            project.is_public = false;
            let entity = DatabaseService.stripAngularProps(project);
            instance.updateProject(entity);
        }

        /**
         * Load a project (as yet unused)
         * @param project id
         */
        function loadProject(id)
        {
            // LOAD
            DatabaseService.getProjectsRef().child(id).once('value').then(success).catch(failure);

            // SUCCESS
            function success(snapshot) {
                let project = Project.fromObject(snapshot.val());
                BroadcastService.send(PROJECT_SERVICE_RESPONSES.LOAD_PROJECT.SUCCESS, project);
            }

            // FAILURE
            function failure(error) {
                BroadcastService.send(PROJECT_SERVICE_RESPONSES.LOAD_PROJECT.FAILURE, error);
            }
        }

    }
})(); // IIFE keeps global scope clean