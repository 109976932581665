// Shell - Account Scope
(function (){

    angular.module('Sinewav3.Client.Shell')

        .constant( 'STATES', [
            {
                "name": "Alabama",
                "abbreviation": "AL"
            },
            {
                "name": "Alaska",
                "abbreviation": "AK"
            },
            {
                "name": "Arizona",
                "abbreviation": "AZ"
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR"
            },
            {
                "name": "California",
                "abbreviation": "CA"
            },
            {
                "name": "Colorado",
                "abbreviation": "CO"
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT"
            },
            {
                "name": "Delaware",
                "abbreviation": "DE"
            },
            {
                "name": "District Of Columbia",
                "abbreviation": "DC"
            },
            {
                "name": "Florida",
                "abbreviation": "FL"
            },
            {
                "name": "Georgia",
                "abbreviation": "GA"
            },
            {
                "name": "Guam",
                "abbreviation": "GU"
            },
            {
                "name": "Hawaii",
                "abbreviation": "HI"
            },
            {
                "name": "Idaho",
                "abbreviation": "ID"
            },
            {
                "name": "Illinois",
                "abbreviation": "IL"
            },
            {
                "name": "Indiana",
                "abbreviation": "IN"
            },
            {
                "name": "Iowa",
                "abbreviation": "IA"
            },
            {
                "name": "Kansas",
                "abbreviation": "KS"
            },
            {
                "name": "Kentucky",
                "abbreviation": "KY"
            },
            {
                "name": "Louisiana",
                "abbreviation": "LA"
            },
            {
                "name": "Maine",
                "abbreviation": "ME"
            },
            {
                "name": "Maryland",
                "abbreviation": "MD"
            },
            {
                "name": "Massachusetts",
                "abbreviation": "MA"
            },
            {
                "name": "Michigan",
                "abbreviation": "MI"
            },
            {
                "name": "Minnesota",
                "abbreviation": "MN"
            },
            {
                "name": "Mississippi",
                "abbreviation": "MS"
            },
            {
                "name": "Missouri",
                "abbreviation": "MO"
            },
            {
                "name": "Montana",
                "abbreviation": "MT"
            },
            {
                "name": "Nebraska",
                "abbreviation": "NE"
            },
            {
                "name": "Nevada",
                "abbreviation": "NV"
            },
            {
                "name": "New Hampshire",
                "abbreviation": "NH"
            },
            {
                "name": "New Jersey",
                "abbreviation": "NJ"
            },
            {
                "name": "New Mexico",
                "abbreviation": "NM"
            },
            {
                "name": "New York",
                "abbreviation": "NY"
            },
            {
                "name": "North Carolina",
                "abbreviation": "NC"
            },
            {
                "name": "North Dakota",
                "abbreviation": "ND"
            },
            {
                "name": "Ohio",
                "abbreviation": "OH"
            },
            {
                "name": "Oklahoma",
                "abbreviation": "OK"
            },
            {
                "name": "Oregon",
                "abbreviation": "OR"
            },
            {
                "name": "Pennsylvania",
                "abbreviation": "PA"
            },
            {
                "name": "Rhode Island",
                "abbreviation": "RI"
            },
            {
                "name": "South Carolina",
                "abbreviation": "SC"
            },
            {
                "name": "South Dakota",
                "abbreviation": "SD"
            },
            {
                "name": "Tennessee",
                "abbreviation": "TN"
            },
            {
                "name": "Texas",
                "abbreviation": "TX"
            },
            {
                "name": "Utah",
                "abbreviation": "UT"
            },
            {
                "name": "Vermont",
                "abbreviation": "VT"
            },
            {
                "name": "Virgin Islands",
                "abbreviation": "VI"
            },
            {
                "name": "Virginia",
                "abbreviation": "VA"
            },
            {
                "name": "Washington",
                "abbreviation": "WA"
            },
            {
                "name": "West Virginia",
                "abbreviation": "WV"
            },
            {
                "name": "Wisconsin",
                "abbreviation": "WI"
            },
            {
                "name": "Wyoming",
                "abbreviation": "WY"
            }
        ] )

        .run( [ '$rootScope', 'STATES', ($rootScope, STATES) => $rootScope.account = new AccountScopeModel( STATES ) ] );

    /**
     * Account Scope Model
     * @constructor
     */
    function AccountScopeModel( STATES ) {

        // Injected
        this.STATES = STATES;

        // Initialize model, state, and form inputs
        this.reset();

    }

    /**
     * Reset the model, state and form inputs
     */
    AccountScopeModel.prototype.reset = function () {

        // Data model
        this.resetModel();

        // State
        this.resetState();

        // Build form input models
        this.resetFormInputs();

    };

    /**
     * Reset the model
     */
    AccountScopeModel.prototype.resetModel = function () {
        this.uid = null;
        this.profile = null;
    };

    /**
     * Reset the state
     */
    AccountScopeModel.prototype.resetState = function () {
        this.signing_up = false;
        this.signing_in_with_social = false;
        this.manual_sign_in = false;
        this.editing = false;
        this.editing_photo = false;

    };

    /**
     * Reset the form inputs
     */
    AccountScopeModel.prototype.resetFormInputs = function () {
        this.input = {};
        this.input.email      = null;
        this.input.password   = null;
        this.input.confirm    = null;
        this.input.profile    = null;
        this.input.use_real_name   = true;
        this.resetProfilePhotoInput();
    };

    /**
     * Reset the profile photo inputs
     * @param profile
     */
    AccountScopeModel.prototype.resetProfilePhotoInput = function () {
        this.input.image = { raw: null, crop: null };
        this.editing_photo = false;
    };

    /**
     *  Reset the password inputs
     */
    AccountScopeModel.prototype.resetPasswordInputs = function () {
        this.input.password = null;
        this.input.confirm = null;
    };

    /**
     *  Has account sign in/up inputs
     */
    AccountScopeModel.prototype.hasAccountInputs = function () {
        return this.input && (this.input.password || this.input.confirm || this.input.email);
    };

    /**
     * Set the user profile
     * @param profile
     */
    AccountScopeModel.prototype.setUserProfile = function ( profile ) {
        this.profile = profile;
        this.input.profile = Profile.fromObject(profile.toObject());
        this.editing = false;
    };

    /**
     * Edit the user profile
     * @param profile
     */
    AccountScopeModel.prototype.editProfile = function () {
        this.input.profile = Profile.fromObject( this.profile.toObject() );
        this.input.use_real_name = this.profile.name.display === [ this.profile.name.first, this.profile.name.last ].join(' ');
        this.editing = true;
    };

    /**
     * Edit the user profile
     * @param profile
     */
    AccountScopeModel.prototype.editProfilePhoto = function () {
        this.editing_photo = true;
    };

    /**
     * Abandon edits to the user profile
     * @param profile
     */
    AccountScopeModel.prototype.abandonEditing = function () {
        this.editing = false;
        this.input.profile = null;
        this.resetProfilePhotoInput();
    };

    /**
     * Has the user profile changed?
     */
    AccountScopeModel.prototype.profileHasChanged = function () {
        let a = angular.toJson(this.profile.toObject());
        let b = angular.toJson(this.input.profile.toObject());
        return !(a === b);
    };

    /**
     * Generate the display name for a user from the first and last names
     */
    AccountScopeModel.prototype.generateDisplayName = function (erase) {
        let name = this.input.profile.name;
        if (this.input.use_real_name){
            name.display = [name.first, name.last].join(' ').trim();
        } else if (erase) {
            name.display = "";
        }
    };

})();
