/**
 * Plugin Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // PLUGIN SERVICE RESPONSES
        .constant('PLUGIN_SERVICE_RESPONSES', {
            LIST_PLUGINS: {
                SUCCESS: 'plugin-list-success',
                FAILURE: 'plugin-list-error'
            },
            LIST_PUBLIC_PLUGINS: {
                SUCCESS: 'plugin-list-public-success',
                FAILURE: 'plugin-list-public-error'
            },
            LIST_SUBMITTED_PLUGINS: {
                SUCCESS: 'plugin-list-submitted-success',
                FAILURE: 'plugin-list-submitted-error'
            },
            CREATE_PLUGIN: {
                SUCCESS: 'plugin-create-success',
                FAILURE: 'plugin-create-error'
            },
            COPY_PLUGIN: {
                SUCCESS: 'plugin-copy-success',
                FAILURE: 'plugin-copy-error'
            },
            UPDATE_PLUGIN: {
                SUCCESS: 'plugin-update-success',
                FAILURE: 'plugin-update--error'
            },
            DELETE_PLUGIN: {
                SUCCESS: 'plugin-delete-success',
                FAILURE: 'plugin-delete-error'
            },
            SUBMIT_PLUGIN: {
                SUCCESS: 'plugin-submit-success',
                FAILURE: 'plugin-submit-error'
            },
            CANCEL_PLUGIN: {
                SUCCESS: 'plugin-cancel-success',
                FAILURE: 'plugin-cancel-error'
            },
            LOAD_PLUGIN: {
                SUCCESS: 'plugin-load-success',
                FAILURE: 'plugin-load--error'
            }
        })

        .factory(
            'PluginService',
            [
                'BroadcastService',
                'DatabaseService',
                'PublishService',
                'PLUGIN_SERVICE_RESPONSES',
                PluginServiceFactory
            ]
        );

    // Factory Method
    function PluginServiceFactory(BroadcastService,
                                  DatabaseService,
                                  PublishService,
                                  PLUGIN_SERVICE_RESPONSES)
    {
        let instance = {};

        instance.listPlugins = listPlugins;
        instance.listPublicPlugins = listPublicPlugins;
        instance.listSubmittedPlugins = listSubmittedPlugins;
        instance.createPlugin = createPlugin;
        instance.copyPlugin = copyPlugin;
        instance.updatePlugin = updatePlugin;
        instance.deletePlugin = deletePlugin;
        instance.submitPlugin = submitPlugin;
        instance.cancelSubmission =  cancelSubmission;
        instance.sendPublishRequest = sendPublishRequest;
        instance.loadPlugin = loadPlugin; // as yet unused

        return instance;

        /**
         * Retrieve the list of plugins
         * Automatically receives updates when plugins are added or removed
         * @param uid
         */
        function listPlugins(uid) {
            // Listen for changes to the user's plugins
            DatabaseService.getPluginsRef()
                .orderByChild('author/uid')
                .equalTo(uid)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let plugin, plugins = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        plugin = Plugin.fromObject(childSnapshot.val());
                        plugins.push(plugin);
                    }
                );

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.SUCCESS, plugins);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.FAILURE, error);
            }
        }

        /**
         * Retrieve the list of public plugins
         * Automatically receives updates when plugins are added or removed
         */
        function listPublicPlugins() {
            // Listen for changes to the public plugins
            DatabaseService.getPluginsRef()
                .orderByChild('is_public')
                .equalTo(true)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let plugin, plugins = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        plugin = Plugin.fromObject(childSnapshot.val());
                        plugins.push(plugin);
                    }
                );

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.SUCCESS, plugins);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.FAILURE, error);
            }
        }

        /**
         * Retrieve the list of submitted plugins
         * Automatically receives updates when plugins are added or removed
         */
        function listSubmittedPlugins() {
            // Listen for changes to the public plugins
            DatabaseService.getPluginsRef()
                .orderByChild('status')
                .equalTo(Plugin.STATUS.SUBMITTED)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let plugin, plugins = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        plugin = Plugin.fromObject(childSnapshot.val());
                        plugins.push(plugin);
                    }
                );

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.SUCCESS, plugins);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.FAILURE, error);
            }
        }

        /**
         * Create a plugin
         * @param name
         * @param userToken
         */
        function createPlugin(name, userToken) {
            // CREATE NEW PLUGIN NODE
            let id = DatabaseService.getPluginsRef().push().key;

            // PREPARE PLUGIN
            let plugin = new Plugin(id, name, userToken);

            // SAVE PLUGIN
            DatabaseService.getPluginsRef().child(id).set(plugin.toObject(), onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.CREATE_PLUGIN.SUCCESS, plugin);
                }
            }
        }

        /**
         * Copy a plugin
         * @param plugin
         */
        function copyPlugin(plugin, userToken) {
            // CREATE NEW PLUGIN NODE
            let id = DatabaseService.getPluginsRef().push().key;

            let clone = Plugin.fromObject(plugin.toObject());
            clone.author = userToken;

            // PREPARE PLUGIN
            let entity = DatabaseService.stripAngularProps(clone).toObject();
            entity.id = id;
            entity.name += " copy";
            entity.status = Plugin.STATUS.DEVELOPMENT;
            entity.reject_reason = null;
            entity.is_example = false;
            entity.is_public = false;

            // SAVE PLUGIN
            DatabaseService.getPluginsRef().child(id).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.COPY_PLUGIN.SUCCESS);
                }
            }
        }

        /**
         * Save changes to a plugin
         * @param plugin
         */
        function updatePlugin(plugin) {
            // SET UNSET SETTING VALUES TO SANE DEFAULTS
            if (plugin.settings) {
                plugin.settings.forEach( group => group.settings.forEach( setting => setting.value = setting.sane ) )
            }

            // UPDATE PLUGIN
            let entity = DatabaseService.stripAngularProps(plugin).toObject();
            DatabaseService.getPluginsRef().child(plugin.id).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.UPDATE_PLUGIN.SUCCESS, plugin);
                }
            }
        }

        /**
         * Delete a plugin
         * @param plugin
         */
        function deletePlugin(plugin) {
            // DELETE PLUGIN
            DatabaseService.getPluginsRef().child(plugin.id).remove(onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(PLUGIN_SERVICE_RESPONSES.DELETE_PLUGIN.SUCCESS);
                }
            }
        }

        /**
         * Submit a plugin for review
         * @param plugin
         */
        function submitPlugin(plugin) {
            plugin.status = Plugin.STATUS.SUBMITTED;
            instance.sendPublishRequest( plugin, PublishRequest.STATUS.PENDING );
        }

        /**
         * Cancel a plugin submission
         * @param plugin
         */
        function cancelSubmission(plugin) {
            plugin.status = Plugin.STATUS.DEVELOPMENT;
            plugin.is_public = false;
            plugin.is_example = false;
            instance.sendPublishRequest( plugin, PublishRequest.STATUS.CANCELED );
        }

        /**
         * Send a publish request (submission or cancellation)
         * @param plugin
         * @param status
         */
        function sendPublishRequest(plugin, status) {

            let entity = DatabaseService.stripAngularProps(plugin);

            // Create the publishing request
            let request = new PublishRequest(
                entity.author,
                entity.id,
                PublishRequest.TYPE.PLUGIN,
                entity.name,
                status,
                Date.now(),
                plugin.is_example
            );

            // Save the plugin, it's status has changed status
            PublishService.sendPublishRequest(request)
                .then( () => {
                    instance.updatePlugin(entity);
                } )
                .catch ( error => {
                    let response = (request.status === PublishRequest.PENDING )
                        ? PLUGIN_SERVICE_RESPONSES.SUBMIT_PLUGIN.FAILURE
                        : PLUGIN_SERVICE_RESPONSES.CANCEL_PLUGIN.FAILURE;

                    BroadcastService.send(response, error);
                })
        }

        /**
         * Load a plugin
         * @param plugin id
         */
        function loadPlugin(id) {
            // LOAD
            DatabaseService.getPluginsRef().child(id).once('value').then(success).catch(failure);

            // SUCCESS
            function success(snapshot) {
                let plugin = Plugin.fromObject(snapshot.val());
                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LOAD_PLUGIN.SUCCESS, plugin);
            }

            // FAILURE
            function failure(error) {
                BroadcastService.send(PLUGIN_SERVICE_RESPONSES.LOAD_PLUGIN.FAILURE, error);
            }
        }

    }
})(); // IIFE keeps global scope clean