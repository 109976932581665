(function (){

    // Create the shell
    angular.module('Sinewav3.Client.Shell',
        [
            'ngRoute',
            'ngSanitize',
            'ngMaterial',
            'ngMessages',
            'ngAnimate',
            'color.picker',
            'ngImgCrop',
            'ui.ace',
            'angularResizable'
        ])

        // Dictionary of Firebase config params
        // NOTE: gsutil cors set cors.json gs://<your-cloud-storage-bucket> to deploy cors.json.
        .constant('FIREBASE_CONFIG',
            {
                "localhost": {
                    apiKey: "AIzaSyAOZHLlaWb_YesKeeEktFaoM1CVyC7mTxY",
                    authDomain: "dev.sinewav3.com",
                    databaseURL: "https://sinewav3-dev.firebaseio.com",
                    projectId: "sinewav3-dev",
                    storageBucket: "sinewav3-dev.appspot.com",
                    messagingSenderId: "823883823041"
                },
                "dev.sinewav3.com": {
                    apiKey: "AIzaSyAOZHLlaWb_YesKeeEktFaoM1CVyC7mTxY",
                    authDomain: "dev.sinewav3.com",
                    databaseURL: "https://sinewav3-dev.firebaseio.com",
                    projectId: "sinewav3-dev",
                    storageBucket: "sinewav3-dev.appspot.com",
                    messagingSenderId: "823883823041"
                },
                "qa.sinewav3.com": {
                    apiKey: "AIzaSyAOHUMFKBV9jhejJaqq1NVE4N2bVJe2hp4",
                    authDomain: "qa.sinewav3.com",
                    databaseURL: "https://sinewav3-qa.firebaseio.com",
                    projectId: "sinewav3-qa",
                    storageBucket: "sinewav3-qa.appspot.com",
                    messagingSenderId: "292248759037"
                },
                "app.sinewav3.com": {
                    apiKey: "AIzaSyBRESQ1Lj8Sbhxab4i_zrsrrBCywPB3BbM",
                    authDomain: "app.sinewav3.com",
                    databaseURL: "https://sinewav3-app.firebaseio.com",
                    projectId: "sinewav3-app",
                    storageBucket: "sinewav3-app.appspot.com",
                    messagingSenderId: "884172069035"
                }
            }
        )

        // Database-related scope initialization
        .run([
            'FIREBASE_CONFIG',
            '$location',
            (FIREBASE_CONFIG, $location) => firebase.initializeApp(FIREBASE_CONFIG[$location.host()])
        ]);

})();
