/**
 * Like Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // LIKE SERVICE RESPONSES
        .constant('LIKE_SERVICE_RESPONSES', {
            MAP_PLUGIN_LIKES: {
                SUCCESS: 'map-plugin-likes-success',
                FAILURE: 'map-plugin-likes-error'
            },
            LIKE_PLUGIN: {
                SUCCESS: 'like-plugin-success',
                FAILURE: 'like-plugin-error'
            },
            UNLIKE_PLUGIN: {
                SUCCESS: 'unlike-plugin-success',
                FAILURE: 'unlike-plugin-error'
            }
        })

        .factory(
            'LikeService',
            [
                'BroadcastService',
                'DatabaseService',
                'LIKE_SERVICE_RESPONSES',
                LikeServiceFactory
            ]
        );

    // Factory Method
    function LikeServiceFactory(BroadcastService,
                                DatabaseService,
                                LIKE_SERVICE_RESPONSES)
    {
        let instance = {};

        instance.mapPluginLikes = mapPluginLikes;
        instance.likePlugin = likePlugin;
        instance.unlikePlugin = unlikePlugin;

        return instance;

        /**
         * Retrieve the list of plugin likes for this user and create a map by plugin id
         * Automatically receives updates when likes are added or removed in the db
         * @param uid
         */
        function mapPluginLikes(uid) {
            // Listen for changes to the user's plugins
            DatabaseService.getPluginLikesRef()
                .orderByChild('uid')
                .equalTo(uid)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let like, likes = {};
                snapshot.forEach(
                    function(childSnapshot) {
                        like = PluginLike.fromObject(childSnapshot.val());
                        likes[ like.plugin_id ] = true;
                    }
                );

                BroadcastService.send(LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.SUCCESS, likes);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.FAILURE, error);
            }
        }

        /**
         * Like a plugin
         * @param uid
         * @param plugin_id
         */
        function likePlugin(uid, plugin_id) {

            // Create like
            let like = new PluginLike(uid, plugin_id);

            // UPDATE PLUGIN
            let entity = DatabaseService.stripAngularProps(like).toObject();
            DatabaseService.getPluginLikesRef().child(like.key).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(LIKE_SERVICE_RESPONSES.LIKE_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(LIKE_SERVICE_RESPONSES.LIKE_PLUGIN.SUCCESS, like);
                }
            }
        }

        /**
         * Unlike a plugin
         * @param uid
         * @param plugin_id
         */
        function unlikePlugin(uid, plugin_id) {

            // REMOVE PLUGIN LIKE
            let like = new PluginLike(uid, plugin_id);
            DatabaseService.getPluginLikesRef().child(like.key).remove(onComplete);

            // UPDATE PLUG
            // ANNOUNCE
            function onComplete(error){
                if (error){
                    BroadcastService.send(LIKE_SERVICE_RESPONSES.UNLIKE_PLUGIN.FAILURE, error);
                } else {
                    BroadcastService.send(LIKE_SERVICE_RESPONSES.UNLIKE_PLUGIN.SUCCESS, like);
                }
            }
        }
    }
})(); // IIFE keeps global scope clean