(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'script', ScriptFactory
        );

    // Factory Method
    function ScriptFactory()
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            scope: false,
            link: function(scope, elem, attr)
            {
                if (attr.type === 'text/javascript-lazy')
                {
                    let s = document.createElement("script");
                    s.type = "text/javascript";
                    let src = elem.attr('src');
                    if(src !== undefined) {
                        s.src = src;
                    } else {
                        let code = elem.text();
                        s.text = code;
                    }
                    document.head.appendChild(s);
                    elem.remove();
                }
            }
        };
    }
})();



