// Shell Controller
(function (){

    angular.module('Sinewav3.Client.Shell')

        .controller(
            'ProfileController',
            [
                '$rootScope',
                '$scope',
                '$mdDialog',
                'ToastService',
                'AssetService',
                'AccountService',
                'BroadcastService',
                'EVENTS',
                'CHROME',
                'ASSET_SERVICE_RESPONSES',
                'ACCOUNT_SERVICE_RESPONSES',
                ProfileController
            ]
        );

    // Constructor
    function ProfileController($rootScope,
                               $scope,
                               $mdDialog,
                               ToastService,
                               AssetService,
                               AccountService,
                               BroadcastService,
                               EVENTS,
                               CHROME,
                               ASSET_SERVICE_RESPONSES,
                               ACCOUNT_SERVICE_RESPONSES
    ) {

        let instance = this;
        instance.onDestroy = onDestroy;

        instance.editProfile = editProfile;
        instance.updateProfile = updateProfile;
        instance.onUpdateProfileResponse = onUpdateProfileResponse;
        instance.onStoreProfilePhotoResponse = onStoreProfilePhotoResponse;
        instance.sortPublishedPlugins = sortPublishedPlugins;
        instance.invokeFabAction = invokeFabAction;

        initialize();

        // Private initialization function
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.EDIT_PROFILE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( CHROME.FAB.ACTIONS.CLOSE.ACTION, instance.invokeFabAction ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.SUCCESS, instance.onStoreProfilePhotoResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.FAILURE, instance.onStoreProfilePhotoResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.SUCCESS, instance.onUpdateProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.FAILURE, instance.onUpdateProfileResponse ) );

            // Handle the selection of a profile image to be cropped
            $scope.handleImageFileSelect = function( files ) {
                let account = $rootScope.account;
                let file = files[0];

                // Use blueimp loadImage which fixes exif orientation problem
                loadImage(
                    file,
                    function(canvas) {
                        $scope.$apply( function( $scope ) {
                            account.input.image.raw = canvas.toDataURL();
                        });
                    },
                    {  orientation: true }
                );

            };

            // Hide the fab button upon entering an account page unless editing
            if ($rootScope.account.editing){
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.CLOSE);
            } else {
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.EDIT_PROFILE);
            }
        }

        /**
         * Remove event listeners when the controller is destroyed
         */
        function onDestroy() {
            let i, removeListener;
            for (i = 0; i < $scope.listenerCleanup.length; i++) {
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
            BroadcastService.send(EVENTS.HIDE_FAB);
        }

        /**
         * Edit the user's profile
         */
        function editProfile() {
            let account = $rootScope.account;
            if (!account.editing){
                account.editProfile();
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.CLOSE);
            }
        }

        /**
         * Update the user's profile with pending changes.
         */
        function updateProfile() {
            let account = $rootScope.account;
            let pending = account.input.profile;
            let cropped = account.input.image.crop;
            if ( pending && pending.isValid() ) {
                AccountService.updateProfile( pending );
            }

            if (account.editing_photo && cropped){
                AssetService.storeProfilePhoto(account.uid, cropped);
            }
            BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.EDIT_PROFILE);
            account.abandonEditing();
        }

        /**
         * Handle the UPDATE_PROFILE responses
         * @param event
         * @param data
         */
        function onUpdateProfileResponse(event, data) {
            let account = $rootScope.account;
            let message;
            switch (event.name) {
                case ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.SUCCESS:
                    account.setUserProfile(data);
                    message = "Profile updated";
                    ToastService.showToast(message);
                    break;

                case ACCOUNT_SERVICE_RESPONSES.UPDATE_PROFILE.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the STORE_PROFILE_PHOTO response
         * @param event
         * @param data
         */
        function onStoreProfilePhotoResponse(event, data) {
            let account = $rootScope.account;
            switch (event.name) {
                case ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.SUCCESS:
                    account.profile.photo_url = data;
                    AccountService.updateProfile(account.profile);
                    break;

                case ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Sort the user's approved plugins by likes and name
         * @param v1
         * @param v2
         * @returns {number}
         */
        function sortPublishedPlugins(v1, v2) {

            let plugins = $rootScope.plugin.getMyPublishedPlugins();
            let v1Likes = v1.value,
                v2Likes = v2.value,
                v1Name = plugins[ v1.index ].name,
                v2Name = plugins[ v2.index ].name,
                retval = 0;

            if ( v1Likes  <  v2Likes ) retval = 1;
            if ( v1Likes  >  v2Likes ) retval = -1;
            if ( v1Likes === v2Likes ) {
                if (v1Name  <  v2Name) retval = -1;
                if (v1Name  >  v2Name) retval = 1;
                if (v1Name === v2Name) retval = 0;
            }
            return retval;

        }

        /**
         * While a project is selected, the user has invoked
         * an action from the FAB menu
         * @param event
         */
        function invokeFabAction(event) {
            let action = event.name;
            let account = $rootScope.account;
            let message;
            switch (action)
            {
                case CHROME.FAB.ACTIONS.EDIT_PROFILE.ACTION:
                    instance.editProfile();
                    break;

                case CHROME.FAB.ACTIONS.CLOSE.ACTION:
                    if ( account.editing_photo || (account.editing && account.profileHasChanged()) )
                    {
                        message = 'Do you want to abandon your changes?';
                        let confirm = $mdDialog.confirm()
                            .title('Unsaved Changes')
                            .textContent(message)
                            .ariaLabel(message)
                            .ok('Cancel')
                            .cancel('Abandon');

                        $mdDialog.show(confirm)
                            .then(() =>  {}) // Cancel
                            .catch(() => cancelAction()); // Abandon
                    } else {
                        cancelAction()
                    }
                    break;
            }

            // Abandon the profile edit and hide the fab
            function cancelAction(){
                account.abandonEditing();
                BroadcastService.send(EVENTS.SHOW_FAB_BUTTON, CHROME.FAB.ACTIONS.EDIT_PROFILE);
            }
        }

    }
})();
