(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'cardEditPhoto',
            [
                'SECTIONS',
                CardEditPhotoFactory
            ]
        );

    // Factory Method
    function CardEditPhotoFactory(SECTIONS)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            scope: false,
            templateUrl: SECTIONS.PREFIX + SECTIONS.ACCOUNT.PROFILE_EDIT_PHOTO + SECTIONS.POSTFIX
        };

    }
})();



