// Shell Configuration
(function (){

    angular.module('Sinewav3.Client.Shell')

        // Dictionary of modes for the shell
        .constant('MODES', {
            ANON:  'anon',
            ADMIN: 'admin',
            USER:  'user'
        })

        // Dictionary of events for the shell
        .constant('EVENTS', {
            DESTROY:          '$destroy',
            KEY_DOWN:         'onkeydown',
            CODE_RESIZE:      'angular-resizable.resizing',
            ROUTE_CHANGE:     '$routeChangeStart',
            ROUTE_CHANGED:    '$routeChangeSuccess',
            NAV_TO:           'shell-navigate-to',
            CHANGE_MODE:      'shell-change-mode',
            SHOW_FAB_BUTTON:  'shell-show-fab-button',
            SHOW_FAB_MENU:    'shell-show-fab-menu',
            HIDE_FAB:         'shell-hide-fab'
        })

        .constant('AUTH_PROVIDERS', {
            GOOGLE:          'Google',
            TWITTER:         'Twitter',
            GITHUB:          'Github',
            FACEBOOK:        'Facebook'
        })

        // Database of sections for routing
        .constant('SECTIONS', {
            PREFIX:     'shell/ui/sections',
            POSTFIX:    '-tmpl.html',
            ROOTS:      {
                ACCOUNT:   'account',
                ADMIN:     'admin',
                LANDING:   'landing'
            },

            // ACCOUNT PAGES
            ACCOUNT:    {
                ASSETS:          '/account/asset/list',
                ASSETS_SUB:      '/account/asset/sub-list',
                VIEW_ASSET:      '/account/asset/view',
                ASSET_DLG:       '/account/asset/create',
                ASSET_TYPE:      '/account/asset/type-menu',
                PLUGINS:         '/account/plugin/list',
                PLUG_DLG:        '/account/plugin/create',
                PUB_PLUG:        '/account/plugin/publish',
                EDIT_PLUG:       '/account/plugin/edit',
                PROJECTS:        '/account/project/list',
                EDIT_PROJ:       '/account/project/edit',
                CREATE_PROJ:     '/account/project/create',
                SHARE_PROJ:       '/account/project/share',
                RENDER_DLG:      '/account/project/render',
                PROFILE_MANAGE:  '/account/profile/manage',
                PROFILE_CARD_DISPLAY:  '/account/profile/card-display',
                PROFILE_CARD_INFO:     '/account/profile/card-edit-info',
                PROFILE_EDIT_PHOTO:    '/account/profile/card-edit-photo',
                PROFILE_LOCATION:      '/account/profile/location',
                ACTIVITY:        '/account/profile/activity',
                SIGN_OUT:        '/account/profile/sign-out',
                CHANGE_PW:       '/account/profile/password'

            },

            // ADMIN PAGES
            ADMIN:      {
                METRICS:     '/admin/metrics',
                USERS:       '/admin/users',
                PLANS:       '/admin/plans',
                REJECT_DLG:  '/admin/reject'

            },

            // LANDING PAGES
            LANDING:    {
                HOME:           '/landing/home',
                INTRO_CARD:     '/landing/intro-card',
                INTRO_MOBILE:   '/landing/intro-mobile',
                PRESO_CARD:     '/landing/preso-card',
                PRESO_MOBILE:   '/landing/preso-mobile',
                MINI_CARD_ART:  '/landing/mini-card-artist',
                MINI_CARD_CODE: '/landing/mini-card-coder',
                TERMS:          '/landing/terms',
                PRIVACY:        '/landing/privacy',
                AUTH:           '/landing/auth',
                SIGN_IN:        '/landing/sign-in',
                SIGN_UP:        '/landing/sign-up',
                FORGOT_PW:      '/landing/forgot-password'
            },

        })

        // Copy for various pages
        .constant('COPY', {
            AUTH: {
                SIGN_IN:   'Welcome back. Let\'s make something awesome!',
                SIGN_UP:   'Howdy, pardner, ready to have some fun?',
                FORGOT_PW: 'Need a link to reset your password?',
                SIGN_OUT:  'Happy trails, come back soon!'
            }
        })

        // Startup the visualizer core
        .constant('VISUALIZER',
            sinewav3.VisualizerFacade.getInstance(sinewav3.VisualizerFacade.NAME)
        )

        // Shell-related scope initialization
        .run([
            '$rootScope',
            '$timeout',
            '$window',
            'MODES',
            'EVENTS',
            'SECTIONS',
            'COPY',
            'CHROME',
            'AUTH_PROVIDERS',
            'VISUALIZER',
            initialize
        ]);

    // Scope initialization
    function initialize($rootScope, $timeout, $window, MODES, EVENTS, SECTIONS, COPY, CHROME, AUTH_PROVIDERS, VISUALIZER) {

        $rootScope.MODES = MODES;
        $rootScope.EVENTS = EVENTS;
        $rootScope.SECTIONS = SECTIONS;
        $rootScope.COPY = COPY;
        $rootScope.CHROME = CHROME;
        $rootScope.AUTH_PROVIDERS = AUTH_PROVIDERS;
        $rootScope.shell = new ShellScopeModel($rootScope, $timeout, $window, MODES.ANON, "");

        // Startup the visualizer
        VISUALIZER.startup();
    }

    /**
     * Asset Scope Model
     * @constructor
     */
    function ShellScopeModel($rootScope, $timeout, $window, mode, page) {

        // State
        this.config = null;
        this.mode = mode;
        this.page = page;
        this.params = {};
        this.fullscreen = this.detectFullScreenMode();
        this.webgl = this.detectWebGL();
        this.loading = true;
        this.loaded = false;
        this.load_counter = 0;
        this.startup = true;
        this.auth_form = $rootScope.SECTIONS.LANDING.SIGN_IN;
        this.auth_copy = $rootScope.COPY.AUTH.SIGN_IN;

        // Angular references
        this.$window = $window;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;

        // Listen for changes to fullscreen state
        if (document.addEventListener) {
            document.addEventListener('webkitfullscreenchange', () => this.onFullScreenChange() );
            document.addEventListener('mozfullscreenchange', () => this.onFullScreenChange() );
            document.addEventListener('fullscreenchange', () => this.onFullScreenChange());
            document.addEventListener('MSFullscreenChange', () => this.onFullScreenChange());
        }
    }

    ShellScopeModel.prototype.setConfig = function(config) {
        this.config = config;

        if (config.env !== 'app') {
            this.$window.document.title = [this.$window.document.title, "|", config.env].join(" ");
        }
    };

    /**
     * Get the host path e.g., https://dev.sinewav3.com/
     * @returns {*}
     */
    ShellScopeModel.prototype.getHostPrefix = function() {
        return `https://${this.config.env}.sinewav3.com`;
    };

    ShellScopeModel.prototype.startupComplete = function() {
        this.startup = false;
    };

    ShellScopeModel.prototype.onFullScreenChange = function() {
        this.$timeout ( this.$rootScope.$apply( () =>
            this.fullscreen = this.detectFullScreenMode(), 350
        ));
    };

    ShellScopeModel.prototype.detectWebGL = function () {
        try {
            let canvas = document.createElement('canvas');
            return !!( window.WebGLRenderingContext &&
                ( canvas.getContext('webgl') || canvas.getContext('experimental-webgl') )
            );
        } catch (e) {
            return false;
        }
    };

    ShellScopeModel.prototype.detectFullScreenMode = function () {
        return (document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen);
    };

    ShellScopeModel.prototype.incrementLoadCounter = function () {
        this.load_counter++;
        this.loading = true;
        this.loaded = false;
    };

    ShellScopeModel.prototype.decrementLoadCounter = function () {
        if ( this.load_counter > 0 ) this.load_counter--;
        if ( this.load_counter === 0 ) this.showApp();
    };

    ShellScopeModel.prototype.showApp = function () {
        this.$timeout( () => this.loaded = true, 1200);
        this.$timeout( () => this.loading = false, 2000);
        this.$timeout( () => this.show_selected_tab = true, 2750);
    };

    ShellScopeModel.prototype.setAuthForm = function (form) {

        const SECTIONS = this.$rootScope.SECTIONS;
        const COPY = this.$rootScope.COPY;

        this.auth_form = form;
        switch (form) {
            case SECTIONS.LANDING.SIGN_UP:
                this.auth_copy = COPY.AUTH.SIGN_UP;
                break;

            case SECTIONS.LANDING.SIGN_IN:
                this.auth_copy = COPY.AUTH.SIGN_IN;
                break;

            case SECTIONS.ACCOUNT.SIGN_OUT:
                this.auth_copy = COPY.AUTH.SIGN_OUT;
                break;

            case SECTIONS.LANDING.FORGOT_PW:
                this.auth_copy = COPY.AUTH.FORGOT_PW;
                break;
        }
    };




})();
