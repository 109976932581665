(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'userToolbar',
            [
                'CHROME',
                UserToolbarFactory
            ]
        );

    // Factory Method
    function UserToolbarFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            controller: 'ToolbarController',
            controllerAs: 'toolbarCtrl',
            templateUrl: CHROME.PREFIX + CHROME.TOOLBAR.USER + CHROME.POSTFIX
        };

    }
})();



