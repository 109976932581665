/**
 * <b>The Plugin developer's interface to the Plugin's settings.</b>
 *
 * A reference to the PluginContext is available to all Plugins via the VisualizerContext as <b>context.plugins</b>.

 * Inside any of the Plugin's functions, you can read the settings
 * and take appropriate action in response.
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var PluginContext = (function() {

        /**
         * Constructor
         * @private
         * @param id
         * @param name
         * @param author
         * @param settings
         * @constructor
         */
        function PluginContext( id, name, author, settings ) {
            this.id = id;
            this.name = name;
            this.author = author;
            this.settings = settings;
        }

        /**
         * Get a settings group by name from this PluginContext instance's settings collection
         * @private
         * @param {string} name The name of the SettingGroup to retrieve
         * @returns {SettingGroup}
         */
        PluginContext.prototype.getSettingGroup = function( name ) {
            var group = ( this.settings )
                ? this.settings.find( item => item.name === name )
                : null;
            return group;
        };

        /**
         * Retrieve the current value of a Setting
         *
         * @param {string} group_name the name of the SettingGroup the Setting belongs to
         * @param {string} setting_name the name of the Setting
         * @returns {*} value
         */
        PluginContext.prototype.getSettingValue = function( group_name, setting_name ) {
            var group = this.getSettingGroup( group_name );
            var setting = ( group )
                ? group.settings.find( item => item.name === setting_name )
                : null;
            return (setting != null && setting.type === Setting.TYPE.COLOR)
                ? setting.getValueAsColor()
                : setting.value;
        };

        return PluginContext;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = PluginContext;
    } else {
        window.PluginContext = PluginContext;
    }

})();