// Shell Controller
(function (){

    angular.module('Sinewav3.Client.Shell')

        .controller(
            'ShellController',
            [
                '$rootScope',
                '$scope',
                '$mdDialog',
                '$mdMedia',
                '$location',
                '$timeout',
                '$window',
                'ConfigService',
                'ToastService',
                'FocusService',
                'AccountService',
                'ProjectService',
                'PluginService',
                'AssetService',
                'LikeService',
                'BroadcastService',
                'EVENTS',
                'MODES',
                'SECTIONS',
                'COPY',
                'CHROME',
                'AUTH_SERVICE_RESPONSES',
                'CONFIG_SERVICE_RESPONSES',
                'ACCOUNT_SERVICE_RESPONSES',
                'PROJECT_SERVICE_RESPONSES',
                'PLUGIN_SERVICE_RESPONSES',
                'ASSET_SERVICE_RESPONSES',
                'LIKE_SERVICE_RESPONSES',
                ShellController
            ]
        );

    // Constructor
    function ShellController($rootScope,
                             $scope,
                             $mdDialog,
                             $mdMedia,
                             $location,
                             $timeout,
                             $window,
                             ConfigService,
                             ToastService,
                             FocusService,
                             AccountService,
                             ProjectService,
                             PluginService,
                             AssetService,
                             LikeService,
                             BroadcastService,
                             EVENTS,
                             MODES,
                             SECTIONS,
                             COPY,
                             CHROME,
                             AUTH_SERVICE_RESPONSES,
                             CONFIG_SERVICE_RESPONSES,
                             ACCOUNT_SERVICE_RESPONSES,
                             PROJECT_SERVICE_RESPONSES,
                             PLUGIN_SERVICE_RESPONSES,
                             ASSET_SERVICE_RESPONSES,
                             LIKE_SERVICE_RESPONSES
    ){

        let instance = this;
        instance.onDestroy = onDestroy;

        // APP RELATED
        instance.onLoadConfigResponse = onLoadConfigResponse;

        // ROUTE RELATED
        instance.enforceAccessPermissions = enforceAccessPermissions;
        instance.onRouteChange = onRouteChange;
        instance.onRouteChanged = onRouteChanged;
        instance.onNavTo = onNavTo;
        instance.navToSignIn = navToSignIn;
        instance.navToSignUp = navToSignUp;
        instance.navToSignOut = navToSignOut;
        instance.navToForgotPassword = navToForgotPassword;
        instance.selectPage = selectPage;
        instance.isSelected = isSelected;
        instance.needsAuth = needsAuth;
        instance.hasAuth = hasAuth;
        instance.needsAdmin = needsAdmin;
        instance.hasAdmin = hasAdmin;
        instance.onChangeMode = onChangeMode;
        instance.getSelectedPage = getSelectedPage;

        // SIGN-IN RELATED
        instance.onAuthChanged = onAuthChanged;
        instance.onLoadProfileResponse = onLoadProfileResponse;
        instance.onCreateProfileResponse = onCreateProfileResponse;
        instance.onListProjectsResponse = onListProjectsResponse;
        instance.onListPluginsResponse = onListPluginsResponse;
        instance.onListPublicPluginsResponse = onListPublicPluginsResponse;
        instance.onListSubmittedPluginsResponse = onListSubmittedPluginsResponse;
        instance.onMapPluginLikesResponse = onMapPluginLikesResponse;
        instance.onListAssetsResponse = onListAssetsResponse;
        instance.onListPublicAssetsResponse = onListPublicAssetsResponse;

        // UI RELATED
        instance.onFABVisibilityChange = onFABVisibilityChange;
        instance.showInfoPopup = showInfoPopup;
        instance.getAssetTypeIcon = getAssetTypeIcon;
        instance.trimToEllipsis = trimToEllipsis;
        instance.showAPI = showAPI;
        instance.gotoSlack = gotoSlack;
        instance.gotoBlog = gotoBlog;
        instance.showMobileFSLink = showMobileFSLink;


        initialize();

        // Private initialization function
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.NAV_TO, instance.onNavTo ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.CHANGE_MODE, instance.onChangeMode ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.ROUTE_CHANGE, instance.onRouteChange ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.ROUTE_CHANGED, instance.onRouteChanged ) );
            $scope.listenerCleanup.push( $scope.$on( CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.SUCCESS, instance.onLoadConfigResponse ) );
            $scope.listenerCleanup.push( $scope.$on( CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.FAILURE, instance.onLoadConfigResponse ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.SIGN_IN.SUCCESS, instance.onAuthChanged ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.SIGN_IN.FAILURE, instance.onAuthChanged ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.SIGN_OUT.SUCCESS, instance.onAuthChanged ) );
            $scope.listenerCleanup.push( $scope.$on( AUTH_SERVICE_RESPONSES.SIGN_OUT.FAILURE, instance.onAuthChanged ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.SUCCESS, instance.onLoadProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.FAILURE, instance.onLoadProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.SUCCESS, instance.onCreateProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.FAILURE, instance.onCreateProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.NO_PROFILE, instance.onLoadProfileResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.SUCCESS, instance.onListProjectsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.FAILURE, instance.onListProjectsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.SUCCESS, instance.onListPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.FAILURE, instance.onListPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.SUCCESS, instance.onListPublicPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.FAILURE, instance.onListPublicPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.SUCCESS, instance.onListSubmittedPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.FAILURE, instance.onListSubmittedPluginsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.SUCCESS, instance.onMapPluginLikesResponse ) );
            $scope.listenerCleanup.push( $scope.$on( LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.FAILURE, instance.onMapPluginLikesResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.LIST_ASSETS.SUCCESS, instance.onListAssetsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.LIST_ASSETS.FAILURE, instance.onListAssetsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.SUCCESS, instance.onListPublicAssetsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.FAILURE, instance.onListPublicAssetsResponse ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.SHOW_FAB_BUTTON, instance.onFABVisibilityChange ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.SHOW_FAB_MENU, instance.onFABVisibilityChange ) );
            $scope.listenerCleanup.push( $scope.$on( EVENTS.HIDE_FAB, instance.onFABVisibilityChange ) );

            // Load the configuration
            ConfigService.loadConfig();
            $rootScope.shell.incrementLoadCounter();

            // Set form validation functions on the $scope
            $scope.signupFormIsValid = $scope.changePassFormIsValid = function(form) {
                return form.$valid && $rootScope.account.input.password === $rootScope.account.input.confirm;
            };

            // Listen for auth state changes
            firebase.auth().onAuthStateChanged(authStateChanged);

            // Private inner function to handle auth state changes
            function authStateChanged(user) {
                let shell = $rootScope.shell;
                let account = $rootScope.account;
                if (user) { // SIGNED IN
                    if (shell.startup || account.manual_sign_in) {
                        $timeout(function () {
                            BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_IN.SUCCESS, user);
                            console.log('Logged In');
                        }, 350);
                    }
                } else {    // SIGNED OUT
                    if (!shell.startup) {
                        BroadcastService.send(AUTH_SERVICE_RESPONSES.SIGN_OUT.SUCCESS);
                        shell.showApp();
                    }
                    console.log('Logged Out');
                }
            }

            // Get the initial screen size and watch for changes
            onScreenChangeSmall( $mdMedia('gt-sm') );
            $rootScope.$watch( () => $mdMedia('gt-sm'), onScreenChangeSmall );
            function onScreenChangeSmall( big ) {
                $rootScope.mobileScreen = !big;
            }

            onScreenChangeExtraSmall( $mdMedia('gt-xs') );
            $rootScope.$watch( () => $mdMedia('gt-xs'), onScreenChangeExtraSmall );
            function onScreenChangeExtraSmall( medium ) {
                $rootScope.smallMobileScreen = !medium;
            }
        }

        /**
         * Remove event listeners when the controller is destroyed
         */
        function onDestroy() {
            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }
        }

        //--------------------------------------------------
        // APP RELATED
        //--------------------------------------------------

        function onLoadConfigResponse(event, data) {

            let shellScope = $rootScope.shell;
            if (shellScope.loading) shellScope.decrementLoadCounter();

            switch (event.name)
            {
                case CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.SUCCESS:
                    $timeout(function() {
                        shellScope.setConfig(data);
                        shellScope.decrementLoadCounter();
                    }, 350);
                    break;

                case CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        //--------------------------------------------------
        // ROUTE RELATED
        //--------------------------------------------------

        /**
         * If the user is not authorized to see the page,
         * redirect them to the home page.
         * @param page
         * @returns {boolean}
         */
        function enforceAccessPermissions( page ) {
            let allowed = true;
            if ( (instance.needsAuth(page) && !instance.hasAuth()) ||
                (instance.needsAdmin(page) && !instance.hasAdmin()) ) {
                redirectUnauthorized();
                allowed = false;
            }
            return allowed;

            function redirectUnauthorized() {
                instance.selectPage(SECTIONS.LANDING.HOME);
            }
        }

        /**
         * A route change has begun.
         * Prevent unauthorized access from url bar or bookmark.
         *
         * Critically, this isn't called on first load, where
         * we could prevent unauthorized route changes from happening.
         *
         * Therefore we must also enforce permissions from onRouteChanged
         * after the fact.
         *
         * The upshot is that nearly every unauthorized access attempt
         * will be redirected from here, before it can happen.
         *
         * @param event
         * @param next
         */
        function onRouteChange(event, next) {
            if (next && next.$$route) {
                let page = next.$$route.originalPath;
                let allowed = instance.enforceAccessPermissions(page);
                if (!allowed) event.preventDefault();
            }
        }

        /**
         * A route change has successfully completed.
         *
         * If the user has access, and either modified the URL hash manually
         * or loaded from a bookmark, the menu needs to be synced up now.
         *
         * Otherwise we need to redirect them.
         *
         * @param event
         * @param current
         * @param previous
         */
        function onRouteChanged(event, current, previous) {
            let shell = $rootScope.shell;
            let page = current.$$route.originalPath;
            if (page != shell.page) {
                let allowed = instance.enforceAccessPermissions(page);
                if (allowed) {
                    shell.page = page;
                    shell.params = current.params;
                }
            }
        }

        /**
         * Does the given page need authentication?
         * @param page
         * @returns {boolean}
         */
        function needsAuth(page) {
            let nodes = page.split('/');
            return ( nodes[1] === SECTIONS.ROOTS.ACCOUNT );
        }

        /**
         * Is the user authenticated
         * @returns {boolean}
         */
        function hasAuth() {
            return ($rootScope.account.profile != null);
        }

        /**
         * Does the given page need administrator authentication?
         * @param page
         * @returns {boolean}
         */
        function needsAdmin(page) {
            let nodes = page.split('/');
            return ( nodes[1] === SECTIONS.ROOTS.ADMIN );
        }

        /**
         * Is the user authenticated and an administrator?
         * @returns {boolean|*}
         */
        function hasAdmin() {
            return (instance.hasAuth() && $rootScope.account.profile.is_admin);
        }

        /**
         * Handle the EVENTS.NAV_TO event
         * @param event
         * @param data
         */
        function onNavTo(event, data) {
            switch (data) {
                case SECTIONS.LANDING.SIGN_IN:
                    instance.navToSignIn();
                    break;

                case SECTIONS.LANDING.SIGN_UP:
                    instance.navToSignUp();
                    break;

                case SECTIONS.LANDING.FORGOT_PW:
                    instance.navToForgotPassword();
                    break;

                default:
                    instance.selectPage(data);
            }
        }

        /**
         * Navigate to the user auth page and show the sign in form
         */
        function navToSignIn(){
            $rootScope.shell.setAuthForm(SECTIONS.LANDING.SIGN_IN);
            instance.selectPage(SECTIONS.LANDING.AUTH);
        }

        /**
         * Navigate to the user auth page and show the sign up form
         */
        function navToSignUp(){
            $rootScope.shell.setAuthForm(SECTIONS.LANDING.SIGN_UP);
            instance.selectPage(SECTIONS.LANDING.AUTH);
        }

        /**
         * Navigate to the user auth page and show the sign up form
         */
        function navToSignOut(){
            $rootScope.shell.setAuthForm(SECTIONS.ACCOUNT.SIGN_OUT);
            instance.selectPage(SECTIONS.LANDING.AUTH);
        }

        /**
         * Navigate to the user auth page and show the forgot password form
         */
        function navToForgotPassword(){
            $rootScope.shell.setAuthForm(SECTIONS.LANDING.FORGOT_PW);
            instance.selectPage(SECTIONS.LANDING.AUTH);
        }

        /**
         * Select a page
         * @param page
         */
        function selectPage(page) {
            $rootScope.shell.page = page;
            $location.path(page);
            FocusService.focusOn(CHROME.PAGE);
        }

        /**
         * Check if the given page is selected
         * @param page
         * @returns {boolean}
         */
        function isSelected(page) {
            return $rootScope.shell.page === page;
        }

        /**
         * Change the shell mode (ANON/USER/ADMIN)
         * @param event
         * @param data
         */
        function onChangeMode(event, mode) {
            $rootScope.$apply(function(){
                $rootScope.shell.mode = mode;
            });
        }

        /**
         * Get the selected page
         * @returns {*}
         */
         function getSelectedPage(){
            let shell = $rootScope.shell;
            return shell.show_selected_tab ? shell.page : null;

        }

        //--------------------------------------------------
        // SIGN-IN RELATED
        //--------------------------------------------------

        /**
         * Handle the SIGN_IN and SIGN_OUT responses
         * @param event
         * @param data
         */
        function onAuthChanged(event, data) {
            let account = $rootScope.account;
            let project = $rootScope.project;
            let asset   = $rootScope.asset;
            let plugin  = $rootScope.plugin;
            let shell   = $rootScope.shell;
            console.log('AUTH CHANGE:',event.name);
            switch (event.name)
            {
                case AUTH_SERVICE_RESPONSES.SIGN_IN.SUCCESS:
                    shell.setAuthForm(SECTIONS.ACCOUNT.SIGN_OUT);
                    if (!account.signing_up) {
                        account.uid = data.uid;
                        AccountService.loadProfile(account.uid);
                        shell.incrementLoadCounter();
                    }
                    break;

                case AUTH_SERVICE_RESPONSES.SIGN_OUT.SUCCESS:
                    account.reset();
                    project.reset();
                    asset.reset();
                    plugin.reset();
                    $rootScope.shell.setAuthForm(SECTIONS.LANDING.SIGN_IN);
                    BroadcastService.send(EVENTS.CHANGE_MODE, MODES.ANON);
                    if (!shell.startup) BroadcastService.send(EVENTS.NAV_TO, SECTIONS.LANDING.HOME);
                    break;

                case AUTH_SERVICE_RESPONSES.SIGN_IN.FAILURE:
                case AUTH_SERVICE_RESPONSES.SIGN_OUT.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LOAD_PROFILE response
         * @param event
         * @param data
         */
        function onLoadProfileResponse(event, data) {
            let account = $rootScope.account;
            let shell = $rootScope.shell;
            switch (event.name)
            {
                case ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.SUCCESS:
                    account.setUserProfile( data );

                    // Fetch the user's list of projects
                    ProjectService.listProjects(account.profile.uid);
                    shell.incrementLoadCounter();

                    // Fetch the user's list of assets
                    AssetService.listAssets(account.profile.uid);
                    shell.incrementLoadCounter();

                    // Fetch the list of public assets
                    AssetService.listPublicAssets();
                    shell.incrementLoadCounter();

                    // Fetch the user's list of plugins
                    PluginService.listPlugins(account.profile.uid);
                    shell.incrementLoadCounter();

                    // Fetch the list of public plugins (includes examples and products)
                    PluginService.listPublicPlugins();
                    shell.incrementLoadCounter();

                    // Fetch the list of submitted plugins if user is admin
                    if (instance.hasAdmin()) {
                        PluginService.listSubmittedPlugins();
                        shell.incrementLoadCounter();
                    }

                    // Fetch a map of the user's liked plugins
                    LikeService.mapPluginLikes(account.profile.uid);
                    shell.incrementLoadCounter();

                    // Acknowledge receipt of profile now
                    shell.decrementLoadCounter();

                    // Choose the appropriate shell mode
                    let isAdmin = account.profile.is_admin;
                    let mode = isAdmin ? MODES.ADMIN : MODES.USER;
                    BroadcastService.send(EVENTS.CHANGE_MODE, mode);
                    shell.startupComplete();

                    // Choose the default page
                    if (account.manual_sign_in) account.manual_sign_in = false;
                    let page = isAdmin ? SECTIONS.ADMIN.METRICS : SECTIONS.ACCOUNT.PROJECTS;
                    BroadcastService.send(EVENTS.NAV_TO, page);
                    break;

                case ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.FAILURE:
                    ToastService.showToast(data.message);
                    break;

                case ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.NO_PROFILE:
                    let user = firebase.auth().currentUser;
                    AccountService.createProfile(user);
                    shell.decrementLoadCounter();
                    break;

            }
        }


        /**
         * Handle the CREATE_PROFILE response
         * @param event
         * @param data
         */
        function onCreateProfileResponse(event, data) {
            let message;
            let account = $rootScope.account;
            account.signing_up = false;
            switch (event.name) {
                case ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.SUCCESS:
                    BroadcastService.send(ACCOUNT_SERVICE_RESPONSES.LOAD_PROFILE.SUCCESS, data);
                    message = 'Account Created. ';
                    if (account.signing_in_with_social) {
                        message += 'Check your email and validate your account!';
                        account.signing_in_with_social = false;
                    }
                    ToastService.showToast(message);
                    break;

                case ACCOUNT_SERVICE_RESPONSES.CREATE_PROFILE.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LIST_PROJECTS response
         * @param event
         * @param data
         */
        function onListProjectsResponse(event, data) {

            let shellScope = $rootScope.shell;
            if (shellScope.loading) shellScope.decrementLoadCounter();

            switch (event.name)
            {
                case PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.SUCCESS:
                    $timeout(function() {
                        $rootScope.project.setProjectList(data);
                    }, 350);
                    break;

                case PROJECT_SERVICE_RESPONSES.LIST_PROJECTS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }

        }

        /**
         * Handle the LIST_PLUGINS response
         * @param event
         * @param data
         */
        function onListPluginsResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.SUCCESS:
                    $timeout(function() {
                        let scopeModel = $rootScope.plugin;
                        scopeModel.list = data;
                        if (scopeModel.selected) {
                            let replacement;
                            scopeModel.list.forEach( plugin => { if (plugin.id == scopeModel.selected.id ) replacement = plugin; } );
                            if (replacement) {
                                scopeModel.selectPlugin(replacement);
                            } else {
                                scopeModel.deselectPlugin();
                            }
                        }
                    }, 350);
                    break;

                case PLUGIN_SERVICE_RESPONSES.LIST_PLUGINS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LIST_PUBLIC_PLUGINS response
         * @param event
         * @param data
         */
        function onListPublicPluginsResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.SUCCESS:
                    $timeout(function() {
                        let scopeModel = $rootScope.plugin;
                        let examples = [];
                        let products = [];
                        data.forEach( plugin => plugin.is_example ? examples.push(plugin) : products.push(plugin) );
                        scopeModel.product_list = products;
                        scopeModel.example_list = examples;
                    }, 350);
                    break;

                case PLUGIN_SERVICE_RESPONSES.LIST_PUBLIC_PLUGINS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LIST_SUBMITTED_PLUGINS response
         * @param event
         * @param data
         */
        function onListSubmittedPluginsResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.SUCCESS:
                    $timeout(function() {
                        let scopeModel = $rootScope.plugin;
                        let submissions = [];
                        data.forEach( plugin => submissions.push(plugin) );
                        scopeModel.submissions_list = submissions;
                    }, 350);
                    break;

                case PLUGIN_SERVICE_RESPONSES.LIST_SUBMITTED_PLUGINS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the MAP_PLUGIN_LIKES response
         * @param event
         * @param data
         */
        function onMapPluginLikesResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.SUCCESS:
                    $timeout(function() {
                        $rootScope.plugin.likes = data;
                    }, 350);
                    break;

                case LIKE_SERVICE_RESPONSES.MAP_PLUGIN_LIKES.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LIST_ASSETS response
         * @param event
         * @param data
         */
        function onListAssetsResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case ASSET_SERVICE_RESPONSES.LIST_ASSETS.SUCCESS:
                    $timeout(function() {
                        $rootScope.asset.setAssetList(data);
                    }, 350);
                    break;

                case ASSET_SERVICE_RESPONSES.LIST_ASSETS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }

        /**
         * Handle the LIST_PUBLIC_ASSETS response
         * @param event
         * @param data
         */
        function onListPublicAssetsResponse(event, data) {
            $rootScope.shell.decrementLoadCounter();
            switch (event.name)
            {
                case ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.SUCCESS:
                    $timeout(function() {
                        $rootScope.asset.setPublicAssetList(data);
                    }, 350);
                    break;

                case ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.FAILURE:
                    ToastService.showToast(data.message);
                    break;
            }
        }


        //-------------------------------------------------
        // UI RELATED
        //-------------------------------------------------

        /**
         * Change the visibility of the FAB Button or FAB Menu
         * @param event
         * @param data
         */
        function onFABVisibilityChange(event, data) {
            let chrome = $rootScope.chrome;

            switch (event.name)
            {
                case EVENTS.SHOW_FAB_BUTTON:
                    chrome.hidingFAB = false;
                    $timeout( () => {
                        chrome.fab.menu.show = false;
                        chrome.fab.button.action = data;
                        chrome.fab.button.show = true;
                    }, 300);
                    break;

                case EVENTS.SHOW_FAB_MENU:
                    $timeout( () => {
                        chrome.fab.button.show = false;
                        chrome.fab.menu.actions = data;
                        chrome.fab.menu.show = true;
                    }, 300);
                    break;

                case EVENTS.HIDE_FAB:
                    chrome.fab.button.show = false;
                    chrome.fab.menu.show = false;
                    break;
            }
        }

        /**
         * Show an info popup
         */
        function showInfoPopup( title, content ) {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(title)
                    .ariaLabel(title)
                    .textContent(content)
                    .ok('Got it!')
            );

        }

        /**
         * Get the status icon for an asset
         * Placed here since Projects, Assets, and Plugins need access to it
         * @param asset
         * @returns {string}
         */
        function getAssetTypeIcon(type) {
            let icon;
            switch (type)
            {
                case Asset.TYPE.AUDIO:
                    icon = CHROME.ICON.TYPE.AUDIO;
                    break;

                case Asset.TYPE.IMAGE:
                    icon = CHROME.ICON.TYPE.IMAGE;
                    break;

                case Asset.TYPE.MODEL:
                    icon = CHROME.ICON.TYPE.MODEL;
                    break;

                case Asset.TYPE.VIDEO:
                    icon = CHROME.ICON.TYPE.VIDEO;
                    break;

                case Asset.TYPE.FRAME:
                    icon = CHROME.ICON.TYPE.FRAME;
                    break;

                case Asset.TYPE.FONT:
                    icon = CHROME.ICON.TYPE.FONT;
                    break;

                case AssetGroup.TYPE.CUBE:
                    icon = CHROME.ICON.TYPE.CUBE;
                    break;
            }
            return icon;
        }

        /**
         * Return the text, shortened with ellipsis added, if over max length
         * @param text
         * @param length
         * @returns {string}
         */
        function trimToEllipsis( text, max ) {
            let retval = (text && text.length > max)
                ? text.slice(0, max-3) + "..."
                : text;

            return retval
        }

        /**
         * Show the Plugin API docs
         */
        function showAPI() {

            $window.open('docs/index.html', '_blank');

        }

        /**
         * Show the Plugin API docs
         */
        function gotoSlack() {

            $window.open('https://sinewav3.slack.com/signup', '_blank');

        }

        function gotoBlog() {
            $window.open('http://sinewav3.com', '_blank');
        }

        function showMobileFSLink() {
            let shell = $rootScope.shell;
            return shell.page === SECTIONS.LANDING.HOME ||
                shell.page === SECTIONS.LANDING.PRIVACY ||
                shell.page === SECTIONS.LANDING.TERMS
        }

    }

})();
