// Shell - Storage Service
(function() {

    // Add the BroadcastService to the module
    angular.module('Sinewav3.Client.Shell')

        // Dictionary of top-level database nodes
        .constant('UPLOAD_TYPES',
            {
                AUDIO: {
                    DESC: 'Audio',
                    MIME: 'audio/x-m4a, audio/mpeg',
                    TYPE: Asset.TYPE.AUDIO,
                    GROUP: false
                },
                IMAGE: {
                    DESC: 'Image',
                    MIME: 'image/*',
                    TYPE: Asset.TYPE.IMAGE,
                    GROUP: false
                },
                MODEL: {
                    DESC: '3D Model',
                    MIME: 'application/object',
                    TYPE: Asset.TYPE.MODEL,
                    GROUP: true
                },
                CUBE: {
                    DESC: 'Cube Map',
                    MIME: 'image/*',
                    TYPE: AssetGroup.TYPE.CUBE,
                    GROUP: true
                },
                FONT: {
                    DESC: 'Font',
                    MIME: 'application/json',
                    TYPE: Asset.TYPE.FONT,
                    GROUP: false
                }
            }
        )

        .factory(
            'StorageService',
            [
                StorageServiceFactory
            ]
        );

    // Factory Method
    function StorageServiceFactory()
    {
        let service = {};

        service.getRef = getRef;
        service.getAssetPath = getAssetPath;
        service.getProfilePhotoPath = getProfilePhotoPath;
        service.convertDataURItoBlob = convertDataURItoBlob;

        return service;

        /**
         * Get the reference to the storage bucket
         * @param path
         * @returns {*}
         */
        function getRef(path) {
            return firebase.storage().ref(path);
        }

        /**
         * Get the storage path for a user's asset
         * @param uid
         * @param type
         * @param id
         * @param filename
         * @returns {string}
         */
        function getAssetPath(uid, type, id, filename) {
            return ['assets', uid, type, id, filename].join('/');
        }

        /**
         * Get the storage path for the user's profile photo
         * @param uid
         * @returns {string}
         */
        function getProfilePhotoPath(uid){
            return ['users', uid, 'profile.png'].join('/');
        }

        /**
         * Convert a data URI to a storable blob
         * @param dataURI
         * @returns {*}
         */
        function convertDataURItoBlob(dataURI) {

            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            let byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            let ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            let ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            let blob = new Blob([ab], {type: mimeString});
            return blob;

        }

        /*
        function convertDataURItoBlob(dataURI) {
            let arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1];
            return new Blob([atob(arr[1])], {type:mime});
        }
        */
    }

})();
