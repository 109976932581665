/**
 * Sinewav3 Schema Entity: World
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var World = (function() {

        /**
         * Constructor
         * @param name
         * @param plugins
         * @param projection
         * @constructor
         */
        function World( name, plugins, settings ) {
            this.name = name;
            this.plugins = plugins;
            this.settings = settings || World.getDefaultSettings()
        }

        World.SETTING_GROUP = {
            CAMERA: 'Camera',
            AMBIENT: 'Ambient Light'
        };

        World.SETTING = {

            CAM: {

                TYPE: {
                    NAME: 'Type',
                    PERSPECTIVE: 'Perspective',
                    ORTHOGRAPHIC: 'Orthographic',
                    STEREO: 'Stereo'
                },

                FOV: {
                    NAME: 'FOV',
                    DEFAULT: 45,
                    MIN: 0,
                    MAX: 180,
                    STEP: 1
                },

                ASPECT: {
                    NAME: 'Aspect',
                    FOUR_BY_THREE: '4:3',
                    SIXTEEN_BY_NINE: '16:9'
                },

                NEAR: {
                    NAME: 'Near',
                    DEFAULT: 1,
                    MIN: 1,
                    MAX: 180,
                    STEP: 1
                },

                FAR: {
                    NAME: 'Far',
                    DEFAULT: 100000,
                    MIN: 1,
                    MAX: 100000,
                    STEP: 100
                },

                LEFT: {
                    NAME: 'Left',
                    DEFAULT: 100000,
                    MIN: 1,
                    MAX: 100000,
                    STEP: 100
                },

                RIGHT: {
                    NAME: 'Right',
                    DEFAULT: 100000,
                    MIN: 1,
                    MAX: 100000,
                    STEP: 100
                },

                TOP: {
                    NAME: 'Top',
                    DEFAULT: 2000,
                    MIN: 1,
                    MAX: 100000,
                    STEP: 100
                },

                BOTTOM: {
                    NAME: 'Bottom',
                    DEFAULT: 100000,
                    MIN: 1,
                    MAX: 100000,
                    STEP: 100
                },

                X: {
                    NAME: 'X Position',
                    DEFAULT: 0,
                    MIN: 0,
                    MAX: 100000,
                    STEP: 100
                },

                Y: {
                    NAME: 'Y Position',
                    DEFAULT: 0,
                    MIN: 0,
                    MAX: 100000,
                    STEP: 100
                },

                Z: {
                    NAME: 'Z Position',
                    DEFAULT: 200,
                    MIN: 0,
                    MAX: 100000,
                    STEP: 100
                },

            },

            AMBIENT: {

                COLOR: {
                    NAME: 'Color',
                    DEFAULT: '404040'
                },

                INTENSITY: {
                    NAME: 'Intensity',
                    DEFAULT: 0.5,
                    MIN: 0,
                    MAX: 1,
                    STEP: 0.1
                }
            }

        };

        World.getDefaultSettings = function() {

            // SETTINGS
            var settings = [];

            // CAMERA SETTINGS GROUP
            var cam_group = new SettingGroup( World.SETTING_GROUP.CAMERA );
            settings.push( cam_group );

            // AMBIENT LIGHT SETTINGS GROUP
            var amb_light_group = new SettingGroup( World.SETTING_GROUP.AMBIENT );
            settings.push( amb_light_group );

            // CAMERA TYPE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.TYPE.NAME,
                    Setting.TYPE.ENUM,
                    World.SETTING.CAM.TYPE.PERSPECTIVE,
                    World.SETTING.CAM.TYPE.PERSPECTIVE,
                    null, null, null, null,
                    [ World.SETTING.CAM.TYPE.PERSPECTIVE, World.SETTING.CAM.TYPE.ORTHOGRAPHIC, World.SETTING.CAM.TYPE.STEREO ]
                )
            );

            // CAMERA FOV
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.FOV.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.FOV.DEFAULT,
                    World.SETTING.CAM.FOV.DEFAULT,
                    World.SETTING.CAM.FOV.MIN,
                    World.SETTING.CAM.FOV.MAX,
                    World.SETTING.CAM.FOV.STEP
                )
            );

            // CAMERA PERSPECTIVE ASPECT RATIO
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.ASPECT.NAME,
                    Setting.TYPE.ENUM,
                    World.SETTING.CAM.ASPECT.SIXTEEN_BY_NINE,
                    World.SETTING.CAM.ASPECT.SIXTEEN_BY_NINE,
                    null, null, null, null,
                    [ World.SETTING.CAM.ASPECT.SIXTEEN_BY_NINE, World.SETTING.CAM.ASPECT.FOUR_BY_THREE ]
                )
            );
            // CAMERA NEAR PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.NEAR.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.NEAR.DEFAULT,
                    World.SETTING.CAM.NEAR.DEFAULT,
                    World.SETTING.CAM.NEAR.MIN,
                    World.SETTING.CAM.NEAR.MAX,
                    World.SETTING.CAM.NEAR.STEP
                )
            );

            // CAMERA FAR PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.FAR.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.FAR.DEFAULT,
                    World.SETTING.CAM.FAR.DEFAULT,
                    World.SETTING.CAM.FAR.MIN,
                    World.SETTING.CAM.FAR.MAX,
                    World.SETTING.CAM.FAR.STEP
                )
            );

            // CAMERA LEFT ORTHO CAM PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.LEFT.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.LEFT.DEFAULT,
                    World.SETTING.CAM.LEFT.DEFAULT,
                    World.SETTING.CAM.LEFT.MIN,
                    World.SETTING.CAM.LEFT.MAX,
                    World.SETTING.CAM.LEFT.STEP
                )
            );

            // CAMERA RIGHT ORTHO CAM PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.RIGHT.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.RIGHT.DEFAULT,
                    World.SETTING.CAM.RIGHT.DEFAULT,
                    World.SETTING.CAM.RIGHT.MIN,
                    World.SETTING.CAM.RIGHT.MAX,
                    World.SETTING.CAM.RIGHT.STEP
                )
            );

            // CAMERA TOP ORTHO CAM PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.TOP.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.TOP.DEFAULT,
                    World.SETTING.CAM.TOP.DEFAULT,
                    World.SETTING.CAM.TOP.MIN,
                    World.SETTING.CAM.TOP.MAX,
                    World.SETTING.CAM.TOP.STEP
                )
            );

            // CAMERA BOTTOM ORTHO CAM PLANE
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.BOTTOM.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.BOTTOM.DEFAULT,
                    World.SETTING.CAM.BOTTOM.DEFAULT,
                    World.SETTING.CAM.BOTTOM.MIN,
                    World.SETTING.CAM.BOTTOM.MAX,
                    World.SETTING.CAM.BOTTOM.STEP
                )
            );

            // CAMERA X POSITION
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.X.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.X.DEFAULT,
                    World.SETTING.CAM.X.DEFAULT,
                    World.SETTING.CAM.X.MIN,
                    World.SETTING.CAM.X.MAX,
                    World.SETTING.CAM.X.STEP
                )
            );

            // CAMERA Y POSITION
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.Y.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.Y.DEFAULT,
                    World.SETTING.CAM.Y.DEFAULT,
                    World.SETTING.CAM.Y.MIN,
                    World.SETTING.CAM.Y.MAX,
                    World.SETTING.CAM.Y.STEP
                )
            );

            // CAMERA Z POSITION
            cam_group.addSetting(
                new Setting(
                    World.SETTING.CAM.Z.NAME,
                    Setting.TYPE.INTEGER,
                    World.SETTING.CAM.Z.DEFAULT,
                    World.SETTING.CAM.Z.DEFAULT,
                    World.SETTING.CAM.Z.MIN,
                    World.SETTING.CAM.Z.MAX,
                    World.SETTING.CAM.Z.STEP
                )
            );

            // AMBIENT LIGHT COLOR
            amb_light_group.addSetting(
                new Setting(
                    World.SETTING.AMBIENT.COLOR.NAME,
                    Setting.TYPE.COLOR,
                    World.SETTING.AMBIENT.COLOR.DEFAULT,
                    World.SETTING.AMBIENT.COLOR.DEFAULT
                )
            );

            // AMBIENT LIGHT INTENSITY
            amb_light_group.addSetting( // Setting( name, type, sane, value, min, max, step, asset_type, enum_list )
                new Setting(
                    World.SETTING.AMBIENT.INTENSITY.NAME,
                    Setting.TYPE.FLOAT,
                    World.SETTING.AMBIENT.INTENSITY.DEFAULT,
                    World.SETTING.AMBIENT.INTENSITY.DEFAULT,
                    World.SETTING.AMBIENT.INTENSITY.MIN,
                    World.SETTING.AMBIENT.INTENSITY.MAX,
                    World.SETTING.AMBIENT.INTENSITY.STEP
                )
            );


            return settings;

        };


        /**
         * Get a new World instance from a database representation
         * @param o
         * @returns {World}
         */
        World.fromObject = function(o) {
            var plugins = o.plugins
                ? o.plugins.map( plugin => Plugin.fromObject(plugin) )
                : null;

            var settings = o.settings
                ? o.settings.map( setting => setting.is_group ? SettingGroup.fromObject(setting) : Setting.fromObject(setting) )
                : null;

            return new World(
                o.name,
                plugins,
                settings
            );
        };

        /**
         * Get a database representation of this World instance
         * @returns {object}
         */
        World.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this World instance
         * @returns {string}
         */
        World.prototype.toString = function() {
            return [
                this.name,
                this.plugins && this.pluginsIsValid() ? this.plugins.reduce( (prev, plugin) => prev + (prev ? ", ": "") + plugin.toString() ) : "",
                this.settings && this.settingsIsValid() ? this.settings.reduce( (prev, setting) => prev + (prev ? ", ": "") + setting.toString()) : ""
            ].join(', ');
        };

        /**
         * Is this World instance's name field valid?
         * @returns {boolean|*}
         */
        World.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this World instance's plugins field valid?
         * Must be an array of valid Plugin instances (at least one)
         * @returns {boolean|*}
         */
        World.prototype.pluginsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    Array.isArray(this.plugins) &&
                    this.plugins.length > 0 &&
                    this.plugins.reduce( (prev, plugin) => prev && plugin.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this World instance's settings field valid?
         * Must be an array of valid SettingGroup instances (at least one)
         * @returns {boolean|*}
         */
        World.prototype.settingsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    Array.isArray(this.settings) &&
                    this.settings.length > 0 &&
                    this.settings.reduce( (prev, setting) => prev && setting.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Add a Plugin to this World instance's plugins collection
         * @param plugin
         */
        World.prototype.addPlugin = function( plugin ) {
            if ( !this.plugins ) this.plugins = [];
            if ( Object.getPrototypeOf( plugin ) === Plugin.prototype ) {
                this.plugins.unshift( plugin );
            }
        };

        /**
         * Add a settings group to this World instance's settings collection
         * @param group
         */
        World.prototype.addSettingGroup = function( group ) {
            if ( !this.settings ) this.settings = [];
            if ( Object.getPrototypeOf( group ) === SettingGroup.prototype ) {
                this.settings.unshift( group );
            }
        };

        /**
         * Get a settings group by name from this World instance's settings collection
         * @param group
         */
        World.prototype.getSettingGroup = function( name ) {
            var group = ( this.settings )
                ? this.settings.find( item => item.name === name )
                : null;
            return group;
        };

        /**
         * Is this World instance valid?
         * @returns {boolean|*}
         */
        World.prototype.isValid = function() {
            return (
                this.nameIsValid() &&
                this.pluginsIsValid() &&
                this.settingsIsValid()
            );
        };

        /**
         * Does this World's plugins array contain a plugin with the same id as the given one?
         * @returns {boolean|*}
         */
        World.prototype.hasPlugin = function( plugin ) {
            return (
                this.plugins && this.pluginsIsValid()
                    ? this.plugins.reduce( (prev, item) => prev || item.id === plugin.id, false )
                    : false
            )
        };

        /**
         * Get a WorldContext for this World instance
         * @returns {*}
         */
        World.prototype.getContext = function() {
            return new WorldContext( this.name, this.settings );
        };

        /**
         * Get the context list for this World instance
         * @returns {Array}
         */
        World.prototype.getContextList = function() {
            let wc = this.getContext();
            return this.plugins.map( plugin => ( { world:  wc, plugin: plugin.getContext() } ) );
        };

        /**
         * Get the function matrix for this World instance
         * @returns {Array}
         */
        World.prototype.getFunctionList = function() {
            return this.plugins.map( plugin => ( {
                    setup:   plugin.getSetupFunction(),
                    render:  plugin.getRenderFunction(),
                    destroy: plugin.getDestroyFunction()
                } )
            );
        };

        return World;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = World;
    } else {
        window.World = World;
    }

})();