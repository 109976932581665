/**
 * Config Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // LIKE SERVICE RESPONSES
        .constant('CONFIG_SERVICE_RESPONSES', {
            LOAD_CONFIG: {
                SUCCESS: 'load-config-success',
                FAILURE: 'load-config-error'
            }
        })

        .factory(
            'ConfigService',
            [
                'BroadcastService',
                'DatabaseService',
                'CONFIG_SERVICE_RESPONSES',
                ConfigServiceFactory
            ]
        );

    // Factory Method
    function ConfigServiceFactory(BroadcastService,
                                DatabaseService,
                                CONFIG_SERVICE_RESPONSES)
    {
        let instance = {};

        instance.loadConfig = loadConfig;

        return instance;

        /**
         * Load configuration
         */
        function loadConfig()
        {
            // LOAD
            DatabaseService.getConfigRef().once('value').then(success).catch(failure);

            // SUCCESS
            function success(snapshot) {
                let config = snapshot.val();
                BroadcastService.send(CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.SUCCESS, config);
            }

            // FAILURE
            function failure(error) {
                BroadcastService.send(CONFIG_SERVICE_RESPONSES.LOAD_CONFIG.FAILURE, error);
            }
        }

    }
})(); // IIFE keeps global scope clean