/**
 * Sinewav3 Schema Entity: Profile
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Profile = (function() {

        /**
         * Constructor
         * @param uid
         * @param email
         * @param display
         * @param first
         * @param last
         * @param photo_url
         * @param plan
         * @param address
         * @param is_admin
         * @constructor
         */
        function Profile(uid, email, display, first, last, photo_url, site, bio, plan, address, is_admin) {
            this.uid = uid;
            this.email = email;
            this.name = new NameToken(display, first, last);
            this.photo_url = photo_url;
            this.plan = plan; /* Plan instance */
            this.address = address; /* Location instance */
            this.site = site;
            this.bio = bio;
            this.is_admin = (typeof is_admin === 'undefined')? false : is_admin;
        }

        /**
         * Get a new Profile instance from a database representation
         * @param o
         * @returns {Profile}
         */
        Profile.fromObject = function(o) {
            var address = o.address ? new Location.fromObject(o.address) : null;
            var plan = o.plan ? Plan.fromObject(o.plan) : null;
            return new Profile(
                o.uid,
                o.email,
                o.name.display,
                o.name.first,
                o.name.last,
                o.photo_url,
                o.site,
                o.bio,
                plan,
                address,
                o.is_admin);
        };

        /**
         * Get a database representation of this Profile instance
         * @returns {object}
         */
        Profile.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Profile instance
         * @returns {boolean}
         */
        Profile.prototype.toString = function() {
            return [
                this.uidIsValid() ? this.uid : "",
                this.emailIsValid() ? this.email : "",
                this.nameIsValid() ? this.name.toString() : "",
                this.planIsValid() ? this.plan.toString() : "",
                this.address ? this.address.toString() : "",
                this.photo_url ? this.photo_url : "",
                this.site ? this.site : "",
                this.bio ? this.bio : "",
                this.is_admin ? "is_admin" : ""
            ].join(', ');
        };

        /**
         * Get a UserToken instance referring to this Profile
         * @returns {UserToken}
         */
        Profile.prototype.getToken = function() {
            return new UserToken( this.uid, this.name, this.photo_url );
        };

        /**
         * Is this Profile instance's uid field valid?
         * @returns {boolean}
         */
        Profile.prototype.uidIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.uid !== null &&
                    typeof this.uid !== 'undefined' &&
                    typeof this.uid === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Profile instance's email field valid?
         * @returns {boolean}
         */
        Profile.prototype.emailIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.email !== 'undefined' && this.email !== null &&
                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Profile instance's name field valid?
         * @returns {boolean}
         */
        Profile.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.name !== 'undefined' && this.name !== null &&
                    Object.getPrototypeOf( this.name ) === NameToken.prototype &&
                    this.name.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Profile instance's plan field valid?
         * @returns {boolean}
         */
        Profile.prototype.planIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.plan !== 'undefined' && this.name !== null &&
                    Object.getPrototypeOf( this.plan ) === Plan.prototype &&
                    this.plan.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Profile instance valid?
         * @returns {boolean}
         */
        Profile.prototype.isValid = function() {
            return (
                this.uidIsValid() &&
                this.emailIsValid() &&
                this.nameIsValid()
            );
        };

        return Profile;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Profile;
    } else {
        window.Profile = Profile;
    }

})();