(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'wave',
            [
                'CHROME',
                WaveFactory
            ]
        );

    // Factory Method
    function WaveFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            templateUrl: CHROME.PREFIX + CHROME.WAVE + CHROME.POSTFIX
        };

    }
})();



