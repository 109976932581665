/**
 * Wave Controller
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Controller to the module
    angular.module("Sinewav3.Client.Shell")
        .controller(
            'WaveController',
            [
                '$scope',
                '$timeout',
                'EVENTS',
                WaveController
            ]
        );

    // Constructor
    function WaveController($scope, $timeout, EVENTS)
    {
        // Construct and initialize the instance
        let instance = this;

        instance.onDestroy = onDestroy;
        instance.createGraph = createGraph;
        instance.pathFunction = pathFunction;
        instance.animate = animate;

        initialize();

        // Initialize the controller
        function initialize() {

            // Set event listeners, hanging onto the returned listener removal functions
            $scope.listenerCleanup = [];
            $scope.listenerCleanup.push( $scope.$on( EVENTS.DESTROY, instance.onDestroy) );

            // Get the svg path objects
            $scope.path = document.getElementsByClassName('sine-wave');
            $scope.reference = document.getElementsByClassName('sine-wave-reference');

            // Initialize the wave values
            $scope.x = 0;
            $scope.offset = 0;
            $scope.shadow = true;
            $scope.framerate = 60;
            $scope.frequency = getRandomSetting(.75, 7); //0.25;
            $scope.amplitude = getRandomSetting( 1.5, 5 ) //1;
            $scope.increment = 5;
            $scope.rotation = 0;
            $scope.run = true;
            // Create the static shadow
            instance.createGraph( $scope.reference );

            // Start rendering
            requestAnimationFrame( instance.animate );


            function getRandomSetting(min, max){
                return Math.floor( Math.random() *( max - min + 1 ) + min ) + .25;
            }

        }

        /**
         * Remove event listeners when the controller is destroyed
         * Also hide the FAB and close the viewer as we are navigating away from the Asset page
         */
        function onDestroy(){

            $scope.run = false;

            let i, removeListener;
            for (i=0; i < $scope.listenerCleanup.length; i++){
                removeListener = $scope.listenerCleanup[i];
                removeListener();
            }

            $scope.path = null;
            $scope.reference = null;

        }

        /**
         * Function to determine curve
         * @param x
         * @returns {*}
         */
        function pathFunction (x) {
            //return 0.2*(Math.sin(Math.sqrt(x)-$scope.offset))*x;
            //return (Math.sin(Math.sqrt(x*$scope.frequency)-$scope.offset))*x*(0.1 * $scope.amplitude);
            return (Math.sin(Math.sqrt(x*$scope.frequency)-$scope.offset))*x*(0.1 * $scope.amplitude);
        }

        /**
         * Create the graph for a wave
         * @param wave
         */
        function createGraph( wave ) {
            if ( !wave ) return;
            $scope.x = 0;
            let point, data = [
                {
                    'type': 'M',
                    'values': [0,150]
                }
            ];
            while ($scope.x < 300) {
                point = {
                    x: $scope.x,
                    y: 150 - instance.pathFunction( $scope.x )
                };
                data.push({
                    'type': 'L',
                    'values': [
                        point.x,
                        point.y
                    ]
                });
                $scope.x += 1;
            }
            wave[0].setPathData(data);
        }

        /**
         * Render a frame
         */
        function animate() {

            if ($scope.run && $scope.path && $scope.path.length) {
                $scope.offset += ( $scope.increment / $scope.framerate );
                instance.createGraph($scope.path);
                $timeout(
                    () => $scope.requestId = requestAnimationFrame( instance.animate ),
                    (1000 / $scope.framerate)
                );
            } else {
                cancelAnimationFrame( $scope.requestId );
            }
        }

    }
})(); // IIFE keeps global scope clean
