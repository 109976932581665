/**
 * Asset Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // ASSET SERVICE RESPONSES
        .constant('ASSET_SERVICE_RESPONSES', {
            LIST_ASSETS: {
                SUCCESS: 'asset-list-success',
                FAILURE: 'asset-list-error'
            },
            LIST_PUBLIC_ASSETS: {
                SUCCESS: 'asset-list-public-success',
                FAILURE: 'asset-list-public-error'
            },
            CREATE_ASSET: {
                SUCCESS: 'asset-create-success',
                FAILURE: 'asset-create-error'
            },
            DELETE_ASSET: {
                SUCCESS: 'asset-delete-success',
                FAILURE: 'asset-delete-error'
            },
            LOAD_ASSET: {
                SUCCESS: 'asset-load-success',
                FAILURE: 'asset-load--error'
            },
            STORE_PROFILE_PHOTO: {
                SUCCESS: 'asset-store-profile-photo-success',
                FAILURE: 'asset-store-profile-photo--error'
            }
        })

        .factory(
            'AssetService',
            [
                'BroadcastService',
                'DatabaseService',
                'StorageService',
                'ASSET_SERVICE_RESPONSES',
                AssetServiceFactory
            ]
        );

    // Factory Method
    function AssetServiceFactory(BroadcastService,
                                 DatabaseService,
                                 StorageService,
                                 ASSET_SERVICE_RESPONSES){
        let instance = {};

        instance.listAssets = listAssets;
        instance.listPublicAssets = listPublicAssets;
        instance.createAsset = createAsset;
        instance.createAssetGroup = createAssetGroup;
        instance.deleteAsset = deleteAsset;
        instance.deleteAssetGroup = deleteAssetGroup;
        instance.loadAsset = loadAsset;
        instance.storeProfilePhoto = storeProfilePhoto;

        return instance;

        /**
         * Retrieve the list of assets
         * Automatically receives updates when assets are added or removed
         * @param uid
         */
        function listAssets(uid) {
            // Listen for changes to the user's assets
            DatabaseService.getAssetsRef()
                .orderByChild('owner/uid')
                .equalTo(uid)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let asset, assets = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        let data = childSnapshot.val();
                        asset = data.is_group ? AssetGroup.fromObject(data) : Asset.fromObject(data);
                        assets.push(asset);
                    }
                );

                BroadcastService.send(ASSET_SERVICE_RESPONSES.LIST_ASSETS.SUCCESS, assets);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(ASSET_SERVICE_RESPONSES.LIST_ASSETS.FAILURE, error);
            }
        }

        /**
         * Retrieve the list of public assets
         * Automatically receives updates when assets are added or removed
         */
        function listPublicAssets() {
            // Listen for changes to the public assets
            DatabaseService.getAssetsRef()
                .orderByChild('is_public')
                .equalTo(true)
                .on('value', onChange, onCancel );

            // When the list is loaded or changes, update the view
            function onChange(snapshot) {
                let asset, assets = [];
                snapshot.forEach(
                    function(childSnapshot) {
                        let data = childSnapshot.val();
                        asset = data.is_group ? AssetGroup.fromObject(data) : Asset.fromObject(data);
                        assets.push(asset);
                    }
                );

                BroadcastService.send(ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.SUCCESS, assets);
            }

            // Failed to load list
            function onCancel(error) {

                BroadcastService.send(ASSET_SERVICE_RESPONSES.LIST_PUBLIC_ASSETS.FAILURE, error);
            }
        }

        /**
         * Create an asset
         * @param name
         * @param type
         * @param userToken
         * @param file
         * @param duration
         */
        function createAsset(userToken, type, name, file, duration) {
            // Create an ID for the asset
            let id = DatabaseService.getAssetsRef().push().key;

            // UPLOAD THE FILE
            let path = StorageService.getAssetPath(userToken.uid, type, id, file.name);
            let uploadTask = StorageService.getRef(path).put(file);
            uploadTask.then(onSuccess, onFailure);

            // HANDLE SUCCESS
            function onSuccess(snapshot) {

                // PREPARE ASSET
                let url = snapshot.downloadURL;
                let asset = new Asset(id, type, name, userToken, file.name, file.size, false, url, duration);

                // SAVE ASSET
                DatabaseService.getAssetsRef().child(asset.id).set(asset.toObject(), onComplete);

                // ANNOUNCE
                function onComplete(error){
                    if (error){
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error);
                    } else {
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS, asset);
                    }
                }
            }

            // HANDLE FAILURE
            function onFailure(error) {
                BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error)
            }
        }

        /**
         * Create an asset group
         * @param userToken
         * @param type
         * @param name
         * @param files
         * @param assetType
         * @param namingFn
         */
        function createAssetGroup(userToken, type, name, files, assetType, namingFn) {
            // Create an ID for the asset
            let id = DatabaseService.getAssetsRef().push().key;

            // UPLOAD THE FILES
            let path = StorageService.getAssetPath(userToken.uid, type, id);
            let urls = {};
            let promises = files.map( file => StorageService.getRef(path).child(file.name)
                .put(file).then( snapshot => urls[file.name] = snapshot.downloadURL) );
            Promise.all(promises).then(onSuccess, onFailure);

            // HANDLE SUCCESS
            function onSuccess() {
                // PREPARE ASSET GROUP
                let list = files.map( (file) => new Asset(id, assetType, namingFn(file.name), userToken, file.name, file.size, false, urls[file.name]) );
                let group = new AssetGroup(id, type, name, userToken, list, false);
                if (group.type === AssetGroup.TYPE.CUBE) group.sortAssetsAsCube();

                // SAVE ASSET GROUP
                DatabaseService.getAssetsRef().child(group.id).set(group.toObject(), onComplete);

                // ANNOUNCE
                function onComplete(error){
                    if (error){
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error);
                    } else {
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.SUCCESS, group);
                    }
                }
            }

            // HANDLE FAILURE
            function onFailure(error) {
                BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error)
            }
        }

        /**
         * Delete an asset
         * @param asset
         */
        function deleteAsset(asset) {
            let path = StorageService.getAssetPath(asset.owner.uid, asset.type, asset.id, asset.filename);
            StorageService.getRef(path).delete()
                .then(
                    () => {
                        // DELETE ASSET
                        DatabaseService.getAssetsRef().child(asset.id).remove(onComplete);

                        // ANNOUNCE
                        function onComplete(error) {
                            if (error) {
                                BroadcastService.send(ASSET_SERVICE_RESPONSES.DELETE_ASSET.FAILURE, error);
                            } else {
                                BroadcastService.send(ASSET_SERVICE_RESPONSES.DELETE_ASSET.SUCCESS);
                            }
                        }
                    }
                )
                .catch(
                    // REPORT FAILURE
                    () => BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error)
                );
        }

        /**
         * Delete an asset group
         * @param asset
         */
        function deleteAssetGroup(asset) {
            let path = StorageService.getAssetPath(asset.owner.uid, asset.type, asset.id);
            let promises = asset.assets.map( asset => StorageService.getRef(path).child(asset.filename).delete() );
            Promise.all(promises).then(onSuccess, onFailure);

            function onSuccess() { // DELETE ASSET GROUP
                DatabaseService.getAssetsRef().child(asset.id).remove(onComplete);

                // ANNOUNCE
                function onComplete(error) {
                    if (error) {
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.DELETE_ASSET.FAILURE, error);
                    } else {
                        BroadcastService.send(ASSET_SERVICE_RESPONSES.DELETE_ASSET.SUCCESS);
                    }
                }
            }

            function onFailure( error ) {// REPORT FAILURE
                BroadcastService.send(ASSET_SERVICE_RESPONSES.CREATE_ASSET.FAILURE, error);
            }
        }

        /**
         * Load an asset (as yet unused)
         * @param asset id
         */
        function loadAsset(id) {
            // LOAD
            DatabaseService.getAssetsRef().child(id).once('value').then(success).catch(failure);

            // SUCCESS
            function success(snapshot) {
                let asset = Asset.fromObject(snapshot.val());
                BroadcastService.send(ASSET_SERVICE_RESPONSES.LOAD_ASSET.SUCCESS, asset);
            }

            // FAILURE
            function failure(error) {
                BroadcastService.send(ASSET_SERVICE_RESPONSES.LOAD_ASSET.FAILURE, error);
            }
        }

        /**
         * Create an asset
         * @param name
         * @param type
         * @param userToken
         * @param file
         */
        function storeProfilePhoto(uid, dataURI) {

            // UPLOAD THE FILE
            let file = StorageService.convertDataURItoBlob( dataURI );
            let path = StorageService.getProfilePhotoPath(uid);
            let uploadTask = StorageService.getRef(path).put(file);
            uploadTask.then(onSuccess, onFailure);

            // HANDLE SUCCESS
            function onSuccess(snapshot) {

                // RETURN THE USER TOKEN WITH THE DOWNLOAD URL
                let photo_url = snapshot.downloadURL;
                BroadcastService.send(ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.SUCCESS, photo_url);
            }

            // HANDLE FAILURE
            function onFailure(error) {
                BroadcastService.send(ASSET_SERVICE_RESPONSES.STORE_PROFILE_PHOTO.FAILURE, error)
            }
        }
    }
})(); // IIFE keeps global scope clean