// Shell - Chrome Configuration
(function (){

    angular.module('Sinewav3.Client.Shell')

        // Configure the theme
        .config( ['$mdThemingProvider', configureTheme] )

        // Configure and load the icons
        .config( ['$mdIconProvider', configureIcons] )

        // Dictionary of chrome-related configuration
        .constant('CHROME', {
            PREFIX: 'shell/ui/chrome',
            POSTFIX: '-tmpl.html',

            LAYOUT: '/layout/shell-layout',
            INFO: '/info/site-info',

            PAGE: 'page',

            IMG_VIEWER: 'imageViewer',
            CUBE_VIEWER: 'cubeViewer',
            FONT_VIEWER: 'fontViewer',
            MODEL_VIEWER: 'modelViewer',
            VISUALIZER_PANE: 'visualizer-pane',

            FN_SETUP: 'fn_setup',
            FN_RENDER: 'fn_render',
            FN_DESTROY: 'fn_destroy',

            FULLSCREEN: '/fullscreen/fullscreen-toggle',
            WEBGL_WARNING: '/webgl/webgl-warning',

            WAVE: '/wave/wave',

            IMAGES: {
                SIGN_OUT: 'assets/image/sign-out.png',
            },

            MENU: {
                ANON: '/menu/anon/anon-menu',
                ADMIN: '/menu/admin/admin-menu',
                USER: '/menu/user/user-menu'
            },

            TOOLBAR: {
                ANON: '/toolbar/anon/anon-toolbar',
                ADMIN: '/toolbar/admin/admin-toolbar',
                USER: '/toolbar/user/user-toolbar'
            },

            PROJECT: {
                TOOLBAR: {
                    DRILL_DOWN: '/project/toolbar/project-drill-down',
                    SETTING_EDITOR: '/project/toolbar/setting-editor'
                },
                SIDE_NAV: {
                    MENU: '/project/sidenav/settings-sidenav-menu'
                },
                CONTROL: {
                    ASSET: '/project/control/asset-control',
                    BOOLEAN: '/project/control/boolean-control',
                    COLOR: '/project/control/color-control',
                    EASE: '/project/control/ease-control',
                    ENUM: '/project/control/enum-control',
                    FLOAT: '/project/control/float-control',
                    INTEGER: '/project/control/integer-control',
                    LOOP: '/project/control/loop-control',
                    MODULATOR: '/project/control/modulator-control',
                    STRING: '/project/control/string-control'
                }
            },

            TABS: {
                TEMPLATE: '/tabs/nav-tabs',
                ANON: {
                    HOME: {label: 'Home', section: 'LANDING', page: 'HOME'},
                    PLANS: {label: 'Plans', section: 'LANDING', page: 'PLANS'},
                    MARKET: {label: 'Market', section: 'LANDING', page: 'MARKET'}
                },
                ADMIN: {
                    PROJECTS: {label: 'Projects', section: 'ACCOUNT', page: 'PROJECTS'},
                    ASSETS: {label: 'Assets', section: 'ACCOUNT', page: 'ASSETS'},
                    PLUGINS: {label: 'Plugins', section: 'ACCOUNT', page: 'PLUGINS'}
                },
                USER: {
                    PROJECTS: {label: 'Projects', section: 'ACCOUNT', page: 'PROJECTS'},
                    ASSETS: {label: 'Assets', section: 'ACCOUNT', page: 'ASSETS'},
                    PLUGINS: {label: 'Plugins', section: 'ACCOUNT', page: 'PLUGINS'}
                }
            },

            FAB: {
                BUTTON: '/fab/button/fab-button',
                MENU: '/fab/menu/fab-menu',
                ACTIONS: {
                    RUN: {LABEL: 'Run', ACTION: 'action-run', ICON: 'play_arrow'},
                    PAUSE: {LABEL: 'Pause', ACTION: 'action-pause', ICON: 'pause_circle_outline'},
                    RESUME: {LABEL: 'Resume', ACTION: 'action-resume', ICON: 'play_arrow'},
                    CLOSE: {LABEL: 'Close', ACTION: 'action-close', ICON: 'close'},
                    SAVE: {LABEL: 'Save', ACTION: 'action-save', ICON: 'save'},
                    SNAPSHOT: {LABEL: 'Snapshot', ACTION: 'action-snapshot', ICON: 'photo_camera'},
                    RENDER: {LABEL: 'Render', ACTION: 'action-render', ICON: 'add_to_queue'},
                    PUBLISH: {LABEL: 'Publish', ACTION: 'action-publish', ICON: 'publish'},
                    COPY: {LABEL: 'Copy', ACTION: 'action-copy', ICON: 'content_copy'},
                    CANCEL: {LABEL: 'Cancel', ACTION: 'action-cancel', ICON: 'cancel'},
                    DELETE: {LABEL: 'Delete', ACTION: 'action-delete', ICON: 'delete_forever'},
                    DOWNLOAD: {LABEL: 'Download', ACTION: 'action-download', ICON: 'file_download'},
                    SETTINGS: {LABEL: 'Settings', ACTION: 'action-settings', ICON: 'settings'},
                    HELP: {LABEL: 'Help', ACTION: 'action-help', ICON: 'help'},
                    NEW_PROJ: {LABEL: 'New Project', ACTION: 'action-new-project', ICON: 'create'},
                    NEW_PLUG: {LABEL: 'New Plugin', ACTION: 'action-new-plugin', ICON: 'create'},
                    NEW_ASSET: {LABEL: 'New Asset', ACTION: 'action-new-asset', ICON: 'cloud_upload'},
                    LIKE_PLUGIN: {LABEL: 'Like Plugin', ACTION: 'action-like-plugin', ICON: 'favorite_border'},
                    UNLIKE_PLUGIN: {LABEL: 'Unlike Plugin', ACTION: 'action-unlike-plugin', ICON: 'favorite'},
                    EDIT_PROFILE: {LABEL: 'Edit Profile', ACTION: 'action-edit-profile', ICON: 'create'},
                    APPROVE: {LABEL: 'Approve', ACTION: 'action-approve', ICON: 'thumb_up'},
                    REJECT: {LABEL: 'Reject', ACTION: 'action-reject', ICON: 'thumb_down'},
                    SHARE: {LABEL: 'Share', ACTION: 'action-share', ICON: 'share'}
                }
            },

            TOAST: {
                POSITION: {
                    bottom: false,
                    top: true,
                    left: false,
                    right: true
                },
                DELAY: 2000
            },

            ICON: { // all in md font set except where begins with fa-
                STATUS: {
                    READY: 'assignment_turned_in',
                    NEEDS_WORK: 'assignment_late',
                    SUBMITTED: "thumbs_up_down",
                    APPROVED: "thumb_up",
                    REJECTED: "thumb_down"
                },
                TYPE: {
                    AUDIO: 'audiotrack',
                    IMAGE: 'photo',
                    MODEL: 'accessibility',
                    VIDEO: 'movie_creation',
                    FRAME: 'photo_camera',
                    FONT: 'font_download',
                    CUBE: 'collections',
                },
                WORLD: 'language',
                GROUP: 'group_work',
                SETTING: 'settings',
                SETTINGS: 'settings',
                PLUGIN: 'fa-plug',
                ENUM: 'list',
                MODULATOR: 'fa-heartbeat',
                WARNING: 'warning',
                RESET: 'settings_backup_restore',
                VISUALIZE: 'visibility',
                LIKE: 'favorite',
                AUTHOR: 'account_box',
                CLOSE: 'close',
                PROFILE: 'person',
                UPLOAD: 'cloud_upload',
                SAVE: 'save',
                EASE: 'show_chart',
                LOOP: 'loop'
            },

            COLOR_PICKER: { // https://ruhley.github.io/angular-color-picker/

                // html attributes
                required: true,
                disabled: false,
                placeholder: "Choose Color",
                inputClass: 'materialized-color-picker',
                id: undefined,
                name: undefined,

                // color
                format: 'hex',
                restrictToFormat: true,
                hue: true,
                saturation: true,
                lightness: true, // Note: In the square mode this is HSV and in round mode this is HSL
                alpha: true,
                case: 'upper',

                // swatch
                swatch: true,
                swatchPos: 'left',
                swatchBootstrap: false,
                swatchOnly: false,

                // popup
                round: false,
                pos: 'bottom right',
                inline: false,

                // show/hide
                show: {
                    swatch: true,
                    focus: false
                },
                hide: {
                    blur: false,
                    escape: true,
                    click: true
                },

                // buttons
                close: {
                    show: false,
                    label: 'Close',
                    class: ''
                },
                clear: {
                    show: false,
                    label: 'Clear',
                    class: ''
                },
                reset: {
                    show: false,
                    label: 'Reset',
                    class: ''
                }
            }

        } )

        // Account-related scope initialization
        .run(['$rootScope', 'CHROME', initialize]);


    // Scope initialization
    function initialize($rootScope, CHROME) {

        let chrome = {};
        $rootScope.CROME = CHROME;

        // The FAB button and menu configuration
        chrome.fab = {

            // A single floating action button
            button: {
                show: false,
                action: null
            },

            // A horizontal menu of icons, triggered by a floating action button
            menu: {
                show: false,
                trigger: 'menu',
                actions: null,
                sets: {
                    editing: [
                        CHROME.FAB.ACTIONS.CLOSE,
                        CHROME.FAB.ACTIONS.SAVE
                    ],
                    visualizing: [
                        CHROME.FAB.ACTIONS.CLOSE,
                        CHROME.FAB.ACTIONS.PAUSE,
                        CHROME.FAB.ACTIONS.SETTINGS,
                        CHROME.FAB.ACTIONS.SAVE
                    ],
                    paused: [
                        CHROME.FAB.ACTIONS.CLOSE,
                        CHROME.FAB.ACTIONS.RESUME,
                        CHROME.FAB.ACTIONS.SETTINGS,
                        CHROME.FAB.ACTIONS.SAVE,
                        CHROME.FAB.ACTIONS.SNAPSHOT
                    ],
                    visualizing_no_setting: [
                        CHROME.FAB.ACTIONS.CLOSE,
                        CHROME.FAB.ACTIONS.PAUSE
                    ],
                    paused_no_setting: [
                        CHROME.FAB.ACTIONS.CLOSE,
                        CHROME.FAB.ACTIONS.RESUME,
                        CHROME.FAB.ACTIONS.SNAPSHOT
                    ]
                }
            }
        };

        $rootScope.chrome = chrome;

    }

    /**
     * Configure the theme
     * @param $mdThemingProvider
     */
    function configureTheme($mdThemingProvider) {

        // DEFAULT THEME
        $mdThemingProvider.theme('default')
            .primaryPalette('indigo')
            .accentPalette('tabBlue') //.accentPalette('deep-orange')
            .warnPalette('deep-orange'); //.warnPalette('red');

        // WORKAROUND PALETTE FOR TABS old = #2196f3
        $mdThemingProvider.definePalette('tabBlue', {
            '50': '#536DFE',
            '100': '#536DFE',
            '200': '#536DFE',
            '300': '#536DFE',
            '400': '#536DFE',
            '500': '#536DFE',
            '600': '#536DFE',
            '700': '#536DFE',
            '800': '#536DFE',
            '900': '#536DFE',
            'A100': '#536DFE',
            'A200': '#536DFE',
            'A400': '#536DFE',
            'A700': '#536DFE',
            'contrastDefaultColor': 'light',
            'contrastDarkColors': '50 100 200 300 400 A100 A200 A400'
        });

        $mdThemingProvider.theme('tabTheme')
            .primaryPalette('tabBlue')
            .accentPalette('tabBlue');

        $mdThemingProvider.theme('groupTheme').backgroundPalette('indigo').dark();

    }

    /**
     * Configure the icons
     * @param $mdIconProvider
     */
    function configureIcons($mdIconProvider) {
        $mdIconProvider
            .fontSet('md', 'material-icons')
            .fontSet('fa', 'fa');
    }


})();
