/**
 * Share Service
 * (c) 2016-17 Cliff Hall @ Futurescale, Inc
 */
(function() {

    // Add the Service to the module
    angular.module('Sinewav3.Client.Shell')

        // SHARE SERVICE RESPONSES
        .constant('SHARE_SERVICE_RESPONSES', {
            SHARE_PROJECT: {
                SUCCESS: 'share-project-success',
                FAILURE: 'share-project-error'
            },
        })

        .factory(
            'ShareService',
            [
                'BroadcastService',
                'DatabaseService',
                'SHARE_SERVICE_RESPONSES',
                ShareServiceFactory
            ]
        );

    // Factory Method
    function ShareServiceFactory(BroadcastService,
                                 DatabaseService,
                                 SHARE_SERVICE_RESPONSES) {
        let instance = {};

        instance.findShareByAlias = findShareByAlias;
        instance.findShareByProjectId = findShareByProjectId;
        instance.shareProject = shareProject;

        return instance;

        /**
         * Check if the given alias is in use
         * @param alias
         * @returns promise
         */
        function findShareByAlias(alias) {
            return DatabaseService.getProjectSharesRef()
                .child(alias)
                .once('value');
        }

        /**
         * Check if the given project_id has been shared
         * @param project_id
         * @returns promise
         */
        function findShareByProjectId(project_id) {
            return DatabaseService.getProjectSharesRef()
                .orderByChild('project_id')
                .equalTo(project_id)
                .once('value');
        }

        /**
         * Share a Project
         * @param share
         */
        function shareProject(share) {

            // Create like
            let entity = DatabaseService.stripAngularProps(share).toObject();

            // SAVE PROJECT SHARE
            DatabaseService.getProjectSharesRef().child(share.alias).set(entity, onComplete);

            // ANNOUNCE
            function onComplete(error) {
                if (error) {
                    BroadcastService.send(SHARE_SERVICE_RESPONSES.SHARE_PROJECT.FAILURE, error);
                } else {
                    BroadcastService.send(SHARE_SERVICE_RESPONSES.SHARE_PROJECT.SUCCESS, share);
                }
            }
        }
    }
})(); // IIFE keeps global scope clean