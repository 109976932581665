/**
 * Sinewav3 Schema Entity: Entitlement
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Entitlement = (function() {

        /**
         * Constructor
         * @param resource
         * @param quota
         * @param unit
         * @constructor
         */
        function Entitlement(resource, quota, unit) {
            this.resource = resource;
            this.quota = quota;
            this.unit = unit;
        }

        /**
         * Entitlement Resource Constants
         * @type {{DOWNLOADS: string, RENDERS: string, STORAGE: string, SVC_UPLOADS: string, QUEUE_BUMPS: string, CAMERAS: string}}
         */
        Entitlement.RESOURCE = {
            DOWNLOADS:           'downloads',
            RENDERS:             'renders',
            STORAGE:             'storage',
            SVC_UPLOADS:         'video service uploads',
            QUEUE_BUMPS:         'queue bumps',
            WORLDS_PER_PROJECT:  'worlds per project'
        };

        /**
         * Entitlement Unit Constants
         * @type {{BYTES: string, KB: string, MB: string, GB: string, BRANDED: string, UNBRANDED: string, BUMP: string, CAM: string}}
         */
        Entitlement.UNIT = {
            BYTES:     'bytes',
            KB:        'kilobytes',
            MB:        'megabytes',
            GB:        'gigabytes',
            BRANDED:   'branded',
            UNBRANDED: 'unbranded',
            BUMP:      'bump',
            WORLD:     'world'
        };

        /**
         * Get a new Entitlement instance from a database representation
         * @param o
         * @returns {Entitlement}
         */
        Entitlement.fromObject = function(o) {
            return new Entitlement(o.resource, o.quota, o.unit);
        };

        /**
         * Get a database representation of this Entitlement instance
         * @returns {object}
         */
        Entitlement.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Entitlement instance
         * @returns {string}
         */
        Entitlement.prototype.toString = function() {
            return [
                this.quota,
                this.unit,
                this.resource
            ].join(' ');
        };

        /**
         * Is this Entitlement instance's resource field valid?
         * @returns {boolean|*}
         */
        Entitlement.prototype.resourceIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.resource !== 'undefined' &&
                    typeof this.resource === 'string' &&
                    Object.values( Entitlement.RESOURCE ).indexOf( this.resource ) !=-1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Entitlement instance's unit field valid?
         * @returns {boolean|*}
         */
        Entitlement.prototype.unitIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.unit !== 'undefined' &&
                    typeof this.unit === 'string' &&
                    Object.values( Entitlement.UNIT ).indexOf( this.unit ) !=-1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Entitlement instance's quota field valid?
         * @returns {boolean|*}
         */
        Entitlement.prototype.quotaIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.quota !== 'undefined' &&
                    typeof this.quota === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Entitlement instance valid?
         * @returns {boolean|*}
         */
        Entitlement.prototype.isValid = function() {
            return (
                this.resourceIsValid() &&
                this.unitIsValid() &&
                this.quotaIsValid()
            );
        };

        return Entitlement;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Entitlement;
    } else {
        window.Entitlement = Entitlement;
    }

})();