/**
 * Sinewav3 Schema Entity: ProjectToken
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var ProjectToken = (function() {

        /**
         * Constructor
         * @param id
         * @param name
         * @param owner
         * @param description
         * @param is_public
         * @constructor
         */
        function ProjectToken(id, name, owner, description, is_public) {
            this.id = id;
            this.name = name;
            this.owner = owner;
            this.description = description;
            this.is_public = (typeof is_public === 'undefined')? false : is_public;
        }

        /**
         * Get a new ProjectToken instance from a database representation
         * @param o
         * @returns {ProjectToken}
         */
        ProjectToken.fromObject = function(o) {
            var owner = o.owner ? UserToken.fromObject(o.owner) : null;
            return new ProjectToken(o.id, o.name, owner, o.description, o.is_public);
        };

        /**
         * Get a database representation of this ProjectToken instance
         * @returns {object}
         */
        ProjectToken.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this ProjectToken instance
         * @returns {string}
         */
        ProjectToken.prototype.toString = function() {
            return [
                this.idIsValid() ? this.id : "",
                this.nameIsValid() ? this.name : "",
                this.ownerIsValid() ? this.owner.toString() : "",
            ].join(' ');
        };

        /**
         * Is this ProjectToken instance's id field valid?
         * @returns {boolean|*}
         */
        ProjectToken.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectToken instance's name field valid?
         * @returns {boolean|*}
         */
        ProjectToken.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectToken instance's owner field valid?
         * @returns {boolean|*}
         */
        ProjectToken.prototype.ownerIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.owner  !== 'undefined' && this.owner !== null &&
                    Object.getPrototypeOf(this.owner) === UserToken.prototype &&
                    this.owner.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this ProjectToken instance valid?
         * @returns {boolean|*}
         */
        ProjectToken.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.nameIsValid() &&
                this.ownerIsValid()
            );
        };

        return ProjectToken;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = ProjectToken;
    } else {
        window.ProjectToken = ProjectToken;
    }

})();