/**
 * Sinewav3 Schema Entity: Setting
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    let Setting = (function() {

        /**
         * Constructor
         * @param name
         * @param type
         * @param sane
         * @param value
         * @param min
         * @param max
         * @param step
         * @param asset_type
         * @param enum_list
         * @param ease
         * @param loop
         * @constructor
         */
        function Setting(name, type, sane, value, min, max, step, asset_type, enum_list, ease, loop) {
            this.name = name;
            this.type = type;
            this.sane = sane;
            this.value = value;
            this.min = min;
            this.max = max;
            this.step = step;
            this.asset_type = asset_type;
            this.enum_list = enum_list || [];
            this.ease = ease;
            this.loop = loop;
            this.is_group = false;
        }

        /**
         * Setting Type Constants
         * @type {{ASSET: string, BOOLEAN: string, COLOR: string, EASE: string, ENUM: string, FLOAT: string, INTEGER: string, LOOP: string, MODULATOR: string, STRING: string}}
         */
        Setting.TYPE = {
            ASSET: 'asset',
            BOOLEAN: 'boolean',
            COLOR: 'color',
            EASE: 'ease',
            ENUM: 'enum',
            FLOAT: 'float',
            INTEGER: 'integer',
            LOOP: 'loop',
            MODULATOR: 'modulator',
            STRING: 'string'
        };

        /**
         * Built-in Modulator constants
         * @type {{NONE: string, LEFT: string, RIGHT: string, VOL: string, BASS: string, MID: string, HIGH: string}}
         */
        Setting.MODULATORS = {
            NONE: 'None',
            LEFT: 'Audio Left',
            RIGHT: 'Audio Right',
            VOL: 'Audio Volume',
            BASS: 'Audio Bass',
            MID: 'Audio Mid-range',
            HIGH: 'Audio High'
        };

        /**
         * Built-in Ease types
         * @type {{BACK_IN: string, BACK_OUT: string, BACK_IO: string, BOUNCE_IN: string, BOUNCE_OUT: string, BOUNCE_IO: string, CIRC_IN: string, CIRC_OUT: string, CIRC_IO: string, CUBIC_IN: string, CUBIC_OUT: string, CUBIC_IO: string, ELAST_IN: string, ELAST_OUT: string, ELAST_IO: string, EXP_IN: string, EXP_OUT: string, EXP_IO: string, LINEAR: string, QUAD_IN: string, QUAD_OUT: string, QUAD_IO: string, QUART_IN: string, QUART_OUT: string, QUART_IO: string, QUINT_IN: string, QUINT_OUT: string, QUINT_IO: string, SINE_IN: string, SINE_OUT: string, SINE_IO: string}}
         */
        Setting.EASES = {
            BACK_IN: 'Back In',
            BACK_OUT: 'Back Out',
            BACK_IO: 'Back In/Out',
            BOUNCE_IN: 'Bounce In',
            BOUNCE_OUT: 'Bounce Out',
            BOUNCE_IO: 'Bounce In/Out',
            CIRC_IN: 'Circular In',
            CIRC_OUT: 'Circular Out',
            CIRC_IO: 'Circular In/Out',
            CUBIC_IN: 'Cubic In',
            CUBIC_OUT: 'Cubic Out',
            CUBIC_IO: 'Cubic In/Out',
            ELAST_IN: 'Elastic In',
            ELAST_OUT: 'Elastic Out',
            ELAST_IO: 'Elastic In/Out',
            EXP_IN: 'Exponential In',
            EXP_OUT: 'Exponential Out',
            EXP_IO: 'Exponential In/Out',
            LINEAR: 'Linear',
            QUAD_IN: 'Quadratic In',
            QUAD_OUT: 'Quadratic Out',
            QUAD_IO: 'Quadratic In/Out',
            QUART_IN: 'Quartic In',
            QUART_OUT: 'Quartic Out',
            QUART_IO: 'Quartic In/Out',
            QUINT_IN: 'Quintic In',
            QUINT_OUT: 'Quintic Out',
            QUINT_IO: 'Quintic In/Out',
            SINE_IN: 'Sinusoidal In',
            SINE_OUT: 'Sinusoidal Out',
            SINE_IO: 'Sinusoidal In/Out'
        };

        /**
         * Built-in Loop Types
         * @type {{NONE: string, FWD: string, PING: string}}
         */
        Setting.LOOPS = {
            NONE: 'None',
            FORWARD: 'Forward',
            PING_PONG: 'Ping Pong'
        };

        /**
         * Enumerations of Ease types and Loop types
         * @type {{EASE: *[], LOOP: *[]}}
         */
        Setting.ENUMS = {
            EASE: [
                Setting.EASES.BACK_IN,
                Setting.EASES.BACK_OUT,
                Setting.EASES.BACK_IO,
                Setting.EASES.BOUNCE_IN,
                Setting.EASES.BOUNCE_OUT,
                Setting.EASES.BOUNCE_IO,
                Setting.EASES.CIRC_IN,
                Setting.EASES.CIRC_OUT,
                Setting.EASES.CIRC_IO,
                Setting.EASES.CUBIC_IN,
                Setting.EASES.CUBIC_OUT,
                Setting.EASES.CUBIC_IO,
                Setting.EASES.ELAST_IN,
                Setting.EASES.ELAST_OUT,
                Setting.EASES.ELAST_IO,
                Setting.EASES.EXP_IN,
                Setting.EASES.EXP_OUT,
                Setting.EASES.EXP_IO,
                Setting.EASES.LINEAR,
                Setting.EASES.QUAD_IN,
                Setting.EASES.QUAD_OUT,
                Setting.EASES.QUAD_IO,
                Setting.EASES.QUART_IN,
                Setting.EASES.QUART_OUT,
                Setting.EASES.QUART_IO,
                Setting.EASES.QUINT_IN,
                Setting.EASES.QUINT_OUT,
                Setting.EASES.QUINT_IO,
                Setting.EASES.SINE_IN,
                Setting.EASES.SINE_OUT,
                Setting.EASES.SINE_IO
            ],
            LOOP: [
                Setting.LOOPS.NONE,
                Setting.LOOPS.FORWARD,
                Setting.LOOPS.PING_PONG
            ]
        };

        /**
         * Get a new Setting instance from a database representation
         * @param o
         * @returns {Setting}
         */
        Setting.fromObject = function(o) {
            let sane = (o.sane && o.type == Setting.TYPE.ASSET)
                ? o.sane.is_group
                ? AssetGroup.fromObject(o.sane)
                : Asset.fromObject(o.sane)
                : o.sane;

            let value = (o.value && o.type == Setting.TYPE.ASSET)
                ? o.value.is_group
                ? AssetGroup.fromObject(o.value)
                : Asset.fromObject(o.value)
                : o.value;

            let enum_list = ( o.enum_list ) ? o.enum_list.map( element => element ) : null;

            return new Setting( o.name, o.type, sane, value, o.min, o.max, o.step, o.asset_type, enum_list, o.ease, o.loop );
        };

        /**
         * Get a database representation of this Setting instance
         * @returns {object}
         */
        Setting.prototype.toObject = function(){
            this.is_group = false; // to be safe: internally maintained but mutable
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Setting instance
         * @returns {string}
         */
        Setting.prototype.toString = function() {
            return [
                this.name,
                this.type,
                this.sane,
                this.value,
                this.min,
                this.max,
                this.step,
                this.asset_type,
                this.enumListIsValid() ? this.enum_list.join[', '] : "",
                this.ease,
                this.loop
            ].join(", ");
        };

        /**
         * Is this Setting instance's name field valid?
         * @returns {boolean}
         */
        Setting.prototype.nameIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's type field valid?
         * @returns {boolean}
         */
        Setting.prototype.typeIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    Object.values(Setting.TYPE).indexOf(this.type) != -1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's sane field valid?
         * @returns {boolean}
         */
        Setting.prototype.saneIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.sane !== null &&
                    typeof this.sane !== 'undefined' &&
                    (
                        (this.type === Setting.TYPE.ASSET &&
                        Object.getPrototypeOf(this.sane) === Asset.prototype ||
                        Object.getPrototypeOf(this.sane) === AssetGroup.prototype) ||
                        (this.type === Setting.TYPE.BOOLEAN && typeof this.sane === 'boolean' ) ||
                        (this.type === Setting.TYPE.COLOR && /(^[0-9A-F]{6}$)/i.test(this.sane)) ||
                        (this.type === Setting.TYPE.ENUM &&
                        typeof this.sane === 'string' &&
                        this.enumListIsValid() &&
                        Object.values( this.enum_list ).indexOf( this.sane ) != -1) ||
                        (this.type === Setting.TYPE.FLOAT && typeof this.sane === 'number' &&
                        this.min <= this.sane && this.max >= this.sane) ||
                        (this.type === Setting.TYPE.INTEGER && typeof this.sane === 'number' && Number.isInteger(this.sane) &&
                        this.min <= this.sane && this.max >= this.sane) ||
                        (this.type === Setting.TYPE.MODULATOR && Object.values(Setting.MODULATORS).indexOf(this.sane) != -1 ) ||
                        (this.type === Setting.TYPE.EASE && Object.values(Setting.EASES).indexOf(this.sane) != -1 ) ||
                        (this.type === Setting.TYPE.LOOP && Object.values(Setting.LOOPS).indexOf(this.sane) != -1 ) ||
                        (this.type === Setting.TYPE.STRING && typeof this.sane === 'string')
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's value field valid?
         * @returns {boolean}
         */
        Setting.prototype.valueIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.value === null ||
                        typeof this.value === 'undefined'
                    ) || (
                        (this.type === Setting.TYPE.ASSET &&
                        Object.getPrototypeOf(this.value) === Asset.prototype ||
                        Object.getPrototypeOf(this.value) === AssetGroup.prototype) ||
                        (this.type === Setting.TYPE.BOOLEAN && typeof this.value === 'boolean') ||
                        (this.type === Setting.TYPE.COLOR && /(^[0-9A-F]{6}$)/i.test(this.value)) ||
                        (this.type === Setting.TYPE.ENUM &&
                        typeof this.value === 'string' &&
                        this.enumListIsValid() &&
                        Object.values( this.enum_list ).indexOf( this.value ) != -1) ||
                        (this.type === Setting.TYPE.FLOAT && typeof this.value === 'number' &&
                        this.min <= this.value && this.max >= this.value) ||
                        (this.type === Setting.TYPE.INTEGER && typeof this.value === 'number' && Number.isInteger(this.value) &&
                        this.min <= this.value && this.max >= this.value) ||
                        (this.type === Setting.TYPE.MODULATOR && Object.values(Setting.MODULATORS).indexOf(this.value) != -1 ) ||
                        (this.type === Setting.TYPE.EASE && Object.values(Setting.EASES).indexOf(this.value) != -1 ) ||
                        (this.type === Setting.TYPE.LOOP && Object.values(Setting.LOOPS).indexOf(this.value) != -1 ) ||
                        (this.type === Setting.TYPE.STRING && typeof this.value === 'string')
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's min field valid?
         * If type is INTEGER or FLOAT, must be set, less than or equal to sane, and less than max
         * For all other types, ignored and considered valid
         * @returns {boolean}
         */
        Setting.prototype.minIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.type !== Setting.TYPE.INTEGER &&
                        this.type !== Setting.TYPE.FLOAT
                    ) || (
                        this.min !== null &&
                        typeof this.min !== 'undefined' &&
                        typeof this.min === 'number' &&
                        this.min <= this.sane &&
                        this.min < this.max
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's max field valid?
         * If type is INTEGER or FLOAT, must be set, greater than or equal to sane, and greater than min
         * For all other types, ignored and considered valid
         * @returns {boolean}
         */
        Setting.prototype.maxIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.type !== Setting.TYPE.INTEGER &&
                        this.type !== Setting.TYPE.FLOAT
                    ) || (
                        this.max !== null &&
                        typeof this.max !== 'undefined' &&
                        typeof this.max === 'number' &&
                        this.max >= this.sane &&
                        this.max > this.min
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's step field valid?
         * If type is INTEGER or FLOAT, must be set and less than or equal to half the difference between min and max
         * For all other types, ignored and considered valid
         * @returns {boolean}
         */
        Setting.prototype.stepIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.type !== Setting.TYPE.INTEGER &&
                        this.type !== Setting.TYPE.FLOAT
                    ) || (
                        this.step !== null &&
                        typeof this.step !== 'undefined' &&
                        typeof this.step === 'number' &&
                        this.step <= (this.max - this.min) / 2
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's asset_type field valid?
         * @returns {boolean}
         */
        Setting.prototype.assetTypeIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.type !== Setting.TYPE.ASSET
                    ) || (
                        this.asset_type !== null &&
                        typeof this.asset_type !== 'undefined' &&
                        typeof this.asset_type === 'string' &&
                        (
                            Object.values( Asset.TYPE ).indexOf( this.asset_type ) !=-1 ||
                            Object.values( AssetGroup.TYPE ).indexOf( this.asset_type ) !=-1
                        )
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Setting instance's enum_list field valid?
         * @returns {boolean}
         */
        Setting.prototype.enumListIsValid = function() {
            let valid = false;
            try {
                valid = (
                        this.type !== Setting.TYPE.ENUM &&
                        this.enum_list === null ||
                        typeof this.enum_list  !== 'undefined'
                    ) || (
                        this.type === Setting.TYPE.ENUM &&
                        Array.isArray( this.enum_list ) &&
                        Object.values( this.enum_list ).indexOf( this.sane ) != -1
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Get Setting value as a number
         * @param value
         * @returns {number}
         */
        Setting.prototype.getValueAsColor = function() {
            let color = 0;
            if (this.valueIsValid()) {
                color = parseInt(this.value, 16);
            }
            return color;
        };

        /**
         * Is this Setting instance valid?
         * @returns {boolean|*}
         */
        Setting.prototype.isValid = function() {
            return (
                this.nameIsValid() &&
                this.typeIsValid() &&
                this.saneIsValid() &&
                this.valueIsValid() &&
                this.minIsValid() &&
                this.maxIsValid() &&
                this.stepIsValid() &&
                this.assetTypeIsValid() &&
                this.enumListIsValid()
            );
        };

        /**
         * Is the current value of this setting the same as the sane default
         * @returns {boolean}
         */
        Setting.prototype.valueIsDefault = function() {
            let isDefault = false;

            if (this.type === Setting.TYPE.ASSET) { // Compare JSON
                let a, b;
                if (typeof angular !== 'undefined') {
                    a = angular.toJson( this.sane );
                    b = angular.toJson( this.value );
                } else {
                    a = JSON.stringify( this.sane );
                    b = JSON.stringify( this.value );
                }
                isDefault = (a == b);
            } else {
                isDefault = (this.value == this.sane);
            }

            return isDefault;
        };

        /**
         * Reset the current setting to its sane default
         */
        Setting.prototype.resetToDefault = function() {
            this.value = this.sane;
        };

        return Setting;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Setting;
    } else {
        window.Setting = Setting;
    }

})();