/**
 * Sinewav3 Schema Entity: SettingGroup
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var SettingGroup = (function() {

        /**
         * Constructor
         * @param name
         * @param settings
         * @constructor
         */
        function SettingGroup(name, settings) {
            this.name = name;
            this.settings = settings || [];
            this.is_group = true;
        }

        /**
         * Get a new SettingGroup instance from a database representation
         * @param o
         * @returns {SettingGroup}
         */
        SettingGroup.fromObject = function(o) {
            var settings = o.settings
                ? o.settings.map( setting => setting.is_group ? SettingGroup.fromObject(setting) : Setting.fromObject(setting) )
                : null;

            return new SettingGroup(
                o.name,
                settings
            );
        };

        /**
         * Get a database representation of this SettingGroup instance
         * @returns {object}
         */
        SettingGroup.prototype.toObject = function(){
            this.is_group = true; // to be safe: internally maintained but mutable
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this SettingGroup instance
         * @returns {string}
         */
        SettingGroup.prototype.toString = function() {
            return [
                this.name,
                this.settingsIsValid() ? this.settings.reduce( (prev, setting) => prev + (prev ? ", ": "") + setting.toString() ) : ""
            ].join(' ');
        };

        /**
         * Is this SettingGroup instance's name field valid?
         * @returns {boolean|*}
         */
        SettingGroup.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this SettingGroup instance's settings field valid?
         * Must be an array of valid Setting instances (at least one)
         * @returns {boolean|*}
         */
        SettingGroup.prototype.settingsIsValid = function() {
            var valid = false;
            try {
                valid = (
                    Array.isArray(this.settings) &&
                    this.settings.length > 0 &&
                    this.settings.reduce( (prev, setting) => prev && setting.isValid(), true )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Add a Setting to this SettingGroup instance's collection
         * @param setting
         */
        SettingGroup.prototype.addSetting = function( setting ) {
            if ( Object.getPrototypeOf( setting ) === Setting.prototype ) {
                this.settings.unshift( setting );
            };
        };

        /**
         * Get a setting by name from this SettingGroup instance's settings collection
         * @param group
         */
        SettingGroup.prototype.getSetting = function( name ) {
            var setting = ( this.settings )
                ? this.settings.find( item => item.name === name )
                : null;
            return setting;
        };



        /**
         * Is this SettingGroup instance valid?
         * @returns {boolean|*}
         */
        SettingGroup.prototype.isValid = function() {
            return (
                this.nameIsValid() &&
                this.settingsIsValid()
            );
        };

        return SettingGroup;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = SettingGroup;
    } else {
        window.SettingGroup = SettingGroup;
    }

})();