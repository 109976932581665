/**
 * Sinewav3 Schema Entity: PublishRequest
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var PublishRequest = (function() {

        /**
         * Constructor
         * @param user
         * @param item_id
         * @param type
         * @param name
         * @param status
         * @param time
         * @param is_example
         * @param reject_reason
         * @constructor
         */
        function PublishRequest( user, item_id, type, name, status, time, is_example, reject_reason ) {
            this.user          = user; // UserToken
            this.item_id       = item_id;
            this.type          = type;
            this.name          = name;
            this.status        = status;
            this.time          = time;
            this.is_example    = is_example;
            this.reject_reason = reject_reason;
        }

        /**
         * Item Types
         * @type {{PLUGIN: string, TEMPLATE: string, ASSET: string}}
         */
        PublishRequest.TYPE = {
            PLUGIN:   'plugin',
            TEMPLATE: 'template',
            ASSET:    'asset'
        };

        /**
         * Status Types
         * @type {{PENDING: string, APPROVED: string, REJECTED: string}}
         */
        PublishRequest.STATUS = {
            PENDING:  'pending',
            CANCELED: 'canceled',
            APPROVED: 'approved',
            REJECTED: 'rejected'
        };

        /**
         * Get a new PublishRequest instance from a database representation
         * @param o
         * @returns {PublishRequest}
         */
        PublishRequest.fromObject = function(o) {
            var user = o.user ? Profile.fromObject(o.user).getToken() : null;
            return new PublishRequest( user, o.item_id, o.type, o.name, o.status, o.time, o.is_example, o.reject_reason );
        };


        /**
         * Get a string representation of this PublishRequest instance
         * @returns {string}
         */
        PublishRequest.prototype.toString = function() {
            return [
                this.userIsValid ? this.user.toString() : '',
                this.item_id,
                this.type,
                this.name,
                this.status,
                this.time,
                this.is_example ? 'example' : 'product',
                this.reject_reason
            ].join(' ');
        };

        /**
         * Get a database representation of this PublishRequest instance
         * @returns {object}
         */
        PublishRequest.prototype.toObject = function(){
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Is this PublishRequest instance's uid field valid?
         * @returns {boolean|*}user
         */
        PublishRequest.prototype.userIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.user !== 'undefined' && this.user !== null &&
                    Object.getPrototypeOf(this.user) === UserToken.prototype &&
                    this.user.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's item_id field valid?
         * @returns {boolean|*}item_id
         */
        PublishRequest.prototype.itemIdIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.item_id !== null &&
                    typeof this.item_id !== 'undefined' &&
                    typeof this.item_id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's time field valid?
         * @returns {boolean|*}uid
         */
        PublishRequest.prototype.timeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.time !== null &&
                    typeof this.time !== 'undefined' &&
                    typeof this.time === 'number' &&
                    (new Date(this.time)).getTime() > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's type field valid?
         * @returns {boolean}
         */
        PublishRequest.prototype.typeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    (
                        this.type === PublishRequest.TYPE.ASSET ||
                        this.type === PublishRequest.TYPE.PLUGIN ||
                        this.type === PublishRequest.TYPE.TEMPLATE
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's name field valid?
         * @returns {boolean}
         */
        PublishRequest.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string' &&
                    this.name.length > 0
                );
            } catch (e) {}
            return valid;
        };


        /**
         * Is this PublishRequest instance's status field valid?
         * @returns {boolean}
         */
        PublishRequest.prototype.statusIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.status !== null &&
                    typeof this.status !== 'undefined' &&
                    typeof this.type === 'string' &&
                    (
                        this.status === PublishRequest.STATUS.PENDING ||
                        this.status === PublishRequest.STATUS.CANCELED ||
                        this.status === PublishRequest.STATUS.APPROVED ||
                        this.status === PublishRequest.STATUS.REJECTED
                    )
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's is_example field valid?
         * @returns {boolean}
         */
        PublishRequest.prototype.isExampleIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.is_example !== null &&
                    typeof this.is_example === 'boolean'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance's name reject_reason valid?
         * @returns {boolean|*}
         */
        PublishRequest.prototype.rejectReasonIsValid = function() {
            var valid = false;
            try {
                valid = (
                        this.status === PublishRequest.STATUS.REJECTED &&
                        typeof this.reject_reason === 'string' &&
                        this.reject_reason.length > 0
                    ) || (
                        this.status !== PublishRequest.STATUS.REJECTED &&
                        this.reject_reason === null ||
                        typeof this.reject_reason === 'undefined'
                    );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this PublishRequest instance valid?
         * @returns {boolean|*}
         */
        PublishRequest.prototype.isValid = function() {
            return (
                this.userIsValid() &&
                this.itemIdIsValid() &&
                this.typeIsValid() &&
                this.nameIsValid() &&
                this.statusIsValid() &&
                this.timeIsValid() &&
                this.isExampleIsValid() &&
                this.rejectReasonIsValid()
            );
        };

        return PublishRequest;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = PublishRequest;
    } else {
        window.PublishRequest = PublishRequest;
    }

})();