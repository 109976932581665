// Shell - Splash Configuration
(function (){

    angular.module('Sinewav3.Client.Shell')

        .constant('SPLASH', {
            IMAGES: {
                PREFIX: 'assets/image/splash/',
                POSTFIX: {
                    POSTER:  '.jpg',
                    CAPTURE: '.gif'
                }
            },
            INTRO: {
                CONFIG: 'intro',
                THUMB: {
                    INFIX:   'intro/intro-thumb-',
                    POSTFIX: '.png',
                    ARTIST:  'artist',
                    CODER:   'coder'
                },
                CARD: {
                  HEAD: 'Build Responsive 3D Worlds',
                  COPY: 'Learn more about creating awesome stuff with Sinewav3 in the mini intros above...',
                    MINI: {
                        ARTIST: {
                            COPY: 'Easily choreograph visual accompaniment to your music.',
                            BUTTON: 'Visualize Music'
                        },
                        CODER: {
                            COPY: 'Use Three.js and WebGL to create amazing plugins.',
                            BUTTON: 'Develop Plugins'
                        }
                    }
              }
            },
            PRESO: {
                VISUALIZER: {
                    CONFIG: 'visualizer',
                    INFIX: 'viz/card-',
                    CARDS: [{
                        TIME: 12500,
                        HEAD: 'Create a Project',
                        COPY: 'Give it a name, and select or upload an audio track.'
                    }, {
                        TIME: 14250,
                        HEAD: 'Add Worlds to Your Project',
                        COPY: 'Each World is a separate 3D environment with its own camera, lighting, and objects.'
                    }, {
                        TIME: 17000,
                        HEAD: 'Add Plugins to Your Worlds',
                        COPY: 'Plugins provide objects and effects, manipulating them with aspects of your music.'
                    }, {
                        TIME: 18750,
                        HEAD: 'Setup Your Plugins',
                        COPY: 'A Plugin\'s settings allow you to configure its appearance and behavior.'
                    }, {
                        TIME: 15000,
                        HEAD: 'Visualize Your Music',
                        COPY: 'Let the Plugins work their magic as the audio plays. Tweak their settings in real time.'
                    }]
                },
                PLUGIN: {
                    CONFIG: 'plugin',
                    INFIX: 'plug/card-',
                    CARDS: [{
                        TIME: 15500,
                        HEAD: 'Create a Plugin',
                        COPY: 'Give it a name and add a description.'
                    }, {
                        TIME: 27500,
                        HEAD: 'Add Settings to Your Plugin',
                        COPY: 'Settings allow the user to modify your Plugin\'s appearance and behavior.'
                    }, {
                        TIME: 11750,
                        HEAD: 'Write a Setup Function',
                        COPY: 'Create scene objects, materials, helper functions, etc.'
                    }, {
                        TIME: 13500,
                        HEAD: 'Write a Render Function',
                        COPY: 'The app calls this function on all active Plugins before each frame.'
                    }, {
                        TIME: 12500,
                        HEAD: 'Read the API Docs',
                        COPY: 'Learn more about how a Plugin interacts with the visualizer.'
                    }, {
                        TIME: 21000,
                        HEAD: 'Study the Examples',
                        COPY: 'Copying an example and modifying it is a quick way to learn the ropes.'
                    }, {
                        TIME: 15500,
                        HEAD: 'Publish a Plugin',
                        COPY: 'When you\'re ready to share your work, submit your plugin for approval.'
                    }]
                }
            }
        })

        .run([
            '$rootScope', '$timeout', 'SPLASH',
            ($rootScope, $timeout, SPLASH) => {
                $rootScope.SPLASH = SPLASH;
                $rootScope.splash = new SplashScopeModel($timeout, SPLASH)
            }
        ]);

    /**
     * Splash Scope Model
     * @constructor
     */
    function SplashScopeModel($timeout, SPLASH) {

        this.$timeout = $timeout;
        this.SPLASH = SPLASH;

        // Initialize model, state, and form inputs
        this.reset();
    }


    SplashScopeModel.prototype.reset = function () {

        // Data model
        this.resetModel();

        // State
        this.resetState();

    };

    SplashScopeModel.prototype.resetModel = function () {
        this.project = null;
        this.preso = null;
    };

    SplashScopeModel.prototype.resetState = function () {
        this.cancelPromises();
        this.index = 0;
        this.config = null;
        this.splash_postfix = this.SPLASH.IMAGES.POSTFIX.POSTER;
        this.show_intro = false;
        this.show_preso = false;
        this.intro_out = false;
        this.intro_in = false;
        this.preso_out = false;
        this.preso_exit = false;
        this.preso_in = false;
        this.splash_out = false;
        this.splash_in = false;
        this.splashing = false;
        this.image_map = {};
        this.debouncing = false;
        this.preloadImages(this.SPLASH.PRESO.VISUALIZER, 0);
        this.preloadImages(this.SPLASH.PRESO.PLUGIN , 0);
    };

    SplashScopeModel.prototype.cancelPromises = function () {
        this.splash_in_promise = this.cancelPromise(this.splash_in_promise);
        this.splash_out_promise = this.cancelPromise(this.splash_out_promise);
        this.splash_run_promise = this.cancelPromise(this.splash_run_promise);
    };

    /**
     * Cancel a promise and return null
     * @param promise
     * @returns {null}
     */
    SplashScopeModel.prototype.cancelPromise = function ( promise ) {
        if (promise) this.$timeout.cancel(promise);
        return null;
    };

    /**
     * Show the introduction
     * @returns {null}
     */
    SplashScopeModel.prototype.showIntro = function () {
        // Show the intro card
        this.$timeout( () => {
            this.show_intro = true;
            this.intro_in = true;
        }, 300);
    };

    /**
     * Cancel a promise and return null
     * @param promise
     * @returns {null}
     */
    SplashScopeModel.prototype.showPreso = function ( preso ) {

        // Remember which presentation we're showing
        this.config = preso.CONFIG;

        // Hide the intro presentation
        this.$timeout( () => {
            this.intro_out = true;
        }, 195);

        this.$timeout( () => {
            this.intro_out = false;
            this.intro_in = false;
            this.show_intro = false;
        }, 250);

        // Show the presentation
        this.$timeout( () => {
            this.intro_out = false;
            this.show_preso  = true;
            this.preso_in = true;
            this.preso = preso;
            this.nextSplashCard();
        }, 1000);
    };

    /**
     * Hide the running presentation
     */
    SplashScopeModel.prototype.closePreso = function ( ) {

        this.cancelPromises();
        this.splash_in = false;
        this.preso_exit = true;

        this.$timeout( () => {
            this.resetModel();
            this.resetState();
        }, 450);

        this.$timeout( () => {
            this.showIntro();
        }, 600);

    };

    /**
     * Begin preloading the images for a presentation
     */
    SplashScopeModel.prototype.preloadImages = function ( preso, index ) {

         // Get the poster image
        this.preloadImage(preso, index, true);

        // Get the capture image
        this.preloadImage(preso, index, false);

    };

    /**
     * Preload either the preso or capture image for the given preso at the given index
     * @param preso
     * @param index
     * @param isPoster
     */
    SplashScopeModel.prototype.preloadImage = function (preso, index, isPoster) {
        let postfix = isPoster ? this.SPLASH.IMAGES.POSTFIX.POSTER : this.SPLASH.IMAGES.POSTFIX.CAPTURE;
        let url = this.SPLASH.IMAGES.PREFIX + preso.INFIX + index + postfix;
        let image = angular.element(new Image());
        this.image_map[url] = image;
        image.attr('src', url);
    };

    /**
     * Prepare for playing the next splash card:
     *  - Preload the images for the next card
     *  - Setup up the header, image, and copy transitions
     *  - Show poster image until transitions are complete
     *  - Run capture image after transition
     *  - Store promises, so they can be canceled on reset
     *  - Clear promises when they resolve
     *  - Call this method again after the card's time is up
     */
    SplashScopeModel.prototype.nextSplashCard = function() {

        // Fade in the visualizer
        this.splashing = true;

        // Preload images for the next frame
        this.preloadImages(this.preso, this.getNextIndex());

        // Run the new card's screen capture
        this.splash_run_promise = this.$timeout( () => {
            this.splash_postfix = this.SPLASH.IMAGES.POSTFIX.CAPTURE;
            this.splash_run_promise = null;
        }, 600);

        // Out with the current card
        this.splash_out_promise = this.$timeout( () => {
            this.splash_in = false;
            this.splash_out = true;
            this.splash_out_promise = null;
            this.splashing = false;
        }, this.preso.CARDS[this.index].TIME - 600);

        // In with the new card
        this.splash_in_promise = this.$timeout( () => {
            this.splash_postfix = this.SPLASH.IMAGES.POSTFIX.POSTER;
            this.splash_in = true;
            this.splash_out = false;
            this.index = this.getNextIndex();
            this.nextSplashCard();
        }, this.preso.CARDS[this.index].TIME);

    };

    /**
     * Get the next index in the preso (wrapping at end)
     * @returns {number}
     */
    SplashScopeModel.prototype.getNextIndex = function() {
        let next = this.index + 1;
        return (next < this.preso.CARDS.length) ? next : 0;
    };

    /**
     * Get the previous index in the preso (wrapping at 0)
     * @returns {number}
     */
    SplashScopeModel.prototype.getPreviousIndex = function() {
        let next = this.index - 1;
        return (next < 0) ? this.preso.CARDS.length - 1 : next;
    };

    /**
     * Set the shell state for visualizing the selected project
     * Returned function is a closure that gets the index of the currently selected world
     * @param project
     * @returns {Function}
     */
    SplashScopeModel.prototype.visualizeProject = function( project ) {
        this.project = project;
        this.splash_in = true;
        return () => this.index;
    };

    /**
     * Ignore keyboard input briefly
     */
    SplashScopeModel.prototype.debounce = function() {
        this.debouncing = true;
        this.$timeout( () => this.debouncing = false, 800);
    };

    /**
     * Navigate to the previous card
     */
    SplashScopeModel.prototype.navigatePrevious = function() {
        this.debounce();
        this.cancelPromises();

        // Out with the current card
        this.$timeout( () => {
            this.splash_in = false;
            this.splash_out = true;
            this.splashing = false;
        }, 10);

        // In with the new card
        this.splash_in_promise = this.$timeout( () => {
            this.splash_postfix = this.SPLASH.IMAGES.POSTFIX.POSTER;
            this.splash_in = true;
            this.splash_out = false;
            this.splashing = true;
            this.index = this.getPreviousIndex();
            this.nextSplashCard();
        }, 600);
    };

    /**
     * Navigate to the next card
     */
    SplashScopeModel.prototype.navigateNext = function() {
        this.debounce();
        this.cancelPromises();

        // Out with the current card
        this.$timeout( () => {
            this.splash_in = false;
            this.splash_out = true;
            this.splashing = false;
        }, 10);

        // In with the new card
        this.splash_in_promise = this.$timeout( () => {
            this.splash_postfix = this.SPLASH.IMAGES.POSTFIX.POSTER;
            this.splash_in = true;
            this.splash_out = false;
            this.index = this.getNextIndex();
            this.nextSplashCard();
        }, 600);

    };

})();