(function() {

    // Add the directive to the module
    angular.module("Sinewav3.Client.Shell")
        .directive(
            'shellLayout',
            [
                'CHROME',
                ShellLayoutFactory
            ]
        );

    // Factory Method
    function ShellLayoutFactory(CHROME)
    {
        // Construct and return the instance
        return {
            restrict: 'E',
            controller: 'ShellController',
            controllerAs: 'shellCtrl',
            templateUrl: CHROME.PREFIX + CHROME.LAYOUT + CHROME.POSTFIX
        };

    }
})();



