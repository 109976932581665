// Shell - Database Service
(function() {

    // Add the BroadcastService to the module
    angular.module('Sinewav3.Client.Shell')

        // Dictionary of top-level database nodes
        .constant('DB_NODES',
            {
                CONFIG:       'config',
                PROFILES:     'profiles',
                ASSETS:       'assets',
                PROJECTS:     'projects',
                PLUGINS:      'plugins',
                TRANSACTIONS: 'transactions',
                PLUGIN_LIKES: 'plugin_likes',
                PUBLISH_REQS: 'publish_requests',
                PROJECT_SHARES: 'project_shares'
            }
        )

        .factory(
            'DatabaseService',
            [
                'DB_NODES',
                DatabaseServiceFactory
            ]
        );

    // Factory Method
    function DatabaseServiceFactory(DB_NODES)
    {
        let service = {};

        service.unlistenAll         = unlistenAll;
        service.getRef              = getRef;
        service.getConfigRef        = getConfigRef;
        service.getProfilesRef      = getProfilesRef;
        service.getAssetsRef        = getAssetsRef;
        service.getPluginsRef       = getPluginsRef;
        service.getProjectsRef      = getProjectsRef;
        service.getPluginLikesRef   = getPluginLikesRef;
        service.getPublishReqsRef   = getPublishReqsRef;
        service.getProjectSharesRef = getProjectSharesRef;

        service.stripAngularProps = stripAngularProps;

        return service;

        function unlistenAll() {
            service.getProfilesRef().off();
            service.getProjectsRef().off();
            service.getPluginsRef().off();
            service.getAssetsRef().off();
            service.getPluginLikesRef().off();
            service.getPublishReqsRef().off();
        }

        function getRef(node) {

           return firebase.database().ref(node);
        }

        function getConfigRef() {
            return service.getRef(DB_NODES.CONFIG);
        }

        function getProfilesRef() {
            return service.getRef(DB_NODES.PROFILES);
        }

        function getAssetsRef() {
            return service.getRef(DB_NODES.ASSETS);
        }

        function getPluginsRef() {
            return service.getRef(DB_NODES.PLUGINS);
        }

        function getProjectsRef() {
            return service.getRef(DB_NODES.PROJECTS);
        }

        function getPluginLikesRef() {
            return service.getRef(DB_NODES.PLUGIN_LIKES);
        }

        function getPublishReqsRef() {
            return service.getRef(DB_NODES.PUBLISH_REQS);
        }

        function getProjectSharesRef() {
            return service.getRef(DB_NODES.PROJECT_SHARES);
        }

        // Remove the properties added by Angular when displayed in a list
        function stripAngularProps(entity){
            let strippedObj = JSON.parse(angular.toJson(entity.toObject()));
            let obj = entity.constructor.fromObject(strippedObj);
            return obj;
        }
    }

})();
