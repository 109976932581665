// Shell - Broadcast Message Service
(function() {

    // Add the BroadcastService to the module
    angular.module('Sinewav3.Client.Shell')
        .factory(
            'BroadcastService',
            [
                '$rootScope',
                BroadcastServiceFactory
            ]
        );

    // Factory Method
    function BroadcastServiceFactory($rootScope)
    {
        let service = {};
        service.send = send;
        return service;

        function send(event, data){
            $rootScope.$broadcast(event, data);
        }
    }

})();
