/**
 * Sinewav3 Schema Entity: Asset
 *
 * @author Cliff Hall <cliff@futurescale.com>
 */
(function() {

    // Support Node and browser with selective export to modules or window
    var Asset = (function() {

        /**
         * Constructor
         * @param id
         * @param type
         * @param name
         * @param owner
         * @param filename
         * @param size
         * @param is_public
         * @param url
         * @param duration
         * @constructor
         */
        function Asset(id, type, name, owner, filename, size, is_public, url, duration) {
            this.id = id;
            this.type = type;
            this.name = name;
            this.owner = owner;
            this.filename = filename;
            this.size = size;
            this.is_public = (typeof is_public === 'undefined')? false : is_public;
            this.url = url;
            this.duration = duration || 0;
            this.is_group = false;
        }

        /**
         * Asset Type Constants
         * @type {{AUDIO: string, IMAGE: string, MODEL: string, VIDEO: string, FRAME: string}}
         */
        Asset.TYPE = {
            AUDIO: 'audio',
            IMAGE: 'image',
            MODEL: 'model',
            VIDEO: 'video',
            FRAME: 'frame',
            FONT:  'font'
        };

        /**
         * Get a new Asset instance from a database representation
         * @param o
         * @returns {Asset}
         */
        Asset.fromObject = function(o) {
            var owner_name = o.owner ? new NameToken(o.owner.name.display, o.owner.name.first, o.owner.name.last) : null;
            var owner = o.owner ? new UserToken(o.owner.uid, owner_name, o.owner.photo_url) : null;
            return new Asset(
                o.id,
                o.type,
                o.name,
                owner,
                o.filename,
                o.size,
                o.is_public,
                o.url,
                o.duration
            );
        };

        /**
         * Get a database representation of this Asset instance
         * @returns {object}
         */
        Asset.prototype.toObject = function(){
            this.is_group = false; // to be safe: internally maintained but mutable
            return JSON.parse(JSON.stringify(this));
        };

        /**
         * Get a string representation of this Asset instance
         * @returns {string}
         */
        Asset.prototype.toString = function() {
            return [
                this.id,
                this.type,
                this.nameIsValid() ? this.name : "",
                this.ownerIsValid() ? this.owner.toString() : "",
                this.filename ? this.filename : "",
                this.size ? this.size : "",
                this.url ? this.url : "",
                this.duration
            ].join(' ');
        };

        /**
         * Is this Asset instance's id field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.idIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.id !== null &&
                    typeof this.id !== 'undefined' &&
                    typeof this.id === 'string'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's type field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.typeIsValid = function() {
            let valid = false;
            try {
                valid = (
                    this.type !== null &&
                    typeof this.type !== 'undefined' &&
                    typeof this.type === 'string' &&
                    Object.values(Asset.TYPE).indexOf(this.type) !== -1
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's name field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.nameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.name !== null &&
                    typeof this.name !== 'undefined' &&
                    typeof this.name === 'string' &&
                    this.name.length > 0
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's owner field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.ownerIsValid = function() {
            var valid = false;
            try {
                valid = (
                    typeof this.owner  !== 'undefined' && this.owner !== null &&
                    Object.getPrototypeOf(this.owner) === UserToken.prototype &&
                    this.owner.isValid()
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's filename field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.filenameIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.filename !== null &&
                    typeof this.filename !== 'undefined' &&
                    typeof this.filename === 'string'
                );
            } catch (e) {}
            return valid;
        };


        /**
         * Is this Asset instance's size field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.sizeIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.size !== null &&
                    typeof this.size !== 'undefined' &&
                    typeof this.size === 'number' &&
                    Number.isInteger(this.size)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's url field valid?
         *
         * The URL validation regex comes from: https://stackoverflow.com/a/15855457/203704
         * @returns {boolean}
         */
        Asset.prototype.urlIsValid = function() {
            let valid = false;
            let regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            try {
                valid = (
                    this.url === null ||
                    typeof this.url === 'undefined'
                ) || (
                    typeof this.url === 'string' &&
                    regex.test(this.url)
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance's duration field valid?
         * @returns {boolean|*}
         */
        Asset.prototype.durationIsValid = function() {
            var valid = false;
            try {
                valid = (
                    this.duration !== null &&
                    typeof this.duration !== 'undefined' &&
                    typeof this.duration === 'number'
                );
            } catch (e) {}
            return valid;
        };

        /**
         * Is this Asset instance valid?
         * @returns {boolean|*}
         */
        Asset.prototype.isValid = function() {
            return (
                this.idIsValid() &&
                this.typeIsValid() &&
                this.nameIsValid() &&
                this.ownerIsValid() &&
                this.filenameIsValid() &&
                this.sizeIsValid() &&
                this.urlIsValid() &&
                this.durationIsValid()
            );
        };

        /**
         * Is this Asset instance of type AUDIO?
         * @returns {boolean|*}
         */
        Asset.prototype.isAudio = function() {
            return this.type === Asset.TYPE.AUDIO;
        };

        return Asset;

    })();

    // Export
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = Asset;
    } else {
        window.Asset = Asset;
    }

})();