// Shell - Toast Service
(function() {

    // Add the service to the module
    angular.module('Sinewav3.Client.Shell')
        .factory(
            'ToastService',
            [
                '$mdToast',
                'CHROME',
                ToastServiceFactory
            ]
        );

    // Factory Method
    function ToastServiceFactory($mdToast, CHROME)
    {
        let service = {};
        service.showToast = showToast;
        return service;

        function showToast(message) {
            if (message) {

                const position =
                    Object.keys(CHROME.TOAST.POSITION)
                    .filter( function(pos) { return CHROME.TOAST.POSITION[pos]; } )
                    .join(' ');

                // Double the delay for longer messages
                const delay = (message.length > 20) ? CHROME.TOAST.DELAY * 2 : CHROME.TOAST.DELAY;

                $mdToast.show(
                    $mdToast.simple()
                        .textContent(message)
                        .position(position)
                        .action('OK')
                        .highlightClass('md-accent')
                        .hideDelay(delay)
                );
            }
        }
    }

})();
